import React, { Component } from 'react'
import { NavBar, Icon, Progress, Popover, Tabs, Badge, Toast, Button, DatePicker, Modal, Tag } from 'antd-mobile'
import './details.less'
import { req_findDetailsById, req_completeProjectMilepost, req_findListPlan, req_findReport, req_completeProjectPlan } from './../../service/api'
// import {Link} from 'react-router-dom'
import moment from 'moment'
import IP from '../../config/config'
// import IP from '../../config/config'
import cookie from 'react-cookies';

const Item = Popover.Item;
const alert = Modal.alert;

class Details extends Component {
    state = {
        popoverVisible: false,
        data: [],
        checkEntities: [],
        enclosureEntities: [],
        milepostEntities: [],
        userEntities: [],
        planData: [],
        reportData: [],
        planDate: moment().format("YYYY-MM-DD"),
        reportDate: moment().format("YYYY-MM-DD"),
        more: '',
        pe_status: 0,
        enclosureData: []
    }

    componentDidMount = () => {
        const { planDate, reportDate } = this.state
        this.detailsData(this.props.match.params.id);
        this.listPlan(this.props.match.params.id, planDate);
        this.report(this.props.match.params.id, reportDate);
    }

    detailsData = (projectId) => {
        req_findDetailsById({ projectId }).then(res => {
            if (res.code === 20000) {
                this.setState({
                    data: res.data,
                    checkEntities: res.data.projectCheckEntities, // 验收信息
                    enclosureData: res.data.projectEnclosureEntities, // 项目附件
                    enclosureEntities: res.data.projectEnclosureEntities, // 项目附件
                    milepostEntities: res.data.projectMilepostEntities, // 里程碑
                    userEntities: res.data.projectUserEntities, // 人员结构
                })
            } else {
                Toast.fail(res.message, 1);
            }
        })
    }

    // 工作计划
    listPlan = (projectId, dateStr) => {
        req_findListPlan({ projectId, dateStr }).then(res => {
            if (res.code === 20000) {
                this.setState({
                    planData: res.data
                })
            } else {
                Toast.fail(res.message, 1);
            }
        })
    }

    // 日报表
    report = (projectId, dateStr) => {
        req_findReport({ projectId, dateStr }).then(res => {
            if (res.code === 20000) {
                this.setState({
                    reportData: res.data
                })
            } else {
                Toast.fail(res.message, 1);
            }
        })
    }


    // 完成里程碑
    okMile = (v) => {
        alert('完成里程碑', '确定完成里程碑？', [
            { text: '取消', onPress: () => console.log() },
            {
                text: '确定', onPress: () => {
                    const { userEntities } = this.state;
                    const user = userEntities.filter(x => x.pu_state === 1 && x.pu_userid === cookie.load('user').user_id)
                    if (user.length === 1) {
                        req_completeProjectMilepost({ pmId: v.pm_id }).then(res => {
                            if (res.code === 20000) {
                                Toast.success(res.message, 1);
                                this.detailsData(this.props.match.params.id);
                            } else {
                                Toast.fail(res.message, 1);
                            }
                        })
                    } else {
                        Toast.fail("不是项目负责人，无法操作", 1);
                    }
                }
            },
        ])
    }

    //完成工作计划
    okProjectPlan = (v) => {
        req_completeProjectPlan({ ppId: v.pp_id }).then(res => {
            if (res.code === 20000) {
                Toast.success(res.message, 1);
                const { planDate } = this.state
                this.listPlan(this.props.match.params.id, planDate)
            } else {
                Toast.fail(res.message, 1);
            }
        })
    }


    // 点击右上角功能
    handleSelect = (opt) => {
        const { data } = this.state;
        if (opt.props.value === 1) {
            this.props.history.push({ pathname: `/project/workPlan/${this.props.match.params.id}/${data.project_state}` })
        } else if (opt.props.value === 2) {
            const { userEntities } = this.state;
            const user = userEntities.filter(x => x.pu_state === 1 && x.pu_userid === cookie.load('user').user_id)
            if (user.length === 1) {
                this.props.history.push({ pathname: `/project/milestone/${this.props.match.params.id}/${data.project_state}` });
            } else {
                Toast.fail("不是项目负责人，无法操作", 1);
                return
            }
        } else if (opt.props.value === 3) {
            if (data.project_state === 2) {
                Toast.fail("项目已完成！");
            } else {
                this.props.history.push({ pathname: `/project/projectadd/${this.props.match.params.id}/${data.project_state}` });
            }
        } else if (opt.props.value === 4) {
            this.props.history.push({ pathname: `/project/accessory/${this.props.match.params.id}/${data.project_state}` });
        } else if (opt.props.value === 5) {
            this.props.history.push({ pathname: `/project/dailyDetails/${this.props.match.params.id}/${data.project_state}` });
        } else {
            this.props.history.push({ pathname: `/project/checkDetails/${this.props.match.params.id}/${data.project_state}` });
        }
    }

    handlEnclosure = (val) => {
        const { enclosureData, enclosureEntities } = this.state
        this.setState({
            enclosureEntities: val !== 0 ? enclosureData.filter(x => x.pe_status == val) : enclosureData
        })
    }


    // project/milestone
    render() {
        const { enclosureEntities, popoverVisible, data, checkEntities, milepostEntities, userEntities, planData, reportData, planDate, reportDate, pe_status } = this.state
        const tabs = [
            // { title: <Badge>项目附件</Badge> },
            { title: <Badge>人员结构</Badge> },
            { title: <Badge>里程碑</Badge> },
            // { title: <Badge>验收信息</Badge> },
            { title: <Badge>工作计划</Badge> },
            { title: <Badge>日报表</Badge> },
            { title: <Badge>项目附件</Badge> },
        ];
        const borderNone = {
            border: "none"
        }
        const border = {
        }
        return (
            <div>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.push({ pathname: '/project/index' })}
                    rightContent={[
                        <Popover
                            key={8}
                            mask
                            visible={popoverVisible}
                            overlay={[
                                (<Item key={1} value={1}><img src={require('../../images/41.png')} alt='' /><span>工作计划</span></Item>),
                                (<Item key={2} value={2}><img src={require('../../images/42.png')} alt='' /><span>里程碑</span></Item>),
                                (<Item key={3} value={3}><img src={require('../../images/40.png')} alt='' /><span>编辑项目</span></Item>),
                                (<Item key={4} value={4}><img src={require('../../images/43.png')} alt='' /><span>上传附件</span></Item>),
                                (<Item key={5} value={5}><img src={require('../../images/41.png')} alt='' /><span>日报表</span></Item>),
                                // (<Item key={6} value={6}><img src={require('../../images/41.png')} alt='' /><span>验收</span></Item>)
                            ]}
                            onSelect={this.handleSelect}
                        >
                            <Icon key={7} type="ellipsis" />

                        </Popover>
                    ]}
                >
                    项目详情
                </NavBar>
                <div className="main-box">
                    <div className="main-header">
                        <div className="main-box-left">
                            <div className="main-box-title">
                                {data.project_name}
                            </div>
                            <div className="main-box-desc">
                                {data.project_describe && data.project_describe.length > 38 ? data.project_describe.substr(0, 38) + '...' : data.project_describe}
                            </div>
                        </div>
                        {/* <div className="main-box-right">
                            <img src={IP.host+data.project_url} width="60px" alt=""/>
                        </div> */}
                        {data.project_outsourcing === 1 ? <div className="main-box-name"><img src={require('../../images/tag.png')} alt="img" width="56" height="16px" /></div> : ""}
                    </div>
                    <div>
                        <Progress className="main-progress" percent={Number(data.total) * 100} position="normal" />
                        <span className="main-per">{data.total ? Number(data.total) * 100 : 0}%</span>
                        <span className="main-state">{data.project_state === 1 ? "进行中" : "已完工"}</span>
                    </div>
                    <div className="main-footer">
                        <div className="main-footer-desc" style={{ border: 'none' }}>使用单位：{data.project_company_name}</div>
                    </div>
                    <div className="main-footer">
                        <div className="main-footer-desc">{data.project_code}</div>
                        <div className="main-footer-desc">{data.project_create_username}</div>
                        <div className="main-footer-desc" style={{ border: "none" }}>
                            计划 {moment(data.project_end_time).format('YYYY-MM-DD')} 截止
                        </div>
                    </div>
                </div>
                <div className="main-desc">
                    <div className="main-name">{data.project_name}</div>
                    <div className="main-content">{data.project_describe}</div>
                </div>
                <div className="gray" />
                <div className="main-tabs">
                    <Tabs tabs={tabs}
                        initialPage={0}
                    // onChange={(tab, index) => { console.log('onChange', index, tab); }}
                    >



                        {/* 人员结构 */}
                        <div style={{ backgroundColor: '#fff' }}>
                            <div className="tabs-person">
                                <div className="person-title">项目负责人（{userEntities.filter(x => x.pu_state === 1).length}）</div>
                                {
                                    userEntities.filter(x => x.pu_state === 1).map((v, k) => {
                                        return (
                                            <div className="person-main" key={k}>
                                                <div className="person-img">
                                                    <img src={v.pu_user_avatar} width="40px" alt="" />
                                                </div>
                                                <div className="person-name">{v.pu_username}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="tabs-person">
                                <div className="person-title">项目验收人（{userEntities.filter(x => x.pu_state === 3).length}）</div>
                                {
                                    userEntities.filter(x => x.pu_state === 3).map((v, k) => {
                                        return (
                                            <div className="person-main" key={k}>
                                                <div className="person-img">
                                                    <img src={v.pu_user_avatar} width="40px" alt="" />
                                                </div>
                                                <div className="person-name">{v.pu_username}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="tabs-person">
                                <div className="person-title">成员（{userEntities.filter(x => x.pu_state === 2).length}）</div>
                                {
                                    userEntities.filter(x => x.pu_state === 2).map((v, k) => {
                                        return (
                                            <div className="person-main" key={k}>
                                                <div className="person-img">
                                                    <img src={v.pu_user_avatar} width="40px" alt="" />
                                                </div>
                                                <div className="person-name">{v.pu_username}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/* 里程碑 */}
                        <div style={{ backgroundColor: '#fff', paddingTop: "20px" }}>
                            {
                                milepostEntities.map((v, k) => {
                                    return (
                                        <div className="tab-step" key={k} style={milepostEntities.length - 1 === k ? borderNone : border}>
                                            {
                                                v.pm_state === 2 ? (<div className="stop-int" style={{ background: '#FFDC00', color: "black" }}>{k + 1}</div>) : v.pm_state === 1 ? (<div className="stop-int" style={{ background: "#B2B2B2" }} onClick={() => this.okMile(v)}>{k + 1}</div>) : (
                                                    <div className="stop-int">{k + 1}</div>
                                                )
                                            }

                                            {/* <div className="step-title">第{k+1}个节点</div> */}
                                            <div className="step-title">
                                                {v.pm_content.length > 20 && this.state.more !== k ? v.pm_content.substr(0, 20) + '...' : this.state.more === k ? v.pm_content : v.pm_content}
                                                <span style={{ color: "#007ACC" }} onClick={() => {
                                                    this.setState({
                                                        more: k
                                                    })

                                                }}>{this.state.more !== k ? '查看更多' : ''}</span>
                                            </div>

                                            {
                                                v.pm_state === 2 ? (
                                                    <div className="step-desc" style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div>
                                                            <div>•{v.pm_state === 1 ? "勾选左侧圆点可完成节点" : v.pm_end_username + " " + moment(v.pm_end_time).format('MM-DD HH:MM') + "完成了节点"}</div>
                                                            <div style={{ padding: "6px 4px" }}>待验收</div>
                                                        </div>
                                                        {
                                                            userEntities.filter(x => x.pu_state === 3 && x.pu_userid === cookie.load('user').user_id).length === 1 && (
                                                                <div className="check" onClick={() => {
                                                                    this.props.history.push({ pathname: `/project/checkAdd/${v.pm_id}` });
                                                                }}>
                                                                    验收
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                ) : (<div className="step-desc" style={{ color: "#9A9A9A" }}>
                                                    <div>•{v.pm_state === 1 ? "勾选左侧圆点可完成节点" : v.projectCheckEntity && v.projectCheckEntity.pc_username + "  验收  " + moment(v.projectCheckEntity && v.projectCheckEntity.pc_time).format('MM-DD HH:MM')}</div>
                                                    <div style={{ padding: "6px 4px" }}>
                                                        {v.projectCheckEntity && v.projectCheckEntity.pc_content}
                                                    </div>
                                                </div>)
                                            }
                                            <div style={{ paddingLeft: '4px', paddingBottom: '20px' }}>{v.projectCheckEntity && v.projectCheckEntity.checkImgEntities && v.projectCheckEntity.checkImgEntities.map((val, key) => {
                                                return (<img key={key} height="30px" style={{ marginRight: '10px' }} src={IP.host + val.img_url} onClick={() => {
                                                    window.location.href = IP.host + val.img_url
                                                }} alt="" />)
                                            })}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {/* 工作计划 */}
                        <div style={{ backgroundColor: '#fff' }}>
                            <div className="accept-title" style={{ marginBottom: "20px" }}>
                                <DatePicker
                                    mode="date"
                                    format="YYYY-MM-DD"
                                    title="请选择时间"
                                    value={new Date(planDate)}
                                    onChange={v => {
                                        this.setState({
                                            planDate: moment(v).format("YYYY-MM-DD")
                                        }, () => {
                                            const { planDate } = this.state
                                            this.listPlan(this.props.match.params.id, planDate);
                                        })
                                    }}
                                    extra="click to choose"
                                >
                                    <div className="accept-title-time">{planDate} ∨</div>
                                </DatePicker>

                            </div>
                            {
                                planData.map((v, k) => {
                                    return (
                                        <div key={k}>
                                            <div className="tab-step" style={planData.length - 1 === k ? borderNone : border}>
                                                {
                                                    v.pp_state === 2 ? (<div className="stop-int">{k + 1}</div>) : (<div className="stop-int" style={{ background: "#B2B2B2" }} onClick={() => this.okProjectPlan(v)}>{k + 1}</div>)
                                                }
                                                <div className="step-title">{v.pp_content}</div>
                                                {
                                                    v.pp_state === 2 ? (<div className="step-desc-short">•{v.pp_state === 2 ? v.pp_end_username + " " + moment(v.pp_end_time).format('MM-DD HH:MM') + "完成了节点" : "勾选左侧圆点可完成节点"}</div>) : (<div className="step-desc-short" style={{ color: "#9A9A9A" }}>•{v.pp_state === 2 ? v.pp_end_username + " " + moment(v.pp_end_time).format('MM-DD HH:MM') + "完成了节点" : "勾选左侧圆点可完成节点"}</div>)
                                                }
                                                <div className="step-img">
                                                    {v.projectPlanUserEntities.map((value, key) => {
                                                        return (
                                                            <img key={key} src={value.ppu_user_avatar} width="40px" alt="" />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* 日报表 */}
                        <div className="tab-accept">
                            <div className="accept-title">
                                <DatePicker
                                    mode="date"
                                    format="YYYY-MM-DD"
                                    title="请选择时间"
                                    value={new Date(reportDate)}
                                    onChange={v => {
                                        this.setState({
                                            reportDate: moment(v).format("YYYY-MM-DD")
                                        }, () => {
                                            const { reportDate } = this.state
                                            this.report(this.props.match.params.id, reportDate);
                                        })
                                    }}
                                    extra="click to choose"
                                >
                                    <div className="accept-title-time">{reportDate} ∨</div>
                                </DatePicker>
                                <div className="accept-title-num">
                                    {reportData.filter(x => x.pr_content === null).length}
                                    人未提交</div>
                            </div>
                            {
                                reportData.map((v, k) => {
                                    return (
                                        <div key={k}>
                                            <div className="accept-fine">
                                                <div className="accept-header">
                                                    <div className="accept-img">
                                                        {/* <img src={require('../../images/like.jpg')} width="50px" alt=""/> */}
                                                        <img src={v.pu_user_avatar} width="50px" alt="" />
                                                    </div>
                                                    <div>
                                                        <div className="accept-name">
                                                            {v.pu_username}
                                                        </div>
                                                        {v.pr_content ? (<div className="accept-time">
                                                            创建于{moment(v.pr_time).format("YYYY-MM-DD HH:MM")}
                                                        </div>) : (<div style={{ background: "#FFD7D5", color: "#D5483F", padding: "1px 5px", margin: "4px 0px 0px 20px", fontSize: "12px" }}>未提交</div>)}
                                                    </div>
                                                </div>
                                                {v.pr_content ? (<div className="accept-main">
                                                    验收内容：{v.pr_content}
                                                </div>) : ('')}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* 项目附件 */}
                        <div style={{ backgroundColor: '#fff' }}>
                            {/* 1 项目需求文档 2原型 3效果图 4发布服务器（地址，账号、密码）、5 项目使用手册 6项目宣传PPT */}
                            <div className='detailsTag'>
                                <div className='detailsWidth'>
                                    <Tag data-seed="logId" selected={pe_status === 0} onChange={() => {
                                        this.handlEnclosure(0)
                                        this.setState({
                                            pe_status: 0
                                        })
                                    }}>全部</Tag>
                                    <Tag data-seed="logId" selected={pe_status === 1} onChange={() => {
                                        this.handlEnclosure(1)
                                        this.setState({
                                            pe_status: 1
                                        })
                                    }}>文档</Tag>
                                    <Tag data-seed="logId" selected={pe_status === 2} onChange={() => {
                                        this.handlEnclosure(2)
                                        this.setState({
                                            pe_status: 2
                                        })
                                    }}>原型</Tag>
                                    <Tag data-seed="logId" selected={pe_status === 3} onChange={() => {
                                        this.handlEnclosure(3)
                                        this.setState({
                                            pe_status: 3
                                        })
                                    }}>效果图</Tag>
                                    <Tag data-seed="logId" selected={pe_status === 4} onChange={() => {
                                        this.handlEnclosure(4)
                                        this.setState({
                                            pe_status: 4
                                        })
                                    }}>服务器</Tag>
                                    <Tag data-seed="logId" selected={pe_status === 5} onChange={() => {
                                        this.handlEnclosure(5)
                                        this.setState({
                                            pe_status: 5
                                        })
                                    }}>手册</Tag>
                                    <Tag data-seed="logId" selected={pe_status === 6} onChange={() => {
                                        this.handlEnclosure(6)
                                        this.setState({
                                            pe_status: 6
                                        })
                                    }}>ppt</Tag>
                                </div>
                            </div>
                            {enclosureEntities.map((v, k) => {
                                return (
                                    <div className="tabs-access" key={k} onClick={() => {
                                        window.location.href = IP.host + v.pe_filepath
                                    }}>
                                        <div className="tabs-img">
                                            <img src={IP.TypeIcon[v.pe_filepath.substring(v.pe_filepath.lastIndexOf(".") + 1)] ? IP.host + IP.TypeIcon[v.pe_filepath.substring(v.pe_filepath.lastIndexOf(".") + 1)] : IP.host + IP.TypeIcon['error']} width="40px" alt="" />
                                        </div>
                                        <div className="tabs-main">
                                            <div className="tabs-title">{v.pe_filename.length > 24 ? v.pe_filename.substr(0, 24) + "..." : v.pe_filename}</div>
                                            <div className="tabs-size">{v.pe_size ? v.pe_size : '0'}MB</div>
                                        </div>
                                        <div className="tabs-icon">
                                            <span>•</span>
                                            <span>•</span>
                                            <span>•</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Tabs>
                </div>

                {
                    userEntities.find(x => x.pu_state === 2 && x.pu_userid === cookie.load('user').user_id) ? (<div className="footer-sub">
                        <div className="button-sub">
                            {
                                userEntities.find(x => x.pu_state === 2 && x.pu_userid === cookie.load('user').user_id) ? (<Button type="primary" className="button-footer" onClick={() => {
                                    this.props.history.push({ pathname: `/project/dailyDetails/${this.props.match.params.id}/${data.project_state}` });
                                }}>日报</Button>) : ('')
                            }
                        </div>
                    </div>) : ('')
                }

            </div>
        )
    }
}
export default Details
