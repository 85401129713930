import React, { Component } from 'react';
// import { NavBar, Icon, List, TextareaItem, Toast, ImagePicker } from 'antd-mobile'
import { NavBar, Icon, List, TextareaItem, Toast } from 'antd-mobile'
import { Gallery, GalleryDelete, Uploader, Cell, CellBody } from 'react-weui';
import { uploadImageReply, saveReply } from '../../service/api'

class reply extends Component {
    state = {
        urls: [],
        gallery: false,
        demoFiles: [],
        questionFiles: [], // 附件
        problemDesc: '', // 回答
    }

    componentDidMount(){
        document.getElementsByClassName("weui-uploader__input")[0].removeAttribute("capture")
    }
    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    // 上传附件
    onChangeFile = (e) => {
        const { files } = e.target;
        Toast.loading('上传中...')
        if (files && files.length) {
            let formData = new FormData();
            formData.append("flag", 2)
            formData.append("file", files[0]);
            uploadImageReply(formData).then(res => {
                this.setState({
                    questionFiles: this.state.questionFiles.concat(res.data)
                }, () => {
                    Toast.hide()
                })
            })
        }
    };

    // 回答问题
    handleQuestion = async () => {
        const { problemDesc, urls, questionFiles } = this.state;
        if (!problemDesc) return Toast.info('请填写你的回答')
        const req = {
            problem_id: this.props.match.params.id,
            reply_content: problemDesc,
            reply_images: urls && urls.length ? urls.join(',') : '',
            files: questionFiles && questionFiles.length ? JSON.stringify(questionFiles) : ''
        }
        const res = await saveReply(req)
        if (res.code === 20000) {
            Toast.info(res.message)
            setTimeout(()=>{
                this.props.history.goBack()
            },1000)
        }
    }

    render() {
        const { questionFiles } = this.state;
        return (
            <div>
                {this.renderGallery()}
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={<div onClick={() => this.handleQuestion()}>回答问题</div>}
                >
                    我来答
                </NavBar>
                <List>
                    <TextareaItem
                        placeholder="请输入答案"
                        onChange={(e) => {
                            this.setState({
                                problemDesc: e
                            })
                        }}
                        rows={5}
                        count={200}
                    />
                </List>
                <Cell className='problem_modal_img'>
                    <CellBody>
                        <Uploader
                            title='上传图片（可不填）'
                            maxCount={4}
                            files={this.state.demoFiles}
                            onError={msg => alert(msg)}
                            onChange={(file) => {
                                let newFiles = [...this.state.demoFiles, { url: file.data }];
                                let files = file.nativeFile
                                this.setState({
                                    demoFiles: newFiles
                                });
                                Toast.loading('图片上传中', 0)
                                let formData = new FormData();
                                formData.append("file", files);
                                uploadImageReply(formData).then(res => {
                                    this.setState({
                                        urls: this.state.urls.concat(res.data)
                                    }, () => {
                                        Toast.hide()
                                    })
                                })
                            }}
                            onFileClick={
                                (e, file, i) => {
                                    this.setState({
                                        gallery: {
                                            url: file.url,
                                            id: i
                                        }
                                    })
                                }
                            }
                            lang={{
                                maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                            }}
                        />
                    </CellBody>
                </Cell>
                <div className='taskAdd_enclosure'>
                    <div style={{ color: '#000', textAlign: 'left' }}>上传附件（可不填）</div>
                    <div className='taskAdd_enclosure_file'>
                        {/* <ImagePicker
                            onChange={this.onChangeFile}
                            length={1}
                        /> */}
                        <div className='taskAdd_file'>
                            {/* <img src={require('../../images/10.png')} alt='' /> */}
                            <div onClick={() => document.getElementById('upload').click()} style={{ background: '#4aa14a', color: '#fff', padding: '3px 5px', borderRadius: '5px' }}>上传附件</div>
                            <input type='file' id='upload' onChange={e => this.onChangeFile(e)} hidden />
                        </div>
                    </div>
                    <div style={{textAlign: 'left'}}>
                        {
                            (questionFiles || []).map(x => {
                                return <div key={x.url}>{x.fileName}</div>
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default reply;