/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { NavBar, Icon, List, Picker, InputItem, WhiteSpace, WingBlank, Button, Modal, Toast, SearchBar } from 'antd-mobile'
import cookie from 'react-cookies'
import { findRepairUser, phoneRepair, req_upload, getLocationInfoByLonAndLat } from '../../service/api'
import './index.less'
import { Gallery, GalleryDelete, Uploader, Cell, CellBody } from 'react-weui';
import JSSDK from '../../utils/wechat'
import wx from 'weixin-js-sdk';
import { Map, Marker } from 'react-amap'

const mapKey = '70d9217c07af926e2d27f90b0c5369f8'


@inject('globalStore')
@observer
class index extends Component {
    state = {
        visible: false,
        userTypeId: null, // 用户类型Id
        userData: [], // 用户
        department: '', // 部门
        phone: '', // 电话
        urls: [],
        gallery: false,
        demoFiles: [],
        address: '', // 位置信息
        lat: '',// 维度
        lon: '',// 经度
        locationVisible: false,
    }

    componentDidMount = () => {
        document.getElementsByClassName("weui-uploader__input")[0].removeAttribute("capture")
        const record = this.props.location.record
        if (record) {
            if (record.name === '/user/repairs') {
                this.setState({
                    userTypeId: [1]
                })
            }
            if (record.name === '/staff/repairs') {
                this.setState({
                    userTypeId: [2]
                })
            }
        }

        this.getWeChatLocation()
    }

    // 初始化微信配置
    InitWechatConfig = () => {
        const { globalStore: { appId } } = this.props;
        return JSSDK.InitWxConfig(appId)
    }


    // 获取位置
    getWeChatLocation = async () => {

        await this.InitWechatConfig()
        wx.ready(async e => {
            const res = await JSSDK.getLocation()
            const { lon, lat } = res;
            const result = await getLocationInfoByLonAndLat(lon, lat)
            if (result.info === 'OK') {
                this.setState({
                    address: result.regeocode.formatted_address,
                    lat,
                    lon
                })
            }
        })
    }

    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    // 拨打电话
    modalClick = async () => {
        const { userTypeId, department, phone } = this.state;
        if (!(userTypeId && userTypeId.length && department && phone)) return Toast.info('请完善报修信息', 2, null, false)
        if (phone.replace(/\s/g, "").length !== 11) return Toast.info('联系电话填写有误', 2, null, false)
        const res = await findRepairUser({ companyId: cookie.load('user').user_company_id })
        if (res.code === 20000) {
            this.setState({
                userData: res.data,
                visible: true,
            })
        }
    }

    // 防抖
    debounce(fn) {
        var timer;
        var _self = fn
        return function () {
            clearTimeout(timer)
            var args = arguments // fn所需要的参数
            var _me = this // 当前的this
            timer = setTimeout(function () {
                _self.call(_me, args[0])
            }, 800)
        }
    }

    // 查询维修工
    fetchMaintainer = async val => {
        const res = await findRepairUser({ companyId: cookie.load('user').user_company_id, userName: val })
        if (res.code === 20000) {
            this.setState({
                userData: res.data,
                visible: true,
            })
        }
    }

    // 报修工单
    handleSubmit = async item => {
        const { userTypeId, department, phone, urls, address, lon, lat } = this.state;
        console.log(urls)
        const req = {
            bx_source: userTypeId[0],
            bx_department_name: department,
            bx_phone: phone,
            worker_id: item.user_id,
            worker_name: item.user_name,
            urls: (urls && urls.length) ? urls.join(',') : '',
            area_name: address,
            latitude: lat,
            longitude: lon
        }
        const res = await phoneRepair(req)
        if (res.code === 20000) {
            Toast.info(res.message, 2, null, false);
            this.props.history.goBack()
        }
        window.location.href = 'tel:' + item.user_phone
    }

    render() {

        const { visible, userData, userTypeId, department, phone, address, lon, lat, locationVisible } = this.state;
        const userType = [
            {
                label: '普通用户',
                value: 1,
            },
            {
                label: '员工',
                value: 2,
            }
        ];
        const markerEvents = {
            click: async (e) => {
                const res = await getLocationInfoByLonAndLat(e.lnglat.lng, e.lnglat.lat)
                if (res.info === 'OK') {
                    this.setState({
                        address: res.regeocode.formatted_address,
                        lat: e.lnglat.lat,
                        lon: e.lnglat.lng,
                    })
                }
            }
        }

        return (
            <div>
                {this.renderGallery()}
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    电话报修
                </NavBar>
                <List>
                    <Picker
                        extra="请选择用户类型"
                        data={userType}
                        value={userTypeId}
                        cols={1}
                        onChange={(val) => {
                            this.setState({
                                userTypeId: val,
                            })
                        }}
                    >
                        <List.Item arrow="horizontal">用户类型</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入姓名" style={{ textAlign: 'right', paddingRight: 8 }} value={cookie.load('user').user_nick}>
                        微信昵称
                    </InputItem>
                    <InputItem placeholder="请输入单位部门" value={department} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        this.setState({
                            department: e
                        })
                    }}>
                        单位部门
                    </InputItem>
                    <InputItem placeholder="请输入联系电话" value={phone} style={{ textAlign: 'right', paddingRight: 8 }} type="phone" onChange={(e) => {
                        this.setState({
                            phone: e
                        })
                    }}>
                        联系电话
                    </InputItem>

                    {/* <InputItem placeholder="请输入位置信息" value={address} style={{ textAlign: 'right', paddingRight: 8 }} onChange={
                        e => this.setState({ address: e })
                    }>
                        位置信息
                    </InputItem> */}
                    <InputItem placeholder="请输入位置信息" value={address} style={{ textAlign: 'right', paddingRight: 8 }}
                    onFocus={(e) => {
                        this.setState({
                            locationVisible: true,
                        })
                    }}
                >
                    位置信息
                </InputItem>
                </List>
                <Cell>
                    <CellBody>
                        <Uploader
                            title="图片上传"
                            maxCount={4}
                            files={this.state.demoFiles}
                            onError={msg => alert(msg)}
                            onChange={(file) => {
                                let newFiles = [...this.state.demoFiles, { url: file.data }];
                                let files = file.nativeFile
                                this.setState({
                                    demoFiles: newFiles
                                });
                                Toast.loading('图片上传中', 0)
                                let formData = new FormData();
                                formData.append("multipartFile", files);
                                req_upload(formData).then(res => {
                                    this.setState({
                                        urls: this.state.urls.concat(res.data)
                                    }, () => {
                                        Toast.hide()
                                    })
                                })
                            }}
                            onFileClick={
                                (e, file, i) => {
                                    this.setState({
                                        gallery: {
                                            url: file.url,
                                            id: i
                                        }
                                    })
                                }
                            }
                            lang={{
                                maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                            }}
                        />
                    </CellBody>
                </Cell>
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <WingBlank size="lg">
                    <Button type="primary" onClick={() => this.modalClick()}>拨打电话</Button>
                </WingBlank>
                <WhiteSpace size="lg" />

                <Modal
                    visible={visible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                >
                    <div style={{ position: 'fixed', width: '100%' }}>
                        <SearchBar
                            placeholder="请输入维修工姓名"
                            ref={ref => this.manualFocusInst = ref}
                            onCancel={() => this.setState({ visible: false })}
                            onChange={this.debounce((val) => {
                                this.fetchMaintainer(val)
                            })}
                            onSubmit={(e) => this.fetchMaintainer(e)}
                        />
                    </div>
                    <div style={{ marginTop: 54, padding: '0 18px' }}>
                        {
                            userData.map(item => {
                                return <div key={item.user_id} className='modal_content' onClick={() => this.handleSubmit(item)}>
                                    <a style={{ display: 'flex' }} href="#">
                                        <div className='modal_img'>
                                            {
                                                item.user_avatar ? <img src={item.user_avatar} alt='' /> :
                                                    <img src={require("../../images/04.png")} alt='' />
                                            }
                                        </div>
                                        <div className='modal_info'>
                                            <div className='modal_info_content'>
                                                <span className='info_name'>{item.user_name}</span>&nbsp;&nbsp;
                                                <span className='info_nick'>昵称：{item.user_nick.length > 10 ? item.user_nick.substr(0, 10) + '...' : item.user_nick}</span>
                                            </div>
                                            <div className='modal_info_phone'>{item.user_phone}</div>
                                        </div>
                                    </a>
                                </div>
                            })
                        }
                    </div>
                </Modal>
                <Modal
                    visible={locationVisible}
                    transparent
                    onClose={() => {
                        this.setState({
                            locationVisible: false
                        })
                    }}
                >
                    <div style={{width: '100%', height: '400px'}}>
                        <Map amapkey={mapKey} zoom={15} plugins={['ToolBar']} center={ {longitude:lon, latitude: lat} } events={markerEvents}>
                            <Marker position={{longitude:lon, latitude: lat}}></Marker>
                        </Map>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default index
