import React, { Component } from 'react'
import { NavBar, Icon, List, TextareaItem, Button, Toast } from 'antd-mobile'
import { saveLog } from '../../service/api'

class journal extends Component {
    state = {
        logDesc: '', 
    }

    handleSubmit = async () => {
        const { logDesc } = this.state;
        const res = await saveLog({ content: logDesc })
        if (res.code === 20000) {
            setTimeout(()=>{
                this.props.history.goBack()
            },500)
        }
        Toast.info(res.message, 2, null, false)
    }

    render() {
        return (
            <div>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    新增日志
                </NavBar>
                <List renderHeader={() => '日志描述'}>
                    <TextareaItem
                        placeholder="请输入日志描述"
                        onChange={(e) => {
                            this.setState({
                                logDesc: e
                            })
                        }}
                        rows={5}
                        count={200}
                    />
                </List>
                <div style={{position: 'fixed', width: '100%', bottom: 0, background: '#fff'}}>
                    <div style={{padding: 8}}>
                        <Button type='primary' onClick={this.handleSubmit}>保存</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default journal