import React, { Component } from 'react'
// import { NavBar,Icon,SearchBar,ImagePicker,Toast,Modal,List} from 'antd-mobile'
import { NavBar, Icon, SearchBar, Toast, Modal, List, Tag } from 'antd-mobile'
import { req_uploadEnclosureFile, req_addEnclosureFile, req_findEnclosureFile, req_deleteEnclosureFile } from '../../service/api'
import IP from './../../config/config'
import './accessory.less'
import moment from 'moment'

class accessory extends Component {
    state = {
        popoverVisible: false,
        data: [],
        visible: false,
        list: {},
        pe_filename: '',
        peStatus: 0
    }

    componentDidMount = () => {
        this.findData();
    }

    findData = () => {
        const { pe_filename, peStatus } = this.state;
        req_findEnclosureFile({ userName: pe_filename, peStatus:peStatus!==0?peStatus:'', projectId: this.props.match.params.id }).then(res => {
            if (res.code === 20000) {
                this.setState({
                    data: res.data
                })
            } else {
                Toast.fail(res.message, 1)
            }
        })
    }

    onChange = (e) => {
        this.props.history.push(`/project/fileUpload/${this.props.match.params.id}`)
    };

    render = () => {
        const { data, visible, pe_filename, peStatus } = this.state;

        return (<div>
            <NavBar
                mode='mode'
                icon={<Icon type='left' />}
                onLeftClick={() => this.props.history.goBack()}
            >
                我的附件
            </NavBar>
            {/* 1 项目需求文档 2原型 3效果图 4发布服务器（地址，账号、密码）、5 项目使用手册 6项目宣传PPT */}
            <div className='detailsTag'>
                <div className='detailsWidth'>
                    <Tag data-seed="logId" selected={peStatus === 0} onChange={() => {
                        this.setState({
                            peStatus: 0
                        }, () => {
                            this.findData()
                        })
                    }}>全部</Tag>
                    <Tag data-seed="logId" selected={peStatus === 1} onChange={() => {
                        this.setState({
                            peStatus: 1
                        }, () => {
                            this.findData()
                        })
                    }}>文档</Tag>
                    <Tag data-seed="logId" selected={peStatus === 2} onChange={() => {
                        this.setState({
                            peStatus: 2
                        }, () => {
                            this.findData()
                        })
                    }}>原型</Tag>
                    <Tag data-seed="logId" selected={peStatus === 3} onChange={() => {
                        this.setState({
                            peStatus: 3
                        }, () => {
                            this.findData()
                        })
                    }}>效果图</Tag>
                    <Tag data-seed="logId" selected={peStatus === 4} onChange={() => {
                        this.setState({
                            peStatus: 4
                        }, () => {
                            this.findData()
                        })
                    }}>服务器</Tag>
                    <Tag data-seed="logId" selected={peStatus === 5} onChange={() => {
                        this.setState({
                            peStatus: 5
                        }, () => {
                            this.findData()
                        })
                    }}>手册</Tag>
                    <Tag data-seed="logId" selected={peStatus === 6} onChange={() => {
                        this.setState({
                            peStatus: 6
                        }, () => {
                            this.findData()
                        })
                    }}>ppt</Tag>
                </div>
            </div>
            <div className="top-div">
                <SearchBar placeholder="搜索用户名" className="seek"
                    value={pe_filename}
                    onChange={(xmgl_name) => {
                        this.setState({
                            pe_filename: xmgl_name
                        }, () => {
                            this.findData()
                        })
                    }}
                />
            </div>
            
            <div style={{ paddingTop: "54px" }}>
                {
                    data.map((v, k) => {
                        return (
                            <div className="tabs-access" key={k} onClick={() => {
                                this.setState({
                                    list: v,
                                    visible: true,
                                })
                            }}>
                                <div className="tabs-img">

                                    <img src={IP.TypeIcon[v.pe_filepath.substring(v.pe_filepath.lastIndexOf(".") + 1)] ? IP.host + IP.TypeIcon[v.pe_filepath.substring(v.pe_filepath.lastIndexOf(".") + 1)] : IP.host + IP.TypeIcon['error']} width="40px" alt="" />
                                </div>
                                <div className="tabs-main">
                                    <div className="tabs-title">{v.pe_filename.length > 24 ? v.pe_filename.substr(0, 24) + "..." : v.pe_filename}</div>
                                    <div className="tabs-size">
                                        <span>{v.pe_size}MB</span>
                                        <span>{v.pe_upload_username}</span>
                                        <span>{moment(v.pe_upload_time).format("YYYY/MM/DD HH:mm")}</span>
                                    </div>
                                </div>
                                <div className="tabs-icon">
                                    <span>•</span>
                                    <span>•</span>
                                    <span>•</span>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            {/* {
                    this.props.match.params.status !== '2' && <div className="img">
                        <ImagePicker
                            onChange={this.onChange}
                            length={1}
                        />
                    </div>
                } */}
            {
                this.props.match.params.status !== '2' && <div className='home_upload'>
                    <img src={require('../../images/10.png')} onClick={e => {
                        this.props.history.push(`/project/fileUpload/${this.props.match.params.id}`)
                    }} alt='' />
                    {/* <input type='file' onChange={e => this.onChange(e)} /> */}
                </div>
            }
            <Modal
                popup
                className="modal-sub"
                visible={visible}
                onClose={() => {
                    console.log(11);
                    // this.setState({
                    //     visible:true,
                    // })
                }}
                animationType="slide-up"
                afterClose={() => {
                    console.log(222);
                    // this.setState({
                    //     visible:false,
                    // })
                }}
            >
                <List className="listSub">
                    <List.Item>
                        <div className="buttonSub" onClick={() => {
                            const { list } = this.state;
                            window.location.href = IP.host + list.pe_filepath
                        }}>查看</div>
                    </List.Item>
                    {
                        this.props.match.params.status !== '2' && <List.Item>
                            <div className="buttonSub" style={{ color: "#F96849" }} onClick={() => {
                                const { list } = this.state;
                                req_deleteEnclosureFile({ enclosureFileId: list.pe_id }).then(res => {
                                    if (res.code === 20000) {
                                        Toast.success(res.message, 1);
                                        this.findData();
                                        this.setState({
                                            visible: false,
                                        })
                                    } else {
                                        Toast.fail(res.message, 1);
                                    }
                                })
                            }}>删除</div>
                        </List.Item>
                    }
                    <List.Item>
                        <div className="buttonSub" style={{ color: "#1576FF" }} onClick={() => {
                            this.setState({
                                visible: false,
                            })
                        }}>取消</div>
                    </List.Item>
                </List>
            </Modal>
        </div>)
    }

}
export default accessory