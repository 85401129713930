import React , {Component} from 'react'
import { NavBar,Icon,TextareaItem,Button,Toast} from 'antd-mobile'
import {Link} from 'react-router-dom'
import { req_addProjectReport, findReportById, updateProjectReport } from './../../service/api'

class dailyAdd extends Component{
    state={
        pr_content:'',
        projectId: null,
        editId: null,
    }

    componentDidMount = async () => {
        const record = this.props.match.params;
        this.setState({
            projectId: record.projectId,
            editId: record.editId,
        })
        if (Number(record.editId)) {
            const res = await findReportById({ perId: record.editId })
            if (res.code === 20000) {
                this.setState({
                    pr_content: res.data.pr_content
                })
            }
        }
    }

    subAdd = async () => {
        const { pr_content, projectId, editId }=this.state;
        let params = {
            pr_content,
            pr_projectid: projectId,
            pr_id: editId
        }
        if(pr_content===''){
            Toast.fail("请填写内容",1)
            return
        }
        const method = Number(editId) ? updateProjectReport : req_addProjectReport;
        const res = await method(params);
        if (res.code === 20000) {
            setTimeout(()=>{
                this.props.history.push({pathname:`/project/dailyDetails/${projectId}/${this.props.match.params.status}`});
            }, 1000)
            Toast.info(res.message, 2, null, false);
        }
    }


    render=()=>{
        const { projectId, pr_content } = this.state;
        return (<div style={{height:"100vh",background:"#F5F5F5"}}>
                    <NavBar
                        mode='mode'
                        icon={<Icon type='left' />}
                        onLeftClick={() => this.props.history.goBack()}
                        rightContent={
                            <Link to={`/project/dailyDetails/${projectId}/${this.props.match.params.status}`} style={{color:"#fff",textDecoration:"underline"}}>
                                我的日报
                            </Link>
                        }
                    >
                        我的日报
                    </NavBar>
                    
                    <div style={{paddingTop:"6px"}}>
                        <div style={{fontSize:"16px",color:"#1C2833",background:"#fff",padding:"10px 15px 0px"}}>日报描述</div>
                        <TextareaItem
                            placeholder="请输入内容"
                            rows={5}
                            count={200}
                            disabled={this.props.match.params.status === '2'}
                            value={pr_content}
                            onChange={(v)=>{
                                this.setState({
                                    pr_content:v
                                })
                            }}
                            style={{fontSize:"15px"}}
                        />
                    </div>
                    
                    {
                        this.props.match.params.status !== '2' && <div style={{width:"100%",position:"fixed",bottom:"10px",zIndex:"999"}}>
                            <Button  type="primary" className="button-footer" onClick={()=>{
                                this.subAdd()
                            }}>保存</Button>
                        </div>
                    }
        </div>)
    }

}
export default dailyAdd