import { observable, action } from 'mobx';


class globalStore {
    @observable appId = '';

    @observable user = '';

    @observable funcData = [];


    @action setAppID = appid => {
        this.appId = appid;
    }

    @action setUser = user => {
        this.user = user;
    }
    // @action setVisible = bool => {
    //     console.log(bool)
    //     this.visible = bool;
    // }
    @action setFuncData = funcData => {
        this.funcData = funcData
    }
}
export default new globalStore();