import { getWxConfigParams } from '../service/api';
import Utils from './utils';
import wx from 'weixin-js-sdk'

const JSSDK = {
    InitWxConfig: async (appId) => {
        // const timeStamp = Date.parse(new Date());
        const timeStamp = parseInt(new Date().getTime()/100);
        const noncestr = Utils.getUUID();
        const result = await getWxConfigParams(appId, window.location.href.split('#')[0], timeStamp, noncestr);
        if (result.code === 20000) {
            await window.wx.config({
                beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId, // 必填，企业微信的corpID
                timestamp: Number(result.data.timestamp), // 必填，生成签名的时间戳
                nonceStr: result.data.noncestr, // 必填，生成签名的随机串
                signature: result.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                jsApiList: ['startRecord', 'stopRecord', 'translateVoice', 'uploadVoice', 'getLocation', 'playVoice'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            })
        }
    },
    handleConvertText: (localId, func) => {
        // 转换为文字
        wx.translateVoice({
            localId: localId, // 需要识别的音频的本地Id，由录音相关接口获得，音频时长不能超过60秒
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
                // alert(res.translateResult); // 语音识别的结果
                func(res.translateResult)
            }
        })
    }
    ,
    handleVoiceStart: () => {
        // 点击开始按钮的时候
        wx.startRecord();
    },
    handleVoiceEnd: () => new Promise(resolve => {
        // 点击结束按钮时候
        wx.stopRecord({
            success: (res) => {
                var localId = res.localId;
                JSSDK.handleConvertText(localId, text => {
                    wx.uploadVoice({
                        localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                        isShowProgressTips: 1, // 默认为1，显示进度提示
                        success: function (res) {
                            var serverId = res.serverId; // 返回音频的服务器端ID
                            resolve({ textVoice: text, serverId, localId })
                        }
                    });
                })
            }
        });
    }),

    /**
     *  获取位置信息
     * @param {String} type 类型 wgs84 gps坐标 gcj02 火星坐标
     */
    getLocation: (type = 'gcj02') => new Promise(resolve => {
        wx.getLocation({
            type: type, // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。

                resolve({ lat: latitude, lon: longitude })
            }
        })
    })
}

export default JSSDK;