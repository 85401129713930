import React, { Component } from 'react';
// import { NavBar, Icon, Modal, Button, Toast, ImagePicker } from 'antd-mobile';
import { NavBar, Icon, Modal, Button, Toast } from 'antd-mobile';
import IP from '../../config/config';
import { findTaskById, updateTask, uploadTaskFile } from '../../service/api';
import cookie from 'react-cookies';
import './index.less'

class enclosure extends Component {
    state = {
        enclosureData: [],
        visible: false,
        list: {},
        publishId: null, // 发布人id
        taskId: null, // 任务id
        joinUsers: [], // 参与用户
    }

    componentDidMount = async () => {
        this.fetch(this.props.match.params.id)
    }

    fetch = async taskId => {
        const res = await findTaskById({ taskId })
        if (res.code === 20000) {
            this.setState({
                enclosureData: res.data[0].task_files && res.data[0].task_files.split(',').map(x => {
                    return {fileName: x.split("*")[0], size: x.split("*")[1], url: x.split("*")[2]}
                }),
                publishId: res.data[0].publish_user_id,
                joinUsers: res.data[0].joinUsers,
            })
        }
    }

    // 删除
    handleDel = async list => {
        const { enclosureData, joinUsers } = this.state;
        console.log(enclosureData)
        const array = enclosureData.filter(x => x.url !== list.url)
        console.log(array)
        const req = {
            task_id: Number(this.props.match.params.id),
            task_files: array && array.length ? array.map(x=>(x.fileName+'*'+x.size+'*'+x.url)).join(',') : '',
        }
        const res = await updateTask({ taskEntity: JSON.stringify(req), joinUserIds: joinUsers.map(x => x.user_id).join(',') })
        if (res.code === 20000) {
            Toast.info('删除成功')
            this.fetch(this.props.match.params.id)
            this.setState({
                visible: false,
            })
        }
    }

    onChange = (e) => {
        const { joinUsers, enclosureData } = this.state;
        const { files } = e.target;
        Toast.loading('上传中...')
        if (files && files.length) {
            let formData = new FormData();
            formData.append("file", files[0]);
            uploadTaskFile(formData).then(res=>{
                if(res.code === 20000){
                    const array = (enclosureData || []).concat(res.data)
                    const req = {
                        task_id: Number(this.props.match.params.id),
                        task_files: array && array.length ? array.map(x=>(x.fileName+'*'+x.size+'*'+x.url)).join(',') : '',
                    }
                    updateTask({ taskEntity: JSON.stringify(req), joinUserIds: joinUsers.map(x => x.user_id).join(',') }).then(result=>{
                        if(result.code===20000){
                            Toast.info(result.message)
                        }else{
                            Toast.fail(result.message)
                        }
                        this.fetch(this.props.match.params.id)
                        this.setState({
                            visible: false,
                        })
                    })
                }else{
                    Toast.fail(res.message,1)
                }
            })
        }
    };

    render() {
        const { enclosureData, visible, list, publishId } = this.state;
        return (
            <div>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    任务附件
                </NavBar>
                <div className='taskDetail_tabs'>
                    {
                        (enclosureData || []).map((v,k)=>{
                            return (
                            <div className="tabs-access" key={k}>
                                <div className="tabs-img">
                                    <img src={IP.TypeIcon[v.url.substring(v.url.lastIndexOf(".")+1)]?IP.host + IP.TypeIcon[v.url.substring(v.url.lastIndexOf(".")+1)]:IP.host + IP.TypeIcon['error']} width="40px" alt=""/>
                                </div>
                                <div className="tabs-main">
                                    <div className="tabs-title">{v.fileName.length>24?v.fileName.substr(0,24)+"...":v.fileName}</div>
                                    <div className="tabs-size">
                                        <span>{Number(v.size) ? Number(v.size).toFixed(2) : 0}MB</span>
                                    </div>
                                </div>
                                <div className="tabs-icon" onClick={()=>{
                                    this.setState({
                                        list: v,
                                        visible: true,
                                    })
                                }}>
                                    <span>•</span>
                                    <span>•</span>
                                    <span>•</span>
                                </div>
                            </div>
                            )
                        })
                    }
                    {/* {
                        Number(this.props.match.params.status) ? '' : 
                        <div className="img">
                            <ImagePicker
                                onChange={this.onChange}
                                length={1}
                            />
                        </div>
                    } */}
                    {
                        Number(this.props.match.params.status) ? '' : 
                        <div className='home_upload'>
                            <img src={require('../../images/10.png')} alt='' />
                            <input type='file' onChange={e => this.onChange(e)} />
                        </div>
                    }
                </div>
                <Modal
                    popup
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    animationType="slide-up"
                    className='modal_repairs'
                >
                    <div>
                        <Button style={{ color: '#0084ff' }} onClick={() => { window.location.href = IP.host + list.url }}>查看</Button>
                        {
                            cookie.load('user').user_id === publishId && (!Number(this.props.match.params.status)) ?
                            <Button style={{ color: 'red' }} onClick={() => this.handleDel(list)}>删除</Button> : ''
                        }
                        <Button onClick={() => this.setState({ visible: false })}>取消</Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default enclosure;