/* eslint-disable no-extend-native */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import RouteMap from './routers/index';
import { queryAppIDByHost } from './service/api';
import { observer } from 'mobx-react';
import globalStore from './stores/globalStore'
import moment from 'moment';
// import vConsole from 'vconsole'


// const vconsole = new vConsole();
window.moment = moment;
@observer
class App extends Component {

  state = {

  }

  queryAppidByHost = async () => {
    const { setAppID } = globalStore;
    const website = window.location.host;
    const result = await queryAppIDByHost(website);
    if (result.code === 20000) {
      setAppID(result.data)
    }
  }

  componentDidMount = () => {
    Array.prototype.group = function (size) {
      return new Array(Math.ceil(this.length / size)).fill(0).map((item, index) => (this.filter((e, i) => i >= (index * size) && i < (index * size) + size)))
    }
    this.queryAppidByHost()
  }

  render() {
    const { appId } = globalStore;
    return (
      <>
        <HashRouter>
          {appId && <RouteMap />}
        </HashRouter>
      </>
    )
  }

}


export default App;
