import React, { Component } from 'react'
import { findBusinessRecordById, deleteBusinessRecordById, uploadImg, finishBusinessRecord } from '../../service/api'
import { NavBar, Icon, List, InputItem, TextareaItem, ImagePicker, Button, Toast, Modal } from 'antd-mobile'
import { Gallery, GalleryDelete, Uploader, Cell, CellBody } from 'react-weui';
import IP from '../../config/config'
import './index.less'

class recordInfo extends Component {
    state = {
        detailData: {}, // 详情
        startFiles: [], // 拜访前图片
        endFiles: [], // 拜访后图片
        recordId: null, // 详情记录id
        visible: false,
        urls: [],
        gallery: false,
        demoFiles: [],
    }
    componentDidMount = () => {
        // document.getElementsByClassName("weui-uploader__input")[0].removeAttribute("capture")
        const record = this.props.match.params;
        this.fetchDetail(record.id)
    }

    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    fetchDetail = async recordId => {
        const res = await findBusinessRecordById({ recordId })
        if (res.code === 20000) {
            const startFiles = res.data.visit_start_picture ? res.data.visit_start_picture.split(',').map((x, i) => ({ url: IP.host + x, id: i + 1 })) : []
            const endFiles = res.data.visit_end_picture ? res.data.visit_end_picture.split(',').map((x, i) => ({ url: IP.host + x, id: i + 1 })) : []
            this.setState({
                detailData: res.data,
                startFiles,
                endFiles
            })
        }
    }

    // 编辑
    handleEdit = id => {
        this.props.history.push({ pathname: `/business/callOn/${id}` })
    }

    // 删除
    handleDel = async recordId => {
        const res = await deleteBusinessRecordById({ recordId })
        if (res.code === 20000) {
            setTimeout(()=>{
                this.props.history.goBack()
            },500)
        }
        Toast.info(res.message, 2, null, false)
    }

    // 拜访完成
    handleFinish = id => {
        this.setState({
            recordId: id,
            visible: true,
        })
    }

    // 完成
    handleOk = async () => {
        const { urls, recordId } = this.state;
        console.log(urls, recordId)
        if (!(urls && urls.length)) return Toast.info('请上传完成后的照片', 2, null, false)
        const res = await finishBusinessRecord({ recordId, picUrls: urls.join(',') })
        if (res.code === 20000) {
            this.setState({
                visible: false,
            })
            this.fetchDetail(recordId)
            setTimeout(()=>{
                this.props.history.goBack()
            },1000)
        }
        Toast.info(res.message, 2, null, false)
    }

    render() {

        const { detailData, startFiles, endFiles, visible } = this.state;

        return (
            <div style={{height: '100vh', background: '#f5f5f5'}}>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    拜访详情
                </NavBar>
                <div className='recordInfo' style={{background: '#f5f5f5'}}>
                    <List>
                        <InputItem placeholder="请输入客户姓名" style={{ textAlign: 'right', paddingRight: 8 }} value={detailData.customer_name}>
                            客户姓名
                    </InputItem>
                        <InputItem placeholder="请输入联系电话" value={detailData.customer_phone} style={{ textAlign: 'right', paddingRight: 8 }} type="phone">
                            联系电话
                    </InputItem>
                        <InputItem placeholder="请输入公司名称" value={detailData.visit_company_name} style={{ textAlign: 'right', paddingRight: 8 }}>
                            公司名称
                    </InputItem>
                    </List>
                    <List renderHeader={() => '拜访内容'}>
                        <TextareaItem
                            placeholder="请输入拜访内容"
                            value={detailData.visit_description}
                            rows={5}
                            count={200}
                        />
                    </List>
                    {
                        startFiles && startFiles.length ? <List renderHeader={() => '拜访前图片'}>
                            <ImagePicker
                                files={startFiles}
                                disableDelete
                                selectable={false}
                            />
                        </List> : ''
                    }
                    {
                        endFiles && endFiles.length ? <List renderHeader={() => '拜访后图片'}>
                            <ImagePicker
                                files={endFiles}
                                disableDelete
                                selectable={false}
                            />
                        </List> : ''
                    }
                    {
                        detailData.visit_state ? '' :
                        <div>
                            <Button style={{ color: '#1190ff' }} onClick={() => this.handleEdit(detailData.business_record_id)}>编辑</Button>
                            <Button style={{ color: 'red' }} onClick={() => this.handleDel(detailData.business_record_id)}>删除</Button>
                        </div>
                    }
                    
                </div>
                {
                    detailData.visit_state ? '' :
                        <div style={{position: 'fixed', width: '100%', bottom: 0, background: '#fff'}}>
                        <div style={{padding: 8}}>
                            <Button type='primary' onClick={() => this.handleFinish(detailData.business_record_id)}>拜访完成</Button>
                        </div>
                    </div>
                }
                <Modal
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    transparent
                    className='recordInfo_modal'
                >
                    {this.renderGallery()}
                    <div>
                        <Cell>
                            <CellBody>
                                <Uploader
                                    title='图片上传'
                                    maxCount={4}
                                    files={this.state.demoFiles}
                                    onError={msg => alert(msg)}
                                    onChange={(file) => {
                                        let newFiles = [...this.state.demoFiles, { url: file.data }];
                                        let files = file.nativeFile
                                        this.setState({
                                            demoFiles: newFiles
                                        });
                                        Toast.loading('图片上传中', 0)
                                        let formData = new FormData();
                                        formData.append("multipartFile", files);
                                        uploadImg(formData).then(res => {
                                            this.setState({
                                                urls: this.state.urls.concat(res.data)
                                            }, () => {
                                                Toast.hide()
                                            })
                                        })
                                    }}
                                    onFileClick={
                                        (e, file, i) => {
                                            this.setState({
                                                gallery: {
                                                    url: file.url,
                                                    id: i
                                                }
                                            })
                                        }
                                    }
                                    lang={{
                                        maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                                    }}
                                />
                            </CellBody>
                        </Cell>
                        <div style={{ marginTop: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button style={{ marginRight: 20, width: 100, height: 37, lineHeight: '37px' }} type="primary" onClick={this.handleOk}>完成</Button>
                            <Button style={{ width: 100, height: 37, lineHeight: '37px' }} type="warning" onClick={() => this.setState({ visible: false })}>取消</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default recordInfo;
