import React, { Component } from 'react';

class Clear extends Component {
    componentDidMount = () => {

    }

    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default Clear;