import React , {Component} from 'react'
import { NavBar,Icon,TextareaItem,Button,Toast, ImagePicker, List} from 'antd-mobile'
import {Link} from 'react-router-dom'
import { addProjectMilepostCheck, uploadImage } from './../../service/api'
import cookie from 'react-cookies'

class dailyAdd extends Component{
    state={
        pc_content:'', // 验收信息
        files: [],//文件列表
        imgArr:[], // 文件路径
    }

    componentDidMount=()=>{

    }

    subAdd=()=>{
        const { pc_content,imgArr }=this.state;
        let params={
            checkMessage:pc_content,
            milepostId:this.props.match.params.id,
            imgUrls:imgArr.join(',')
        }
        if(pc_content===''){
            Toast.fail("请填写验收内容",1)
            return
        }
        addProjectMilepostCheck(params).then(res=>{
            if(res.code===20000){
                Toast.success(res.message,1);
                setTimeout(()=>{
                    this.props.history.goBack()
                },1000)
                
            }else{
                Toast.fail(res.message,1)
            }
        })
    }
    
      // 上传文件
    uploadImage = (files, type, index) => {
        const { imgArr } = this.state;

        // 添加

        if(type==="add"){
            if (files.length === 0) return false;
            let param = new FormData(); //创建form对象
            param.append('multipartFile', files[files.length-1].file);//通过append向form对象添加数据
            const xhr = new XMLHttpRequest()
            xhr.open('POST', uploadImage(), true)
            xhr.setRequestHeader("x-auth-token", cookie.load('token'))
            xhr.send(param)
            xhr.onload = (res) => {
                const data = JSON.parse(res.currentTarget.responseText).data
                this.setState({
                    imgArr: imgArr.concat(data)
                })
            }
            xhr.upload.onprogress = (e) => {
                console.log(e)
            }
            xhr.onerror = (err) => {
                Toast.fail('上传失败');
            }
        }

        // 删除图片
        if(type==="remove"){
            this.setState({
                imgArr:imgArr.filter((x,indexs)=>indexs!==index)
            })
        }
        this.setState({
            files,
        });
    }

     

    render=()=>{
        const { files } = this.state;
        return (
            <div style={{height:"100vh",background:"#F5F5F5"}}>
                    <NavBar
                        mode='mode'
                        icon={<Icon type='left' />}
                        onLeftClick={() => this.props.history.goBack()}
                    >
                        我的验收
                    </NavBar>
                    
                    <div style={{paddingTop:"6px"}}>
                        <div style={{fontSize:"16px",color:"#1C2833",background:"#fff",padding:"10px 15px 0px"}}>验收描述</div>
                        <TextareaItem
                            placeholder="请输入内容"
                            rows={5}
                            count={200}
                            onChange={(v)=>{
                                this.setState({
                                    pc_content:v
                                })
                            }}
                            style={{fontSize:"15px"}}
                        />
                    </div>
                    
                    <div>
                    {
                            <ImagePicker
                                files={files}
                                onChange={(files, type, index) => this.uploadImage(files, type, index)}
                                selectable={files.length < 5}
                            />
                    }
                    </div>

                    <div style={{width:"100%",position:"fixed",bottom:"10px",zIndex:"999"}}>
                        <Button  type="primary" className="button-footer" onClick={()=>{
                            this.subAdd()
                        }}>保存</Button>
                    </div>
            </div>
        )
    }

}
export default dailyAdd