import http from "../utils/http";
import jsonp from './../utils/jsonp';
import IP from "../config/config";
import Axios from "axios";

const key = '993d6b7fa8baed9c49a865f8ddaa4147'

/**
 * 查询数据
 * @param {*} website 域名
 */
export const queryAppIDByHost = website => http.get('/sysofficialaccount/findWxAppid', { website })

/**
 * 根据Code和AppID获取用户信息
 * @param {*} code 
 * @param {*} appId 
 */
export const qeuryUserInfoByCode = (code, appId) => http.post('/syslogin/wxLogin', { code, appId });


/**
 * 查询轮播图
 */
export const queryCarouselData = () => http.get('/sysIndex/findAppSlideshow');

/**
 * 手机端查询模块信息
 */
export const queryModuleData = () => http.get('/sysIndex/findAppModule')

/**
 * 测试接口
 */
export const queryTest = () => http.get('/sysuser/aaa')

/**
 * 上传图片
 * @param { any } file 文件
 */
export const req_upload = params => {
    return http.upload('/repairorderapp/uploadHitchImgae', params)
}

/**
 * 填单报修
 */
export const fillInRepair = params => {
    return http.jsonPost('/repairorderapp/fillInRepair', params)
}

/**
 * 获取报修人电话信息
 */
export const findRepairUser = params => {
    return http.get('/sysuser/findRepairUser', params)
}

/**
 * 电话报修提交工单
 */
export const phoneRepair = params => {
    return http.jsonPost('/repairorderapp/phoneRepair', params)
}

/**
 * 获取微信签名
 * @param {String} appId 当前公众号的APPID
 * @param {String} url 当前url
 * @param {String} timestamp 时间戳
 * @param {String} noncestr 随机字符串
 */
export const getWxConfigParams = (appId, url, timestamp, noncestr) => http.get('/syslogin/getSign', { appId, url, timestamp, noncestr })

/**
 * 根据经纬度获取位置信息
 * @param {*} lon 精度
 * @param {*} lat 维度
 */
export const getLocationInfoByLonAndLat = (lon, lat) => jsonp('https://restapi.amap.com/v3/geocode/regeo', { output: 'json', location: `${lon}, ${lat}`, key: key, radius: 1000, extensions: 'all' })
/**
 * 根据模块id查询当前登录人对应的功能
 */
// export const findModuleFunction = params => {
//     return http.get('/sysIndex/findModuleFunction', params)
// }
export const findModuleFunction = params => {
    return http.get('/sysuserrole/findUserRoleByid', params)
}

/**
 * 手机端维修人员查询工单
 */
export const findRepairUserOrder = params => {
    return http.get('/repairorderapp/findRepairUserOrder', params)
}

/**
 * 手机端查询用户自己的报修订单和共享的
 */
export const findUserOrder = params => {
    return http.get('/repairorderapp/findUserOrder', params)
}
/**
 * 语音报修
 * @param {*} mediaId 语音serverId
 * @param {*} bx_source 报修来源
 * @param {*} bx_department_name 部门名称
 * @param {*} urls 图片地址逗号分隔
 * @param {*} latitude 维度
 * @param {*} longitude 经度
 * @param {*} area_name 地点名称
 * @param {*} bx_phone 手机号
 * @param {*} order_desc 故障描述
 */
export const voiceRepair = (mediaId, bx_source, bx_department_name, urls, latitude, longitude, area_name, bx_phone, order_desc) => http.jsonPost('/repairorderapp/voiceRepair', { mediaId, bx_source, bx_department_name, urls, latitude, longitude, area_name, bx_phone, order_desc })

/**
 * 手机端查询项目列表
 */
export const req_findListByName = params => {
    return http.get('/project/findListByName', params)
}
/**
 * 接单、完成
 */
export const orderReceiving = params => {
    return http.post('/repairorderapp/orderReceiving', params)
}

/**
 * 转派、指派
 */
export const redeployOrder = params => {
    return http.post('/repairorderapp/redeployOrder', params)
}

/**
 * 评价、共享
 */
export const operationUserOrder = params => {
    return http.post('/repairorderapp/operationUserOrder', params)
}

/**
 * 查询共享用户
 */
export const findShareUser = params => {
    return http.get('/repairorderapp/findShareUser', params)
}

/**
 * 手机端查询待指派的工单
 */
export const findAppointOrder = params => {
    return http.get('/repairorderapp/findAppointOrder', params)
}

/**
 * 查询工单详情
 */
export const findOrderById = params => {
    return http.get('/repairorderapp/findOrderById', params)
}

/**
 * 电话报修，接单人员填写描述
 */
export const phoneDescRepair = params => {
    return http.post('/repairorderapp/updateOrder', params)
}

/**
 * 根据项目id查询项目详情
 */
export const req_findDetailsById = params => {
    return http.get('/project/findDetailsById', params)
}

/**
 * 完成项目里程碑
 */
export const req_completeProjectMilepost = params => {
    return http.post('/projectmilepost/completeProjectMilepost', params)
}

/**
 * 查询项目工作计划
 */
export const req_findListPlan = params => {
    return http.get('/projectplan/findListPlan', params)
}

/**
 * 根据项目id查询日报信息
 */
export const req_findReport = params => {
    return http.get('/projectreport/findReport', params)
}

/**
 * 完成项目工作计划
 */
export const req_completeProjectPlan = params => {
    return http.post('/projectplan/completeProjectPlan', params)
}

/**
 * 新增日报
 */
export const req_addProjectReport = params => {
    return http.post('/projectreport/addProjectReport', params)
}

/**
 * 手机端用户查看自己的项目日报表
 */
export const req_findReportByUser = params => {
    return http.get('/projectreport/findReportByUser', params)
}

/**
 * 查询单条日报详情信息
 */
export const findReportById = params => {
    return http.get('/projectreport/findReportById', params)
}

/**
 * 编辑日报
 */
export const updateProjectReport = params => {
    return http.jsonPost('/projectreport/updateProjectReport', params)
}

/**
 * 上传项目附件
 */
export const req_uploadEnclosureFile = params => {
    return http.jsonPost('/projectenclosure/uploadEnclosureFile', params)
}

/**
 * 新增附件
 */
export const req_addEnclosureFile = params => {
    return http.jsonPost('/projectenclosure/addEnclosureFile', params)
}

/**
 * 查询我的附件
 */
export const req_findEnclosureFile = params => {
    return http.get('/projectenclosure/findEnclosureFile', params)
}

/**
 * 根据附件id删除附件信息
 */
export const req_deleteEnclosureFile = params => {
    return http.get('/projectenclosure/deleteEnclosureFile', params)
}
/* 上传项目模块图片
* @param { any } file 文件
*/
export const uploadImage = () => IP.host + '/project/uploadProjectImage'

/**
 * 查询成员信息
 */
export const findProjectUser = params => {
    return http.get('/project/findProjectUser', params)
}

/**
 * 新增项目
 */
export const saveProject = params => {
    return http.jsonPost('/project/saveProject', params)
}

/**
 * 根据id查询项目详情 用于编辑
 */
export const findAppProjectInfoById = params => {
    return http.get('/project/findAppProjectInfoById', params)
}

/**
 * 编辑项目
 */
export const updateProject = params => {
    return http.jsonPost('/project/updateProject', params)
}


/**
 * 根据项目id查询里程碑信息
 */
export const req_findProjectMilepostById = params => {
    return http.get('/projectmilepost/findProjectMilepostById', params)
}

/**
 * 新增项目里程碑
 */
export const req_addProjectMilepost = params => {
    return http.jsonPost('/projectmilepost/addProjectMilepost', params)
}

/**
 * 根据里程碑id查询里程碑信息
 */
export const req_findProjectMilepostByMilepostId = params => {
    return http.get('projectmilepost/findProjectMilepostByMilepostId', params)
}

/**
 * 修改项目里程碑
 */
export const req_updateProjectMilepost = params => {
    return http.jsonPost('/projectmilepost/updateProjectMilepost', params)
}

/**
 * 删除项目里程碑
 */
export const req_deleteProjectMilepost = params => {
    return http.post('/projectmilepost/deleteProjectMilepost', params)
}

/**
 * 根据项目id查询计划信息
 */
export const req_findProjectPlan = params => {
    return http.get('/projectplan/findProjectPlan', params)
}

/**
 * 新增项目工作计划
 */
export const req_addProjectPlan = params => {
    return http.jsonPost('/projectplan/addProjectPlan', params)
}

/**
 * 修改项目工作计划
 */
export const req_updateProjectPlan = params => {
    return http.jsonPost('/projectplan/updateProjectPlan', params)
}

/**
 * 根据计划id查询计划详情
 */
export const req_findProjectPlanByPlanId = params => {
    return http.get('/projectplan/findProjectPlanByPlanId', params)
}

/**
 * 删除项目工作计划
 */
export const req_deleteProjectPlan = params => {
    return http.post('/projectplan/deleteProjectPlan', params)
}
// 业务管理

/**
 * 上传图片
 * @param { any } file 文件
 */
export const uploadImg = params => {
    return http.upload('/businessrecords/uploadBusinessRecordPic', params)
}

/**
 * 新增拜访记录
 */
export const saveBusinessRecord = params => {
    return http.jsonPost('/businessrecords/saveBusinessRecord', params)
}

/**
 * 编辑拜访记录
 */
export const updateBusinessRecord = params => {
    return http.jsonPost('/businessrecords/updateBusinessRecord', params)
}

/**
 * 查询拜访记录
 */
export const findUserBusinessRecords = params => {
    return http.get('/businessrecords/findUserBusinessRecords', params)
}

/**
 * 拜访记录详情
 */
export const findBusinessRecordById = params => {
    return http.get('/businessrecords/findBusinessRecordById', params)
}

/**
 * 删除拜访记录
 */
export const deleteBusinessRecordById = params => {
    return http.get('/businessrecords/deleteBusinessRecordById', params)
}

/**
 * 拜访完成
 */
export const finishBusinessRecord = params => {
    return http.post('/businessrecords/finishBusinessRecord', params)
}

/**
 * 新增日志
 */
export const saveLog = params => {
    return http.jsonPost('/businesslogs/saveBusinessLogs', params)
}

/**
 * 查询日志
 */
export const findBusinessLogsByUserId = params => {
    return http.get('/businesslogs/findBusinessLogsByUserId', params)
}

/**
 * 查询项目验收信息
 */
export const req_findProjectCheckList = params => {
    return http.get('/projectcheck/findProjectCheckList', params)
}

/**
 * 添加项目验收信息
 */
export const req_addProjectCheck = params => {
    return http.jsonPost('/projectcheck/addProjectCheck', params)
}

/**
 * 查询打卡记录
 */
export const findAppAttendanceRecordList = params => {
    return http.get('/attendancerecordlist/findAppAttendanceRecordList', params)
}

/**
 * 实名认证
 */
export const authentication = params => {
    return http.jsonPost('/sysuser/authentication', params)
}

/**
 * 查询轨迹记录
 */
export const findUserPath = params => {
    return http.get('/userpath/findUserPath', params)
}

/**
 * 根据openid查询用户信息
 */
export const findUserByUnionid = params => {
    return http.get('/sysuser/findUserByUnionid', params)
}

/**
 * 请求高德地图相关接口
 */
export const queryFromGaode = params => {
    return http.post('/userpath/queryFromGaode', params)
}

// 任务定制仓

/**
 * 发布一条新任务
 */
export const addTask = params => {
    return http.post('/task/addTask', params)
}

/**
 * 查询所有项目
 */
export const findRelationProject = params => {
    return http.get('/task/findRelationProject', params)
}

/**
 * 上传图片
 * @param { any } file 文件
 */
export const uploadTaskImage = params => {
    return http.upload('/task/uploadTaskImage', params)
}

/**
 * 上传附件
 */
export const uploadTaskFile = params => {
    return http.jsonPost('/task/uploadTaskFile', params)
}

/**
 * 查询任务数据
 */
export const findAllTask = params => {
    return http.get('/task/findAllTask', params)
}

/**
 * 查询任务详情
 */
export const findTaskById = params => {
    return http.get('/task/findTaskById', params)
}

/**
 * 编辑任务
 */
export const updateTask = params => {
    return http.post('/task/updateTask', params)
}

/**
 * 任务回复
 */
export const addTaskReply = params => {
    return http.post('/taskreply/addTaskReply', params)
}

/**
 * 完成任务
 */
export const finishTask = params => {
    return http.post('/task/finishTask', params)
}

// 问题收集站

/**
 * 发布问题
 */
export const saveProblem = params => {
    return http.post('/problem/saveProblem', params)
}

/**
 * 删除问题
 */
export const deleteAppProblem = params => {
    return http.get('/problem/deleteAppProblem', params)
}

/**
 * 上传图片/附件
 */
export const uploadImageReply = params => {
    return http.jsonPost('/problemreply/uploadImageReply', params)
}

/**
 * 查询问答列表
 */
export const findAppProblemReply = params => {
    return http.get('/problem/findAppProblemReply', params)
}

/**
 * 查询问题所有回答
 */
export const findPcRply = params => {
    return http.get('/problemreply/findPcRply', params)
}

/**
 * 查询回复详情
 */
export const findReplyByReplyId = params => {
    return http.get('/problemreply/findReplyByReplyId', params)
}

/**
 * 回复问题
 */
export const saveReply = params => {
    return http.jsonPost('/problemreply/saveReply', params)
}

/**
 * 删除回复
 */
export const deleteAppReply = params => {
    return http.get('/problemreply/deleteAppReply', params)
}


export const ssoUserLogin = () => {
    return Axios.get('http://10.100.200.40/adapter/login?password=123456&username=hhuyw&_iCLIENT=RIIL')
}


/**
 * 根据公司Id查询用户组信息
 * @param {*} companyId 公司ID
 */
export const queryDepartmentByCompId = companyId => http.get('/sysusergroup/findUserGroupByCompanyId', { companyId })


/**
 * 根据部门Id查询该部门下的部门和用户
 * 不传默认是查询顶级部门
 * @param {*} groupIds 部门ID
 */
export const queryChildDepAndUserById = (groupIds = undefined) => http.get('/sysuser/appPhonebook', { groupIds })
/**
 * 查询黄淮学院设备信息
 */
export const getList = (params) => {
    return http.get('/ruijie/getList', params)
}

/**
 * 填写里程碑验收信息
 */
export const addProjectMilepostCheck = params => {
    return http.post('/projectmilepost/addProjectMilepostCheck', params)
}


/**
 * pc端查询工单类型不分页
 */
export const findOrderType = params => {
    return http.get('/repairordertype/findAppOrderType', params)
}

/**
 * 手机端查询所有工单
 */
export const findRepairAllOrder = params => {
    return http.get('/repairorderapp/findRepairAllOrder', params)
}

/**
 * 统计所有工单各类型数量
 */
export const StatisticalQuantity = params => {
    return http.get('/repairorderapp/StatisticalQuantity', params)
}

/**
 * 统计维修工工单各种类型数量
 */
export const StatisticalRepairUserQuantity = params => {
    return http.get('/repairorderapp/StatisticalRepairUserQuantity', params)
}

/**
 * 统计用户工单各类型数量
 */
export const StatisticalUserQuantity = params => {
    return http.get('/repairorderapp/StatisticalUserQuantity', params)
}

