import React from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'
import Store from '../stores/store'
import { Provider } from 'mobx-react'
import Home from './../pages/home/index';
import cookie from 'react-cookies';
import Login from '../pages/login';
import Action from '../pages/action/index'
import Phone from '../pages/phone/index'
import VoiceRepair from './../pages/voice/index';
import WorkOrder from '../pages/workorder/index'
import AllWorkOrder from '../pages/allworkorder/index'
import Detail from '../pages/details/index'
import Project from './../pages/project/index';
import ClockIn from './../pages/clockin/index';
import Details from './../pages/project/details';
import projectAdd from './../pages/project/add';
import Business from './../pages/business/index';
import CallOn from './../pages/business/callOn';
import RecordInfo from './../pages/business/recordInfo';
import Journal from './../pages/business/journal';
import dailyAdd from './../pages/project/dailyAdd';
import dailyDetails from './../pages/project/dailyDetails';
import Accessory from './../pages/project/accessory';
import Milestone from './../pages/project/milestone';
import MilestoneAdd from './../pages/project/milestoneAdd';
import WorkPlan from './../pages/project/workPlan';
import WorkPlanAdd from './../pages/project/workPlanAdd';
import checkAdd from './../pages/project/checkAdd';
import checkDetails from './../pages/project/checkDetails';
import FileUpload from './../pages/project/fileUpload';
import Certification from '../pages/certification/index'
import Track from './../pages/track/index';
import Tracks from './../pages/track/track';
import TrackRecord from './../pages/track/trackRecord'
import Task from './../pages/task/index'
import TaskDetail from '../pages/task/taskDetail'
import TaskAdd from '../pages/task/taskAdd'
import Enclosure from '../pages/task/enclosure'
import Problem from '../pages/problem/index'
import Question from '../pages/problem/question'
import Current from '../pages/problem/current'
import Reply from '../pages/problem/reply'
import Password from '../pages/track/password';
import Test from '../pages/test';
import Tree from '../pages/task/tree';
import Clear from '../pages/clear';
import MailList from '../pages/mailist';
import Equipment from '../pages/equipment';

function RouteMap() {
    return (

        <Provider {...Store}>
            <div className="course">
                <Router>
                    {cookie.load('token') ? <Route exact path="/" component={Home} /> : <Route exact path="/" component={Login} />}
                    <Route exact path="/action/index" component={Action} />
                    <Route exact path="/test" component={Test} />
                    <Route exact path="/clear" component={Clear} />
                    <Route exact path="/phone/index" component={Phone} />
                    <Route exact path="/voice/index" component={VoiceRepair} />
                    <Route exact path="/workOrder/index" component={WorkOrder} />
                    <Route exact path="/allWorkOrder/index" component={AllWorkOrder} />
                    <Route exact path="/detail/index/:id" component={Detail} />
                    <Route exact path="/project/index" component={Project} />
                    <Route exact path="/clockin/index" component={ClockIn} />
                    <Route exact path="/project/details/:id" component={Details} />
                    <Route exact path="/project/projectadd/:id/:status" component={projectAdd} />
                    <Route exact path="/project/details" component={Details} />
                    <Route exact path="/business/index" component={Business} />
                    <Route exact path="/business/callOn/:id" component={CallOn} />
                    <Route exact path="/business/recordInfo/:id" component={RecordInfo} />
                    <Route exact path="/business/journal" component={Journal} />
                    <Route exact path="/project/dailyAdd/:projectId/:editId/:status" component={dailyAdd} />
                    <Route exact path="/project/dailyDetails/:id/:status" component={dailyDetails} />
                    <Route exact path="/project/accessory/:id/:status" component={Accessory} />
                    <Route exact path="/project/milestone/:id/:status" component={Milestone} />
                    <Route exact path="/project/milestoneAdd/:id/:pm_id/:status" component={MilestoneAdd} />
                    <Route exact path="/project/workPlan/:id/:status" component={WorkPlan} />
                    <Route exact path="/project/workPlanAdd/:id/:pp_id/:status" component={WorkPlanAdd} />
                    <Route exact path="/project/checkAdd/:id" component={checkAdd} />
                    <Route exact path="/project/checkDetails/:id/:status" component={checkDetails} />
                    <Route exact path="/project/fileUpload/:id" component={FileUpload} />
                    <Route exact path="/certification/index" component={Certification} />
                    <Route exact path="/track/index" component={Track} />
                    <Route exact path="/track/track" component={Tracks} />
                    <Route exact path="/track/trackRecord" component={TrackRecord} />
                    <Route exact path="/track/password" component={Password} />
                    <Route exact path="/task/index" component={Task} />
                    <Route exact path="/task/taskDetail/:id" component={TaskDetail} />
                    <Route exact path="/task/taskAdd/:id/:status" component={TaskAdd} />
                    <Route exact path="/task/enclosure/:id/:status" component={Enclosure} />
                    <Route exact path="/task/tree/:id" component={Tree} />
                    <Route exact path="/problem/index" component={Problem} />
                    <Route exact path="/problem/question/:id" component={Question} />
                    <Route exact path="/problem/current/:id" component={Current} />
                    <Route exact path="/problem/reply/:id" component={Reply} />
                    <Route exact path="/MailList/:id" component={MailList} />
                    <Route exact path="/equipment" component={Equipment} />
                </Router>
            </div >
        </Provider>

    )
}
export default RouteMap