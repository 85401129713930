import React, { Component } from 'react'
import { NavBar, Icon, List, InputItem, TextareaItem, WhiteSpace, Button, WingBlank, Toast } from 'antd-mobile'
import { Gallery, GalleryDelete, Uploader, Cell, CellBody } from 'react-weui';
import { uploadImg, saveBusinessRecord, updateBusinessRecord, findBusinessRecordById } from '../../service/api'
import IP from '../../config/config'
// import cookie from 'react-cookies';

class callOn extends Component {
    state = {
        urls: [],
        gallery: false,
        demoFiles: [],
        clientName: '', // 客户姓名
        phone: '', // 电话
        companyName: '', // 公司名称
        visitContent: '', // 拜访内容
        callOnId: null, // 拜访记录id
        detail: {}, // 详情内容
    }

    componentDidMount = async () => {
        document.getElementsByClassName("weui-uploader__input")[0].removeAttribute("capture")
        const record = this.props.match.params;
        if (Number(record.id)) {
            const res = await findBusinessRecordById({ recordId: record.id })
            if (res.code === 20000) {
                const demoFiles = res.data.visit_start_picture ? res.data.visit_start_picture.split(',') : []
                this.setState({
                    clientName: res.data.customer_name,
                    phone: res.data.customer_phone,
                    companyName: res.data.visit_company_name,
                    visitContent: res.data.visit_description,
                    demoFiles: demoFiles.map((x, i) => ({ url: IP.host + x, id: i + 1 })),
                    urls: demoFiles,
                    detail: res.data,
                })
            }
        }
        this.setState({
            callOnId: record.id
        })
    }

    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    // 开始拜访
    handleSubmit = async () => {
        const { urls, clientName, phone, companyName, visitContent, callOnId, detail } = this.state;
        console.log(urls, clientName, phone, companyName, visitContent)
        if (!(urls && urls.length && clientName && phone && companyName && visitContent)) return Toast.info('请完善拜访信息', 2, null, false)
        const req = {
            business_record_id: Number(callOnId) ? callOnId : null,
            customer_name: clientName,
            customer_phone: phone,
            visit_company_name: companyName,
            visit_description: visitContent,
            visit_start_picture: urls.join(','),
            visit_state: Number(callOnId) ? detail.visit_state : null,
            user_id: Number(callOnId) ? detail.user_id : null,
        }
        const method = Number(callOnId) ? updateBusinessRecord : saveBusinessRecord;
        const res = await method(req);
        if (res.code === 20000) {
            Toast.info(res.message, 2, null, false);
            setTimeout(() => {
                document.getElementById('toAndroid').click();
                // window.location.href = `mychat://mychat/map/${cookie.load('user').unionid || ''}`
                this.props.history.goBack()
            }, 2000)
        }
    }

    render() {

        const { clientName, phone, companyName, visitContent, callOnId } = this.state;

        return (
            <div>
                {this.renderGallery()}
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    拜访客户
                </NavBar>
                <List>
                    <a style={{ display: 'none' }} id="toAndroid" href='mychat://mychat/map/'>跳转</a>
                    <InputItem placeholder="请输入客户姓名" style={{ textAlign: 'right', paddingRight: 8 }} value={clientName} onChange={(e) => {
                        this.setState({
                            clientName: e
                        })
                    }}>
                        客户姓名
                    </InputItem>
                    <InputItem placeholder="请输入联系电话" value={phone} style={{ textAlign: 'right', paddingRight: 8 }} type="phone" onChange={(e) => {
                        this.setState({
                            phone: e
                        })
                    }}>
                        联系电话
                    </InputItem>
                    <InputItem placeholder="请输入公司名称" value={companyName} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        this.setState({
                            companyName: e
                        })
                    }}>
                        公司名称
                    </InputItem>
                </List>
                <List renderHeader={() => '拜访内容'}>
                    <TextareaItem
                        placeholder="请输入拜访内容"
                        value={visitContent}
                        onChange={(e) => {
                            this.setState({
                                visitContent: e
                            })
                        }}
                        rows={5}
                        count={200}
                    />
                </List>
                <Cell>
                    <CellBody>
                        <Uploader
                            title="图片上传"
                            maxCount={4}
                            files={this.state.demoFiles}
                            onError={msg => alert(msg)}
                            onChange={(file) => {
                                console.log(file)
                                let newFiles = [...this.state.demoFiles, { url: file.data }];
                                let files = file.nativeFile
                                console.log(newFiles)
                                this.setState({
                                    demoFiles: newFiles
                                });
                                Toast.loading('图片上传中', 0)
                                let formData = new FormData();
                                formData.append("multipartFile", files);
                                uploadImg(formData).then(res => {
                                    this.setState({
                                        urls: this.state.urls.concat(res.data)
                                    }, () => {
                                        Toast.hide()
                                    })
                                })
                            }}
                            onFileClick={
                                (e, file, i) => {
                                    this.setState({
                                        gallery: {
                                            url: file.url,
                                            id: i
                                        }
                                    })
                                }
                            }
                            lang={{
                                maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                            }}
                        />
                    </CellBody>
                </Cell>
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <WingBlank size="lg">
                    <Button type="primary" onClick={this.handleSubmit}>{Number(callOnId) ? '提交' : '开始拜访'}</Button>
                </WingBlank>
                <WhiteSpace size="lg" />
            </div>
        )
    }
}

export default callOn
