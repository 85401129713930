import React ,{Component} from 'react'
import { NavBar,Icon,Button, Toast } from 'antd-mobile'
import { req_findProjectMilepostById } from './../../service/api'

class milestone extends Component{
    state={
        data:[],
    }

    componentDidMount=()=>{
        this.findData();
    }

    findData=()=>{
        req_findProjectMilepostById({projectId:this.props.match.params.id}).then(res=>{
            if(res.code===20000){
                this.setState({
                    data:res.data
                })
            }else{
                Toast.fail(res.message,1)
            }
        })
    }

    render=()=>{
        const {data}=this.state

        return (
            <div>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.push({pathname:`/project/details/${this.props.match.params.id}`})}
                >
                    里程碑
                </NavBar>
                <div style={{height:"10px",background:"#F5F5F5"}} />
                {
                    data.map((v,k)=>{
                        return (
                        <div key={k} style={{height:"60px",borderBottom:"1px solid #F5F5F5",fontSize:"16px",lineHeight:"60px",margin:"0px 20px",display:"flex",justifyContent:"space-between"}} onClick={()=>{
                            this.props.history.push({pathname:`/project/milestoneAdd/${this.props.match.params.id}/${v.pm_id}/${this.props.match.params.status}`});
                        }}>
                            <div>
                            第{k+1}个节点
                            </div>
                            <div style={{fontSize:"22px",color:"#C5C9CC"}}>
                                ＞
                            </div>
                        </div>
                        )
                    })
                }

                {
                    (this.props.match.params.status !== '2') && <div style={{width:"100%",position:"fixed",bottom:"10px",zIndex:"999"}}>
                        <Button  type="primary" className="button-footer" onClick={()=>{
                            this.props.history.push({pathname:`/project/milestoneAdd/${this.props.match.params.id}/0/${this.props.match.params.status}`});
                        }}>新增节点</Button>
                    </div>
                }
            </div>
        )
    }

}
export default milestone