import React, { Component } from 'react';
import { List, NavBar, InputItem, WhiteSpace, WingBlank, Button, Icon, Toast } from 'antd-mobile';
import cookie from 'react-cookies';
import { authentication } from '../../service/api';

class Password extends Component {
    state = {
        userName: cookie.load('user').user_name || '', // 用户名
        phone: cookie.load('user').user_phone || '', // 手机号
        password: '', // 密码

    }

    // 点击提交按钮的时候
    handleSubmit = async () => {
        const { userName, phone, password } = this.state;
        if (!(userName && phone && password)) return Toast.info('请完善认证信息', 2, null, false)
        const result = await authentication({ user_id: cookie.load('user').user_id, user_name: userName, user_phone: phone.replace(/\s/g, ''), mobile_password: password })
        console.log(result)
        if (result.code === 20000) {
            Toast.success('执行成功')
            result.data.role_id = cookie.load('user').role_id;
            cookie.remove('user', { path: '/' })
            cookie.save('user', result.data, { path: '/' })
            setTimeout(() => {
                this.props.history.goBack(1)
            }, 1000);
        }
        else {
            Toast.fail(result.message)
        }

    }

    render() {
        const { userName, phone, password } = this.state;
        return (
            <div>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    实名认证
                </NavBar>
                <List>
                    <InputItem placeholder="请输入微信昵称" disabled style={{ textAlign: 'right', paddingRight: 8 }} value={cookie.load('user').user_nick}>
                        微信昵称
                    </InputItem>
                    <InputItem placeholder="请输入姓名" disabled={!!cookie.load('user').user_name} value={cookie.load('user').user_name || userName} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        this.setState({
                            userName: cookie.load('user').user_name || e
                        })
                    }}>
                        姓名
                    </InputItem>
                    <InputItem placeholder="请输入联系电话" disabled={!!cookie.load('user').user_phone} value={cookie.load('user').user_phone || phone} style={{ textAlign: 'right', paddingRight: 8 }} type="phone" onChange={(e) => {
                        this.setState({
                            phone: cookie.load('user').user_phone || e
                        })
                    }}>
                        联系电话
                    </InputItem>
                    <InputItem placeholder="请输入密码" maxLength={18} type='password' value={password} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        this.setState({
                            password: e
                        })
                    }}>
                        密码
                    </InputItem>
                    <InputItem placeholder="请输入公司" disabled value={cookie.load('user').user_company_name} style={{ textAlign: 'right', paddingRight: 8 }}>
                        公司
                    </InputItem>
                </List>
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <WingBlank size="lg">
                    <Button type="primary" onClick={this.handleSubmit}>认证</Button>
                </WingBlank>
                <WhiteSpace size="lg" />
            </div>
        );
    }
}

export default Password;