import React , {Component} from 'react'
import { NavBar,Icon, Toast} from 'antd-mobile'
import {Link} from 'react-router-dom'
import { req_findProjectCheckList } from './../../service/api'
import moment from 'moment'

class dailyDetails extends Component{
    state={
        reportData:[],
    }

    componentDidMount=()=>{
        this.report();
    }
    
    report=()=>{
        req_findProjectCheckList().then(res=>{
            if(res.code===20000){
                this.setState({
                    reportData:res.data
                })
            }else{
                Toast.fail(res.message,1);
            }
        })
    }

    render=()=>{
        const { reportData }=this.state
        return (<div>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.push({pathname:`/project/details/${this.props.match.params.id}`})}
                >
                    我的验收
                </NavBar>
                <div style={{background:"#F3F4F6",display:"flex",justifyContent: "space-between",padding:"5px 10px"}}>
                <div style={{color:"#68789F",fontSize:"14px"}}>全部验收({reportData.length})</div>
                    {/* <div style={{color:"#F78C76",textDecoration:"underline",fontSize:"14px"}}> 2次未提交</div> */}
                </div>
                {
                    reportData.map((v,k)=>{
                        return (
                        <div key={k} className="accept" style={{borderBottom: "1px solid #F0EFF5"}}>
                            <div className="accept-header" style={{display: "flex"}}>
                                <div className="accept-img">
                                    <img style={{borderRadius:"50px"}} src={v.pc_avatar} width="50px" alt=""/>
                                </div>
                                <div>
                                    <div className="accept-name" style={{paddingLeft:"20px",fontSize:"16px",fontWeight:"900"}}>
                                        {v.pc_username}
                                    </div>
                                    <div className="accept-time" style={{paddingLeft:"20px",color: "#929292"}}>
                                        创建于{moment(v.pc_time).format('YYYY-MM-DD HH:MM')}
                                    </div>
                                </div>
                            </div>
                            <div className="accept-main" style={{fontSize:"14px",paddingTop:"10px"}}>
                                验收内容：{v.pc_content}
                            </div>
                        </div>
                        )
                    })
                }
                {
                    this.props.match.params.status !== '2' && <Link to={`/project/checkAdd/${this.props.match.params.id}/${this.props.match.params.status}`}>
                        <div style={{width:"50px",height:"50px",borderRadius:"50px",fontSize:"45px",textAlign:"center",lineHeight:"40px",color:"#ffffff",backgroundImage:"linear-gradient(#189bff, #0081ff)",boxShadow: "5px 5px 20px #b0cfee",position:"fixed",bottom:"20px",right:"20px",zIndex:"9999",fontWeight:"100"}}>
                            +
                        </div>
                    </Link>
                }
        </div>)
    }

}
export default dailyDetails