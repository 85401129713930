import React, { Component } from 'react';
// import { NavBar, Icon, Modal, List, InputItem, TextareaItem, Toast, ImagePicker, Button } from 'antd-mobile'
import { NavBar, Icon, Modal, List, InputItem, TextareaItem, Toast } from 'antd-mobile'
import './index.less'
import { Gallery, GalleryDelete, Uploader, Cell, CellBody } from 'react-weui';
import { uploadImageReply, saveProblem, findAppProblemReply, deleteAppProblem } from '../../service/api'
import moment from 'moment';
import IP from '../../config/config'

class index extends Component {
    state = {
        mine: false,
        visible: false,
        problemData: [], // 问答列表
        porblemTitle: '', // 问题
        problemDesc: '', // 答案
        urls: [],
        gallery: false,
        demoFiles: [],
        questionFiles: [], // 附件
    }

    componentDidMount = () => {
        this.fetch()
    }

    // 查询问答列表
    fetch = async flag => {
        const res = await findAppProblemReply({ flag })
        if (res.code === 20000) {
            this.setState({
                problemData: res.data
            })
        }
    }

    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    // 选择查看状态
    handleMine = (index) => {
        if (index === 1) {
            this.setState({
                mine: false,
            })
        }
        if (index === 2) {
            this.setState({
                mine: true,
            })
        }
        this.fetch(index)
    }

    // 上传附件
    onChangeFile = (e) => {
        const { files } = e.target;
        Toast.loading('上传中...')
        if (files && files.length) {
            let formData = new FormData();
            formData.append("flag", 2)
            formData.append("file", files[0]);
            uploadImageReply(formData).then(res => {
                this.setState({
                    questionFiles: this.state.questionFiles.concat(res.data)
                }, () => {
                    Toast.hide()
                })
            })
        }
    };

    // 弹框
    handleModal = () => {
        this.setState({
            visible: true,
        },()=>{
            document.getElementsByClassName("weui-uploader__input")[0].removeAttribute("capture")
        })
    }

    // 发布问题
    handleIssue = async () => {
        const { porblemTitle, problemDesc, urls, questionFiles } = this.state;
        if (!porblemTitle) return Toast.info('请输入问题');
        const problem = {
            problem_title: porblemTitle,
        }
        const reply = {
            reply_content: problemDesc,
            reply_images: urls && urls.length ? urls.join(',') : '',
            files: questionFiles && questionFiles.length ? JSON.stringify(questionFiles) : ''
        }
        const res = await saveProblem({ problem: JSON.stringify(problem), reply: JSON.stringify(reply) })
        if (res.code === 20000) {
            this.setState({ visible: false, urls: [], questionFiles: [], gallery: false, demoFiles: [], mine: false })
            Toast.info(res.message)
            setTimeout(this.fetch(), 1000)
        }
    }

    // 查询问题下的所有答案
    handleAllQuestion = id => {
        this.props.history.push({ pathname: `/problem/question/${id}` })
    }

    // 查询问题下的当前答案
    handleCurrent = id => {
        this.props.history.push({ pathname: `/problem/current/${id}` })
    }

    // 删除自己的问题
    handleProblemDel = async problemId => {
        const res = await deleteAppProblem({ problemId })
        if (res.code === 20000) {
            Toast.info('删除成功')
            setTimeout(() => { this.fetch(2) }, 1000)
            this.setState({
                mine: true
            })
        }
    }

    render() {
        const { mine, visible, questionFiles, problemData } = this.state;
        return (
            <div className='problem_home'>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    问题收集站
                </NavBar>
                <div className='problem_main'>
                    {
                        (problemData || []).map(item => {
                            return <div className='problem_info' key={item.problem_id}>
                                {
                                    !mine ? <div className='problem_header'>
                                        <div className='problem_header_img'>
                                            <img src={item.problem_user_avatar} alt='' />
                                        </div>
                                        <div className='problem_header_name'>
                                            <div>{item.problem_user_name}</div>
                                            <div>{moment(item.problem_create_time).format('YYYY-MM-DD')}</div>
                                        </div>
                                    </div> : ''
                                }
                                <div className='problem_title' onClick={() => this.handleAllQuestion(item.problem_id)}>
                                    <span><span>问</span>{item.problem_title}</span>
                                </div>
                                {
                                    item.reply_images ? <div className='problem_question' onClick={() => this.handleCurrent(item.reply_id)}>
                                        {
                                            item.reply_content ? <div className='problem_question_content'>
                                                {item.reply_user_name}：{item.reply_content && item.reply_content.length > 50 ? item.reply_content.substr(0, 50) + '...' : item.reply_content}
                                            </div> : ''
                                        }
                                        <div className='problem_question_img'>
                                            <img src={IP.host + item.reply_images.split(',')[0]} alt='' />
                                        </div>
                                    </div> : <div className='problem_question' onClick={() => this.handleCurrent(item.reply_id)}>
                                            {
                                                item.reply_content ? <div className='problem_question_desc'>
                                                    {item.reply_user_name}：{item.reply_content && item.reply_content.length > 50 ? item.reply_content.substr(0, 50) + '...' : item.reply_content}
                                                </div> : ''
                                            }
                                        </div>
                                }
                                {
                                    mine ? <div className='ploblem_info_footer'>
                                        <div>{moment(item.problem_create_time).format('YYYY-MM-DD')}</div>
                                        {/* <div className='problem_del'><img src={require('../../images/28.png')} alt='' /></div> */}
                                        <div className='problem_del' onClick={() => this.handleProblemDel(item.problem_id)}>
                                            <div className="problem_del_img">
                                                <img src={require('./../../images/del.jpg')} alt="" />
                                            </div>
                                            <div className="problem_del_text">
                                                删除
                                            </div>
                                        </div>
                                    </div> : ''
                                }
                            </div>
                        })
                    }
                </div>
                <div className='footer'>
                    <div className='footer_info'>
                        <div className='info_img' onClick={() => this.handleMine(1)}>
                            <img src={mine ? require('../../images/34.png') : require('../../images/33.png')} alt='' />
                            <span>首页</span>
                        </div>
                        <div className='info_img' onClick={() => this.handleMine(2)}>
                            <img src={mine ? require('../../images/35.png') : require('../../images/36.png')} alt='' />
                            <span>我的</span>
                        </div>
                    </div>
                    <div className='footer_add' onClick={this.handleModal}>
                        <img src={require('../../images/10.png')} alt='' />
                    </div>
                </div>
                <Modal
                    visible={visible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                >
                    {this.renderGallery()}
                    <NavBar
                        mode="mode"
                        icon={<Icon type="left" />}
                        onLeftClick={() => this.setState({ visible: false })}
                        rightContent={<div onClick={() => this.handleIssue()}>发布问题</div>}
                    >
                        发布问题
                    </NavBar>
                    <List>
                        <InputItem placeholder="请输入问题" style={{ textAlign: 'left' }} onChange={(e) => {
                            this.setState({
                                porblemTitle: e
                            })
                        }}></InputItem>
                    </List>
                    <List>
                        <TextareaItem
                            placeholder="请输入答案（发布问题答案可不填）"
                            onChange={(e) => {
                                this.setState({
                                    problemDesc: e
                                })
                            }}
                            rows={5}
                            count={200}
                        />
                    </List>
                    <Cell className='problem_modal_img'>
                        <CellBody>
                            <Uploader
                                title='上传图片（发布问题答案可不填）'
                                maxCount={4}
                                files={this.state.demoFiles}
                                onError={msg => alert(msg)}
                                onChange={(file) => {
                                    let newFiles = [...this.state.demoFiles, { url: file.data }];
                                    let files = file.nativeFile
                                    this.setState({
                                        demoFiles: newFiles
                                    });
                                    Toast.loading('图片上传中', 0)
                                    let formData = new FormData();
                                    formData.append("file", files);
                                    uploadImageReply(formData).then(res => {
                                        this.setState({
                                            urls: this.state.urls.concat(res.data)
                                        }, () => {
                                            Toast.hide()
                                        })
                                    })
                                }}
                                onFileClick={
                                    (e, file, i) => {
                                        this.setState({
                                            gallery: {
                                                url: file.url,
                                                id: i
                                            }
                                        })
                                    }
                                }
                                lang={{
                                    maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                                }}
                            />
                        </CellBody>
                    </Cell>
                    <div className='taskAdd_enclosure'>
                        <div style={{ color: '#000', textAlign: 'left' }}>上传附件（发布问题答案可不填）</div>
                        <div className='taskAdd_enclosure_file'>
                            {/* <ImagePicker
                                onChange={this.onChangeFile}
                                length={1}
                            /> */}
                            <div className='taskAdd_file'>
                                {/* <img src={require('../../images/10.png')} alt='' /> */}

                                <div onClick={() => document.getElementById('upload').click()} style={{ background: '#4aa14a', color: '#fff', padding: '3px 5px', borderRadius: '5px' }}>上传附件</div>
                                <input type='file' id='upload' onChange={e => this.onChangeFile(e)} hidden />
                            </div>
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            {
                                (questionFiles || []).map(x => {
                                    return <div key={x.url}>{x.fileName}</div>
                                })
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default index;