import React, { Component } from 'react'
// import { NavBar, Icon, Toast, ImagePicker, List, InputItem, DatePicker, Switch, TextareaItem, Modal, Button } from 'antd-mobile'
import { NavBar, Icon, Toast, List, InputItem, DatePicker, Switch, TextareaItem, Modal, Button } from 'antd-mobile'
import { uploadImage, findProjectUser, saveProject, findAppProjectInfoById, updateProject } from './../../service/api'
import cookie from 'react-cookies'
import moment from 'moment'
import './add.less'
import IP from '../../config/config'

export default class Project extends Component {
    state = {
        files: [],//文件列表
        imgArr: '', // 文件路径
        projectName: '', // 项目名称
        startTime: '', // 开始时间
        endTime: '', // 结束时间
        checked: false,
        projectDesc: '', // 项目描述
        acceptor: '', // 验收人
        acceptorIds: [], // 验收人id
        member: '', // 项目成员
        memberIds: [], // 项目成员id
        principal: '', // 负责人
        principalIds: [], // 负责人id
        personnelVisible: false,
        index: null,
        visible: false,
        allStaff: [], // 所有成员
        allMember: [], // 剩余所有成员
        presentMember: [], // 当前成员
        projectId: null, // 项目id
    }

    componentDidMount = async () => {
        console.log(this.props.match.params)
        const record = this.props.match.params;
        if (Number(record.id)) {
            const res = await findAppProjectInfoById({ projectId: record.id })
            if (res.code === 20000) {
                const users = res.data.projectUserEntities;
                this.setState({
                    imgArr: res.data.project_url,
                    files: [{ url: IP.host + res.data.project_url, id: 1 }],
                    projectName: res.data.project_name,
                    // startTime: new Date(res.data.project_start_time),
                    // endTime: new Date(res.data.project_end_time),
                    // startTime: new Date(moment(res.data.project_start_time).format('YYYY/MM/DD')),
                    // endTime: new Date(moment(res.data.project_end_time).format('YYYY/MM/DD')),
                    startTime: new Date(res.data.project_start_time.replace(/-/g, "/")),
                    endTime: new Date(res.data.project_end_time.replace(/-/g, "/")),
                    checked: res.data.project_outsourcing ? true : false,
                    projectDesc: res.data.project_describe,
                    principalIds: users.filter(x => x.pu_state === 1).map(p => String(p.pu_userid)),
                    principal: users.filter(x => x.pu_state === 1).map(p => p.pu_username),
                    memberIds: users.filter(x => x.pu_state === 2).map(p => String(p.pu_userid)),
                    member: users.filter(x => x.pu_state === 2).map(p => p.pu_username),
                    acceptorIds: users.filter(x => x.pu_state === 3).map(p => String(p.pu_userid)),
                    acceptor: users.filter(x => x.pu_state === 3).map(p => p.pu_username),
                })
            }
        }
        this.setState({
            projectId: record.id
        })
        this.fetchMember();
    }

    // 上传文件
    uploadImage = (files, type, index) => {
        console.log(files)
        this.setState({
            files,
        });
        if (files.length === 0) return false;
        let param = new FormData(); //创建form对象
        param.append('multipartFile', files[0].file);//通过append向form对象添加数据
        const xhr = new XMLHttpRequest()
        xhr.open('POST', uploadImage(), true)
        xhr.setRequestHeader("x-auth-token", cookie.load('token'))
        xhr.send(param)
        xhr.onload = (res) => {
            const data = JSON.parse(res.currentTarget.responseText).data
            this.setState({
                imgArr: data
            })
        }
        xhr.upload.onprogress = (e) => {
            console.log(e)
        }
        xhr.onerror = (err) => {
            Toast.fail('上传失败');
        }
    }

    // 编辑成员弹框
    handleEditMomberOk = () => {
        this.setState({
            personnelVisible: false,
        })
    }

    // 添加成员信息弹框
    handleMember = () => {
        const { principalIds, memberIds, acceptorIds } = this.state;
        const array = principalIds.concat((memberIds)).concat((acceptorIds))
        // array.join(',')
        this.fetchMember()
        this.setState({
            visible: true
        })
    }

    // 查询成员信息
    fetchMember = async userIds => {
        const res = await findProjectUser({ userIds })
        if (res.code === 20000) {
            if (!userIds) {
                const staff = [];
                (res.data || []).map(x => (x.sysUserEntities || []).map(t => staff.push(t)))
                this.setState({
                    allStaff: staff
                })
            }
            this.setState({
                allMember: res.data.reverse()
            })
        }
    }

    // // 删除
    // removeByValue = (arr, val) => {
    //     for(var i = 0; i < arr.length; i++) {
    //      if(arr[i] == val) {
    //       arr.splice(i, 1);
    //       break;
    //      }
    //     }
    //    }

    // 删除成员
    handleDelMember = id => {
        const { principalIds, memberIds, acceptorIds, index, presentMember } = this.state;
        if (index === 1) {
            this.setState({
                principalIds: principalIds.filter(x => x !== String(id)),
                presentMember: presentMember.filter(x => String(x.user_id) !== String(id)),
                principal: presentMember.filter(x => String(x.user_id) !== String(id)).map(t => t.user_name).join(',')
            })
        }
        if (index === 2) {
            this.setState({
                memberIds: memberIds.filter(x => x !== String(id)),
                presentMember: presentMember.filter(x => String(x.user_id) !== String(id)),
                member: presentMember.filter(x => String(x.user_id) !== String(id)).map(t => t.user_name).join(',')
            })
        }
        if (index === 3) {
            this.setState({
                acceptorIds: acceptorIds.filter(x => x !== String(id)),
                presentMember: presentMember.filter(x => String(x.user_id) !== String(id)),
                acceptor: presentMember.filter(x => String(x.user_id) !== String(id)).map(t => t.user_name).join(',')
            })
        }
    }

    // 添加成员
    handleAddMemberOk = () => {
        const { index, allStaff, principalIds, memberIds, acceptorIds } = this.state;
        var obj = document.getElementsByName('staff');
        var check_val = [];
        for (var k in obj) {
            if (obj[k].checked) {
                check_val.push(obj[k].value)
            }
        }
        if (index === 1) {
            const check1 = check_val.concat(principalIds)
            const array1 = [];
            check1.map(x => array1.push(allStaff.find(t => t.user_id === Number(x))))
            this.setState({
                principalIds: check1,
                principal: array1.map(x => x.user_name).join(','),
                presentMember: array1,
            })
        }
        if (index === 2) {
            const check2 = check_val.concat(memberIds)
            const array2 = [];
            check2.map(x => array2.push(allStaff.find(t => t.user_id === Number(x))))
            this.setState({
                memberIds: check2,
                member: array2.map(x => x.user_name).join(','),
                presentMember: array2,
            })
        }
        if (index === 3) {
            const check3 = check_val.concat(acceptorIds)
            const array3 = [];
            check3.map(x => array3.push(allStaff.find(t => t.user_id === Number(x))))
            this.setState({
                acceptorIds: check3,
                acceptor: array3.map(x => x.user_name).join(','),
                presentMember: array3,
            })
        }
        this.setState({
            visible: false
        })
    }

    // 保存
    handleSubmit = async () => {
        const { imgArr, projectName, startTime, endTime, checked, projectDesc, principalIds, memberIds, acceptorIds, projectId } = this.state;
        if (!(projectName && startTime && endTime && projectDesc && principalIds.length && memberIds.length && acceptorIds.length)) return Toast.info('请完善项目信息', 2, null, false)
        console.log(projectId)
        console.log(moment(startTime).format('YYYY-MM-DD'))
        console.log(moment(endTime).format('YYYY-MM-DD'))
        console.log(imgArr)
        const req = {
            project_id: Number(projectId) ? projectId : null,
            project_name: projectName,
            project_outsourcing: checked ? 1 : 2,
            project_describe: projectDesc,
            project_start_time: moment(startTime).format('YYYY-MM-DD'),
            project_end_time: moment(endTime).format('YYYY-MM-DD'),
            // project_url: imgArr,
            fuzeUserIds: principalIds.join(','),
            canyuUserIds: memberIds.join(','),
            checkUserIds: acceptorIds.join(','),
        }
        const method = Number(projectId) ? updateProject : saveProject;
        const res = await method(req);
        if (res.code === 20000) {
            Toast.info(res.message, 2, null, false);
            setTimeout(() => {
                this.props.history.goBack()
            }, 2000)
        }
    }

    handleClose = id => {
        const { allMember } = this.state;
        allMember.map(x => {
            if (x.checked && (x.user_group_id === id)) {
                x.checked = false
            } else {
                x.checked = x.user_group_id === id
            }
            return x
        })
        this.setState({
            allMember
        })
    }

    render() {
        const { projectName, startTime, endTime, checked, projectDesc, acceptor, member, principal, personnelVisible, index, visible, allMember, presentMember, principalIds, memberIds, acceptorIds, allStaff } = this.state;
        // const { files, projectName, startTime, endTime, checked, projectDesc, acceptor, member, principal, personnelVisible, index, visible, allMember, presentMember, principalIds, memberIds, acceptorIds, allStaff } = this.state;
        return (
            <div>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.goBack()}
                // rightContent={<Icon type="ellipsis" />}
                >
                    项目管理
                </NavBar>
                {/* <div className='porject_image'>
                    <div style={{ marginLeft: 15, fontSize: 14 }}>封面图片</div>
                    <ImagePicker
                        length="1"
                        files={files}
                        onChange={(files, type, index) => this.uploadImage(files, type, index)}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        multiple={true}
                        selectable={files.length < 1}
                        disableDelete={this.props.match.params.status === '2'}
                    >
                    </ImagePicker>
                </div> */}
                <List>
                    <InputItem placeholder="请输入项目名称" disabled={this.props.match.params.status === '2'} value={projectName} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        this.setState({
                            projectName: e
                        })
                    }}>
                        项目名称
                    </InputItem>
                    <InputItem placeholder="请选择项目负责人" disabled={this.props.match.params.status === '2'} value={principal} style={{ textAlign: 'right', paddingRight: 8 }} onFocus={(e) => {
                        const array = [];
                        principalIds.map(x => array.push(allStaff.find(t => t.user_id === Number(x))))
                        this.setState({
                            personnelVisible: true,
                            index: 1,
                            presentMember: array
                        })
                    }}>
                        项目负责人
                    </InputItem>
                    <InputItem placeholder="请选择项目成员" disabled={this.props.match.params.status === '2'} value={member} style={{ textAlign: 'right', paddingRight: 8 }} onFocus={(e) => {
                        const array = [];
                        memberIds.map(x => array.push(allStaff.find(t => t.user_id === Number(x))))
                        this.setState({
                            personnelVisible: true,
                            index: 2,
                            presentMember: array
                        })
                    }}>
                        项目成员
                    </InputItem>
                    <InputItem placeholder="请选择验收人" disabled={this.props.match.params.status === '2'} value={acceptor} style={{ textAlign: 'right', paddingRight: 8 }} onFocus={(e) => {
                        const array = [];
                        acceptorIds.map(x => array.push(allStaff.find(t => t.user_id === Number(x))))
                        this.setState({
                            personnelVisible: true,
                            index: 3,
                            presentMember: array
                        })
                    }}>
                        验收人
                    </InputItem>
                    <DatePicker
                        mode="date"
                        title="开始时间"
                        extra="开始时间"
                        disabled={this.props.match.params.status === '2'}
                        value={startTime}
                        onChange={date => this.setState({ startTime: date })}
                    >
                        <List.Item arrow="horizontal">计划开始时间</List.Item>
                    </DatePicker>
                    <DatePicker
                        mode="date"
                        title="结束时间"
                        extra="结束时间"
                        minDate={startTime}
                        disabled={this.props.match.params.status === '2'}
                        value={endTime}
                        onChange={date => this.setState({ endTime: date })}
                    >
                        <List.Item arrow="horizontal">计划结束时间</List.Item>
                    </DatePicker>
                    <List.Item
                        extra={<Switch
                            checked={checked}
                            disabled={this.props.match.params.status === '2'}
                            onChange={() => {
                                this.setState({
                                    checked: !this.state.checked,
                                });
                            }}
                        />}
                    >是否外包</List.Item>
                </List>
                <List renderHeader={() => '项目描述'}>
                    <TextareaItem
                        placeholder="请输入项目描述"
                        value={projectDesc}
                        disabled={this.props.match.params.status === '2'}
                        onChange={(e) => {
                            this.setState({
                                projectDesc: e
                            })
                        }}
                        rows={5}
                        count={200}
                    />
                </List>
                {
                    this.props.match.params.status !== '2' && <div style={{ padding: '20px 15px' }} onClick={this.handleSubmit}>
                        <Button type='primary'>保存</Button>
                    </div>
                }
                <Modal
                    visible={personnelVisible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            personnelVisible: false
                        })
                    }}
                >
                    <NavBar
                        mode='mode'
                        icon={<Icon type='left' />}
                        onLeftClick={() => this.setState({ personnelVisible: false })}
                        rightContent={<div onClick={this.handleEditMomberOk}>确定</div>}
                    >
                        人员结构管理
                    </NavBar>
                    <div className='personnel_people'>
                        {index === 1 && <div className='personnel_title'>项目负责人</div>}
                        {index === 2 && <div className='personnel_title'>项目成员</div>}
                        {index === 3 && <div className='personnel_title'>验收人</div>}
                        {
                            (presentMember || []).map(item => {
                                return <div className='personnel_info' key={item.user_id}>
                                    <div className='member_info'>
                                        <div><img src={item.user_avatar} alt='' /></div>
                                        <span >{item.user_name}</span>
                                    </div>
                                    <div className='personnel_image' onClick={() => this.handleDelMember(item.user_id)}>
                                        <img src={require('../../images/28.png')} alt='' />
                                    </div>
                                </div>
                            })
                        }
                        <div className='personnel_addImg'>
                            <div className='personnel_img' onClick={this.handleMember}>
                                <img src={require('../../images/27.png')} alt='' />
                            </div>
                            <span>添加成员</span>
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={visible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                >
                    <NavBar
                        mode='mode'
                        icon={<Icon type='left' />}
                        onLeftClick={() => this.setState({ visible: false })}
                        rightContent={<div onClick={this.handleAddMemberOk}>确定</div>}
                    >
                        选择项目参与人
                    </NavBar>
                    <div>
                        {
                            (allMember || []).map(item => {
                                return <div key={item.user_group_id} className='user_main'>
                                    <div className='user_group' onClick={()=>this.handleClose(item.user_group_id)}>
                                        {
                                            item.checked ? <img style={{ marginRight: 5 }} src={require('../../images/open.jpg')} alt='' /> :
                                            <img style={{ marginRight: 5 }} src={require('../../images/close.jpg')} alt='' />
                                        }
                                        <span>{item.group_name}</span>
                                    </div>
                                    <div className={`user_info ${item.checked ? '' : 'user_active'}`}>
                                        {
                                            (item.sysUserEntities || []).map(i => {
                                                return <label
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '5px 30px'
                                                    }}
                                                    key={i.user_id}
                                                >
                                                    <input
                                                        name="staff"
                                                        type="checkbox"
                                                        value={i.user_id}
                                                    />
                                                    <div className='member_info'>
                                                        <div><img src={i.user_avatar} alt='' /></div>
                                                        <span >{i.user_name}</span>
                                                    </div>
                                                </label>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                        {/* {
                            (allMember || []).map(item => {
                                return <label
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px 15px'
                                    }}
                                    key={item.user_id}
                                >
                                    <input
                                        name="staff"
                                        type="checkbox"
                                        value={item.user_id}
                                    />
                                    <div className='member_info'>
                                        <div><img src={item.user_avatar} alt='' /></div>
                                        <span >{item.user_name}</span>
                                    </div>
                                </label>
                            })
                        } */}
                    </div>
                </Modal>
            </div >
        )
    }
}
