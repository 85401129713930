import React, { Component } from 'react'
import { NavBar, Icon, Modal, Grid, Button, List, DatePicker } from 'antd-mobile'
import './index.less'
import { findUserBusinessRecords, findBusinessLogsByUserId } from '../../service/api'
import moment from 'moment'

class index extends Component {
    state = {
        pitchOn: false,
        visible: false,
        iconData: [],
        recordData: [], // 拜访记录
        logData: [], // 拜访日志
    }
    componentDidMount = () => {
        this.fetchRecord();
        this.fetchLog();
    }

    // 查询拜访记录信息
    fetchRecord = async () => {
        const res = await findUserBusinessRecords()
        if (res.code === 20000) {
            this.setState({
                recordData: res.data
            })
        }
    }

    // 查询拜访日志
    fetchLog = async createTime => {
        const res = await findBusinessLogsByUserId({ createTime })
        if (res.code === 20000) {
            this.setState({
                logData: res.data
            })
        }
    }

    // 切换日期
    handleChangeDate = date => {
       this.fetchLog(moment(date).format("YYYY-MM-DD"))
    }

    // 切换
    handleTabChange = (tab, index) => {
        this.setState({
            index
        })
    }

    // 选择业务状态
    handlePitchOn = (index) => {
        if (index === 1) {
            this.setState({
                pitchOn: false,
            })
        }
        if (index === 2) {
            this.setState({
                pitchOn: true,
            })
        }
    }

    // 弹框
    handleModal = async () => {
        const array = [
            { icon: '29.png', name: '新增拜访', url: '/business/callOn/0' },
            { icon: '30.png', name: '新增日志', url: '/business/journal' },
        ]
        this.setState({
            iconData: array.map((x, index) => { //图标按钮
                return {
                    icon: require(`../../images/${x.icon}`),
                    text: x.name,
                    id: index + 1,
                    url: x.url,
                }
            }),
            visible: true,
        })
    }

    // 记录or日志
    handleCallOn = e => {
        this.props.history.push({ pathname: e.url })
    }

    // 记录详情页跳转
    handleRecord = id => {
        this.props.history.push({ pathname: `/business/recordInfo/${id}` })
    }

    render() {
        const { pitchOn, visible, iconData, recordData, logData } = this.state;
        return (
            <div className='business'>
                {
                    pitchOn ? 
                    <div>
                        <NavBar
                            mode='mode'
                            icon={<Icon type='left' />}
                            onLeftClick={() => this.props.history.goBack()}
                            rightContent={
                                <List className='cut_date'>
                                    <DatePicker
                                        mode="date"
                                        title="选择日期"
                                        onChange={(date)=> this.handleChangeDate(date)}
                                    >
                                    <List.Item arrow="horizontal">切换日期</List.Item>
                                    </DatePicker>
                                </List>
                            }
                        >
                            拜访日志
                        </NavBar>
                        <div className='log_list'>
                            {
                                (logData || []).map(item => {
                                    return <div className='log_info' key={item.business_id}>
                                        <div className='log_info_time'>时间：{item.create_time ? moment(item.create_time).format('YYYY-MM-DD') : ''}</div>
                                        <div className='log_info_content'>{item.content}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div> 
                        : 
                    <div>
                        <NavBar
                            mode='mode'
                            icon={<Icon type='left' />}
                            onLeftClick={() => this.props.history.goBack()}
                        >
                            拜访记录
                        </NavBar>
                        <div className='record_list'>
                        {
                            (recordData || []).map(item => {
                                return <div className='record_info' key={item.business_record_id} onClick={() => this.handleRecord(item.business_record_id)}>
                                    <div className='record_info_name'>
                                        <div>客户姓名：{item.customer_name}</div>
                                        <div>{item.visit_start_time ? moment(item.visit_start_time).format('YYYY-MM-DD HH:mm') : ''}</div>
                                    </div>
                                    <div className='record_info_content'>{item.visit_description && item.visit_description.length > 42 ? item.visit_description.substr(0, 42) + '...' : item.visit_description}</div>
                                    <div className='record_info_company'>{item.visit_company_name}</div>
                                </div>
                            })
                        }
                        </div>
                    </div>
                }
                <div className='footer'>
                    <div className='footer_info'>
                        <div className='info_img' onClick={() => this.handlePitchOn(1)}>
                            <img src={pitchOn ? require('../../images/24.png') : require('../../images/23.png')} alt='' />
                            <span className={pitchOn ? '' : 'pitchOn'}>拜访记录</span>
                        </div>
                        <div className='info_img' onClick={() => this.handlePitchOn(2)}>
                            <img src={pitchOn ? require('../../images/25.png') : require('../../images/26.png')} alt='' />
                            <span className={pitchOn ? 'pitchOn' : ''}>拜访日志</span>
                        </div>
                    </div>
                    <div className='footer_add' onClick={this.handleModal}>
                        <img src={require('../../images/10.png')} alt='' />
                    </div>
                </div>
                <Modal
                    popup
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    animationType="slide-up"
                    className='modal_repairs'
                >
                    <div className='modal_repairs_info'>
                        <div className='modal_repairs_type'>
                            <span>拜访类型</span>
                            <span>数量：2</span>
                        </div>
                        <Grid data={iconData} hasLine={false} columnNum={4} onClick={e => this.handleCallOn(e)} />
                        <Button onClick={() => this.setState({ visible: false })}>取消</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default index
