import http from "../../utils/http";

/**
 * 是否有签到的权限
 */
export const isSignAuthorized = () => http.get('attendance/findAttendanceUser')

/**
 * 打卡
 */
export const saveSignRecord = params => http.jsonPost('/attendancerecordlist/saveAttendanceRecord', { ...params })