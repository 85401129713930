import React, { Component } from 'react';
import { NavBar, Icon, Modal, ActivityIndicator } from 'antd-mobile';
import { findUserPath, queryFromGaode, findUserByUnionid } from '../../service/api'
import cookie from 'react-cookies'
import moment from 'moment';
import './index.less'
import {Map, Polyline} from 'react-amap'

const mapKey = '993d6b7fa8baed9c49a865f8ddaa4147' //需要自己去高德官网上去申请
// const randomPath = () => ({
//     longitude: 60 + Math.random() * 50,
//     latitude: 10 + Math.random() * 40,
//    })

class trackRecord extends Component {
    state = {
        trackData: [], // 轨迹记录
        visible: false,
        trid: '',
        // path: Array(5).fill(true).map(randomPath),
        path: [],
        lon: '',
        lat: '',
        animating:false,
    }
    componentDidMount = async () => {
        this.fetchRecord();
        const res = await findUserByUnionid({ unionid: cookie.load('user').user_openid })
        if (res.code === 20000) {
            cookie.save('user', res.data)
        }
    }

    fetchRecord = async () => {
        const res = await findUserPath()
        if (res.code === 20000) {
            this.setState({
                trackData: res.data
            })
        }
    }

    handleModal = async trid => {
        this.setState({
            animating:true,
        })
        const parms = {
            key: '993d6b7fa8baed9c49a865f8ddaa4147', // key值
            sid: '88318', // 服务号id
            tid: cookie.load('user').gd_id, // 终端唯一编号，即设备id
            trid: Number(trid), // 轨迹唯一编号
        }
        const res = await queryFromGaode({
            method: 0,
            url: 'https://tsapi.amap.com/v1/track/terminal/trsearch',
            parms: JSON.stringify(parms),
        })
        if (res.code === 20000 && res.data.errcode === 10000) {
            const record = res.data.data.tracks[0].points;
            const path = record.map(x => {
                return {
                    longitude: x.location.split(",")[0],
                    latitude: x.location.split(",")[1],
                }
            })
            this.setState({
                animating:false,
                visible: true,
                path,
                lon: path[0].longitude,
                lat: path[0].latitude,
            })
        }
    }

    render() {
        const { trackData, visible, path, lon, lat, animating } = this.state;
        return (
            <div className='track_main'>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    轨迹记录
                </NavBar>
                <div style={{ padding: '40px 15px 0' }}>
                    <div className='track_title'>{cookie.load('user').user_name}的运行轨迹：</div>
                    {
                        trackData.length>0?((trackData || []).map((item, index) => {
                            return <div className='track_info' key={item.path_id} onClick={() => this.handleModal(item.trid)}>
                                <div className='track_name'>轨迹{index +1}</div>
                                <div className='track_time'>{moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div>
                        })):(<div style={{textAlign:'center',paddingTop:'20px',color:'rgb(134, 134, 134)'}}>暂无数据</div>)
                    }
                </div>
                <Modal
                    visible={visible}
                    transparent
                    className='search_modal clockin_modal'
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                >
                    <NavBar
                        mode="mode"
                        icon={<Icon type="left" />}
                        onLeftClick={() => this.setState({ visible: false })}
                    >
                        运行轨迹
                </NavBar>
                    <div style={{width: '100%', height: '100%'}}>
                        <Map plugins={['ToolBar']} amapkey={mapKey} zoom={16} center={ {longitude:lon, latitude: lat}}>
                            <Polyline path={ path } />
                        </Map>
                    </div>
                </Modal>
                <div className="indicator">
                <ActivityIndicator size="large" animating={animating} />{animating &&  <span style={{color:'rgb(134, 134, 134)'}}>加载中...</span>}
                </div>
            </div>
        );
    }
}

export default trackRecord;