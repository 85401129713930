import React, { Component } from 'react';
import { NavBar, Icon, Button, } from 'antd-mobile'
import { Gallery, Uploader, Cell, CellBody } from 'react-weui';
import IP from '../../config/config';
import { findReplyByReplyId } from '../../service/api'
import moment from 'moment';

class current extends Component {
    state = {
        images: [], // 图片
        problems: {}, // 问题
        question: {}, // 回答
        files: [], // 附件
        total: 0,
        gallery: false,
    }

    componentDidMount = () => {
        this.fetchDetail(this.props.match.params.id)
    }

    fetchDetail = async replyId => {
        const res = await findReplyByReplyId({ replyId })
        if (res.code === 20000) {
            this.setState({
                problems: res.data.problemEntity,
                question: res.data.problemReplyEntity,
                images: res.data.problemReplyEntity.reply_images ? res.data.problemReplyEntity.reply_images.split(',').map((x, index) => {
                    return { id: index + 1, url: IP.host + x }
                }) : [],
                files: res.data.problemReplyEntity.problemReplyFileEntities,
                total: res.data.total
            })
        }
    }

    // 回答
    handleQuestion = id => {
        this.props.history.push({ pathname: `/problem/reply/${id}` })
    }

    // 全部回答
    handleSkip = id => {
        this.props.history.push({ pathname: `/problem/question/${id}` })
    }

    renderGallery = () => {
        if (!this.state.gallery) return false;
        let srcs = this.state.images.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={this.state.gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
            </Gallery>
        )
    }

    render() {
        const { images, problems, question, files, total } = this.state;
        return (
            <div className='question_home question-add'>
                {this.renderGallery()}
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    问题详情
                </NavBar>
                <div className='question_main'>
                    <div className='question_problem'>{problems.problem_title}</div>
                    <div className='qusetion_btn'>
                        <div onClick={() => this.handleSkip(problems.problem_id)}>全部{total}个回答 &gt;</div>
                        <Button type='primary' onClick={() => this.handleQuestion(problems.problem_id)}>我来答</Button>
                    </div>
                    <div className='question_separator'></div>
                    <div className='question_info'>
                        <div className='problem_header'>
                            <div className='problem_header_img'>
                                <img src={question.reply_user_avatar} alt='' />
                            </div>
                            <div className='problem_header_name'>
                                <div>{question.reply_user_name}</div>
                                <div>{moment(question.reply_time).format('YYYY-MM-DD')}</div>
                            </div>
                        </div>
                        <div>{question.reply_content}</div>
                        {
                            images && images.length ?
                                <Cell>
                                    <CellBody>
                                        <Uploader
                                            files={this.state.images}
                                            onFileClick={
                                                (e, file, i) => {
                                                    this.setState({
                                                        gallery: {
                                                            url: file.url,
                                                            id: i
                                                        }
                                                    })
                                                }
                                            }
                                        />
                                    </CellBody>
                                </Cell> : ''
                        }
                    </div>
                    <div className='question_separator'></div>
                    {
                        (files || []).map(v => {
                            return <div className="tabs-access" key={v.file_id}>
                                <div className="tabs-img">
                                    <img src={IP.TypeIcon[v.file_url.substring(v.file_url.lastIndexOf(".") + 1)] ? IP.host + IP.TypeIcon[v.file_url.substring(v.file_url.lastIndexOf(".") + 1)] : IP.host + IP.TypeIcon['error']} width="40px" alt="" />
                                </div>
                                <div className="tabs-main">
                                    <div className="tabs-title">{v.file_name.length > 24 ? v.file_name.substr(0, 24) + "..." : v.file_name}</div>
                                    <div className="tabs-size">
                                        <span>{v.file_size}MB</span>
                                    </div>
                                </div>
                                <div className="tabs-icon" onClick={() => {
                                    window.location.href = IP.host + v.file_url
                                }}>
                                    <span>•</span>
                                    <span>•</span>
                                    <span>•</span>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        );
    }
}

export default current;