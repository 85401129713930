import React, { Component } from 'react';
import { ssoUserLogin } from '../../service/api';

class Test extends Component {
    componentDidMount = async () => {
        const result = await ssoUserLogin()
        console.log(result)
    }
    render() {
        return (
            <div>

            </div>
        );
    }
}

export default Test;