import React, { Component } from 'react'
import JSSDK from './../../utils/wechat';
import { inject, observer } from 'mobx-react';
import { NavBar, Icon, List, Picker, InputItem, Toast, WingBlank, Button, WhiteSpace, TextareaItem, Modal } from 'antd-mobile';
import cookie from 'react-cookies';
import { req_upload, voiceRepair } from '../../service/api';
import { Uploader, CellBody, Cell, Gallery, GalleryDelete } from 'react-weui';
import './index.less'
import wx from 'weixin-js-sdk';
import { getLocationInfoByLonAndLat } from './../../service/api';
import { Map, Marker } from 'react-amap'

const mapKey = '70d9217c07af926e2d27f90b0c5369f8' //需要自己去高德官网上去申请

@inject('globalStore')
@observer
class VoiceRepair extends Component {

    state = {
        demoFiles: [],
        urls: [], // 上传返回的url
        voiceFlag: true,// 是否现实录音按钮
        textVoice: '', // 语音识别结果
        serverId: '', // 线上语音ID
        localId: '', // 本地语音ID
        address: '', //
        department: '',// 部门
        phone: '',
        lat: '',
        lon: ''

    }

    componentDidMount = async () => {
        document.getElementsByClassName("weui-uploader__input")[0].removeAttribute("capture")
        window.addEventListener('contextmenu', function (e) {
            e.preventDefault();
        });
        document.addEventListener('click', function (e) {
            console.log(e);
            // 类似事件委托, 判断一下发生事件的元素.
            if (e.target.nodeName.toLowerCase() === 'span') {
                return;
            }
        }, false);

        document.addEventListener('touchstart', function (e) {
            console.log(e);
            // 类似事件委托, 判断一下发生事件的元素.
            if (e.target.nodeName.toLowerCase() === 'span') {
                return;
            }
        }, false);
        this.getWeChatLocation()
    }

    // 初始化微信配置
    InitWechatConfig = () => {
        const { globalStore: { appId } } = this.props;
        return JSSDK.InitWxConfig(appId)
    }


    // 获取位置
    getWeChatLocation = async () => {
        const { record } = this.props.location
        if (record) {
            if (record.name === '/user/repairs') {
                this.setState({
                    userTypeId: [1]
                })
            }
            if (record.name === '/staff/repairs') {
                this.setState({
                    userTypeId: [2]
                })
            }
        }
        await this.InitWechatConfig()
        wx.ready(async e => {
            const res = await JSSDK.getLocation()
            const { lon, lat } = res;
            const result = await getLocationInfoByLonAndLat(lon, lat)
            if (result.info === 'OK') {
                this.setState({
                    address: result.regeocode.formatted_address,
                    lat,
                    lon
                })
            }
        })
    }

    // 手动选择地图
    handleChangeLocation = () => {
        const { lon, lat } = this.state;
        console.log(lon, lat)
    }

    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    // 开启录音
    handleVoiceStart = () => {
        JSSDK.handleVoiceStart()
    }

    // 播放录音
    handlePlayVoice = () => {
        const { localId } = this.state;
        wx.playVoice({
            localId // 需要播放的音频的本地ID，由stopRecord接口获得
        });

    }

    // 提交工单的时候
    handleWorkOrderSubmit = async () => {
        const { urls, serverId, userTypeId, department, lat, lon, phone, address, textVoice } = this.state;
        if (!(serverId && userTypeId && userTypeId.length && department && phone)) return Toast.info('请完善报修信息', 2, null, false)
        const { history: { goBack } } = this.props;
        const result = await voiceRepair(serverId, userTypeId[0], department, urls.join(','), lat, lon, address, phone, textVoice)
        if (result.code === 20000) {
            console.log(result)
            Toast.success('提交工单成功！');
            setTimeout(() => {
                goBack()
            }, 500);
        }
    }

    // 松开录音按钮的时候
    handleVoiceEnd = async () => {
        const result = await JSSDK.handleVoiceEnd();
        result.voiceFlag = false
        this.setState(result)
    }

    render() {
        const { userTypeId, voiceFlag, department, address, phone, textVoice, visible, lon, lat } = this.state;
        const userType = [
            {
                label: '普通用户',
                value: 1,
            },
            {
                label: '员工',
                value: 2,
            }
        ];
        const markerEvents = {
            click: async (e) => {
                const res = await getLocationInfoByLonAndLat(e.lnglat.lng, e.lnglat.lat)
                if (res.info === 'OK') {
                    this.setState({
                        address: res.regeocode.formatted_address,
                        lat: e.lnglat.lat,
                        lon: e.lnglat.lng,
                    })
                }
            }
        }
        return (
            <div>
                {this.renderGallery()}
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    语音报修
                </NavBar>
                <List>
                    <Picker
                        extra="请选择用户类型"
                        data={userType}
                        cols={1}
                        value={userTypeId}
                        onChange={(val) => {
                            this.setState({
                                userTypeId: val,
                            })
                        }}
                    >
                        <List.Item arrow="horizontal">用户类型</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入姓名" style={{ textAlign: 'right', paddingRight: 8 }} value={cookie.load('user').user_nick}>
                        微信昵称
                    </InputItem>

                    <InputItem placeholder="请输入单位部门" value={department} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        this.setState({
                            department: e
                        })
                    }}>
                        单位部门
                    </InputItem>
                    <InputItem placeholder="请输入联系电话" type="phone" value={phone} style={{ textAlign: 'right', paddingRight: 8 }} onChange={e => this.setState({ phone: e })}>
                        联系电话
                    </InputItem>

                    {/* <InputItem placeholder="请输入位置信息" value={address} style={{ textAlign: 'right', paddingRight: 8 }} onChange={
                        e => this.setState({ address: e })
                    }>
                        位置信息
                    </InputItem> */}
                    <InputItem placeholder="请输入位置信息" value={address} style={{ textAlign: 'right', paddingRight: 8 }}
                        onFocus={(e) => {
                            this.setState({
                                visible: true,
                            })
                        }}
                    >
                        位置信息
                    </InputItem>
                    {
                        textVoice && <List renderHeader={() => '问题描述'}>
                            <TextareaItem
                                placeholder="请输入你要报修的问题"
                                disabled
                                value={textVoice}
                                rows={5}
                                count={200}
                            />
                        </List>
                    }
                    <Cell>
                        <CellBody>
                            <Uploader
                                title="图片上传"
                                maxCount={4}
                                files={this.state.demoFiles}
                                onError={msg => alert(msg)}
                                onChange={(file) => {
                                    let newFiles = [...this.state.demoFiles, { url: file.data }];
                                    let files = file.nativeFile
                                    this.setState({
                                        demoFiles: newFiles
                                    });
                                    Toast.loading('图片上传中', 0)
                                    let formData = new FormData();
                                    formData.append("multipartFile", files);
                                    req_upload(formData).then(res => {
                                        this.setState({
                                            urls: this.state.urls.concat(res.data)
                                        }, () => {
                                            Toast.hide()
                                        })
                                    })
                                }}
                                onFileClick={
                                    (e, file, i) => {
                                        this.setState({
                                            gallery: {
                                                url: file.url,
                                                id: i
                                            }
                                        })
                                    }
                                }
                                lang={{
                                    maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                                }}
                            />
                        </CellBody>
                    </Cell>
                    <WhiteSpace size="xl" />
                    <WhiteSpace size="xl" />
                    <WhiteSpace size="xl" />
                    {
                        !voiceFlag && <div className="cuChat">
                            <div className="cuItem self ">
                                <div className="main">
                                    <div className="content bgGreen shadow" onClick={() => this.handlePlayVoice()}>
                                        <span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;( ( (
                                        </span>
                                    </div>
                                </div>
                                <div className="cuAvatar radius" style={{ backgroundImage: `url(${cookie.load('user').user_avatar})` }}>
                                </div>
                            </div>
                        </div>
                    }
                    <WingBlank size="lg">
                        {
                            voiceFlag ?
                                (
                                    <div className="voice-panel">
                                        <div className="voice" onTouchStart={() => this.handleVoiceStart()} onTouchEnd={() => this.handleVoiceEnd()}>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <Button type="primary" onClick={() => this.handleWorkOrderSubmit()}>提交工单</Button>
                                )
                        }
                    </WingBlank>
                </List>
                <Modal
                    visible={visible}
                    transparent
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                >
                    {console.log(lon, lat)}
                    <div style={{width: '100%', height: '400px'}}>
                        <Map amapkey={mapKey} zoom={15} plugins={['ToolBar']} center={ {longitude:lon, latitude: lat} } events={markerEvents}>
                            <Marker position={{longitude:lon, latitude: lat}}></Marker>
                        </Map>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default VoiceRepair