import React, { Component } from 'react';
import { List, NavBar, Icon } from 'antd-mobile';
import { inject, observer } from 'mobx-react';

const { Item } = List;
const { Brief } = Item;

@inject('depStore')
@observer
class Tree extends Component {
    state = {
        data: []
    }
    componentDidMount = () => {
        // 首次进入的时候
        this.fetchData(0)

    }

    componentWillReceiveProps = (nextProps) => {
        // console.log(nextProps.match.params.id)
        this.fetchData(nextProps.match.params.id)
    }

    // 查询当前节点的所有子节点
    fetchData = (parentId) => {
        const { depStore: { depData } } = this.props;
        console.log(JSON.stringify(depData), 'DepData')
        const data = depData.filter(p => p.group_parent_id === Number(parentId))
        this.setState({
            data
        }, () => {
            console.log(this.state.data, 'data')
        })
    }

    // 点击复选框时候
    handleCheckClick = (e, id) => {
        e.stopPropagation()
        const { depStore: { setCheckedData, checkedData } } = this.props;
        setCheckedData(checkedData.includes(id) ? checkedData.filter(p => p !== id) : checkedData.concat(id))
    }


    render() {
        const { data } = this.state;

        const { depStore: { checkedData, depData } } = this.props;

        // 渲染复选框
        const renderCheck = (id) => (
            <div onClick={(e) => this.handleCheckClick(e, id)} className={checkedData.includes(id) ? 'item-check-active' : 'item-check'}>
                <Icon type='check-circle-o' style={{ color: '#fff' }} />
            </div>
        )
        return (
            <div>
                <NavBar
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={<Icon type='check' onClick={() => this.props.history.push('/task/taskAdd/0/0')} />}
                >
                    选择部门
                </NavBar>
                <List>
                    {
                        data.map(
                            item => (
                                <Item
                                    thumb={renderCheck(item.user_group_id)}
                                    arrow={depData.find(p => p.group_parent_id === item.user_group_id) ? 'horizontal' : 'empty'}
                                    key={item.user_group_id}
                                    onClick={() => depData.find(p => p.group_parent_id === item.user_group_id) && this.props.history.push(`/task/tree/${item.user_group_id}`)}
                                >
                                    {item.group_name}
                                    <Brief>{item.group_description}</Brief>
                                </Item>
                            )
                        )
                    }
                </List>
            </div>
        );
    }
}

export default Tree;