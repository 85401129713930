import React, { Component } from 'react'
import { Grid, NavBar, Icon, Modal } from 'antd-mobile'
import cookie from 'react-cookies'

const alert = Modal.alert;
export default class Index extends Component {
    state = {
        modules: [
            {
                icon: require('../../images/31.png'),
                text: '轨迹',
                url: '/track/track'
            },
            {
                icon: require('../../images/32.png'),
                text: '轨迹记录',
                url: '/track/trackRecord',
            },
            {
                icon: require('../../images/32.png'),
                text: '修改密码',
                url: '/track/password'
            },
        ],
    }
    componentDidMount = () => {
        const user = cookie.load('user')
        const { history: { push } } = this.props;
        console.log(user)
        if (user.mobile_password && user.user_phone) {
            // 如果设置用户名或者密码的时候正常访问 

        }
        else {
            // 否则添加一个提示
            alert('警告', '检测到您未设置密码，将无法使用此功能是否设置密码？', [
                { text: '再想想', onPress: () => push('/') },
                { text: '是的', onPress: () => push('/track/password') },
            ])
        }

    }

    handleSkip = e => {
        this.props.history.push({ pathname: e.url })
    }

    render() {
        const { modules } = this.state;
        return (
            <div>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    运行轨迹
                </NavBar>
                <Grid data={modules} hasLine={false} onClick={e => this.handleSkip(e)} />
            </div>
        )
    }
}

