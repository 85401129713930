import React, { Component } from 'react'
import { NavBar, Icon, List, Picker, InputItem, TextareaItem, ImagePicker } from 'antd-mobile'
import { findOrderById } from '../../service/api'
import IP from '../../config/config'
import './index.less'

class index extends Component {
    state = {
        detail: [],
        files: [],
        finish_pics: [],
    }

    componentDidMount = () => {
        this.fetchDeatail(this.props.match.params.id)
    }

    // 查询工单详情
    fetchDeatail = async orderId => {
        const res = await findOrderById({ orderId })
        console.log(res)
       if (res.code === 20000) {
            const files = res.data.urls && res.data.urls.length ? res.data.urls.split(',').map((x,i) => ({url: IP.host + x, id: i + 1})) : []
            const finish_pics = res.data.finish_pic_urls && res.data.finish_pic_urls.length ? res.data.finish_pic_urls.split(',').map((x,i) => ({url: IP.host + x, id: i + 1})) : []
            console.log(files)
            this.setState({
                detail: res.data,
                files,
                finish_pics
            })
       }
    }

    render() {
        const { detail, files, finish_pics } = this.state;
        const userType = [
            {
                label: '普通用户',
                value: 1,
            },
            {
                label: '员工',
                value: 2,
            }
        ];
        return (
            <div className='detail'>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    工单详情
                </NavBar>
                <List>
                    <InputItem placeholder="请输入工单编号" disabled style={{ textAlign: 'right', paddingRight: 8 }} value={detail.order_no}>
                        工单编号
                    </InputItem>
                    <Picker
                        extra="请选择用户类型"
                        data={userType}
                        value={[detail.bx_source]}
                        cols={1}
                        disabled
                    >
                        <List.Item arrow="horizontal">用户类型</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入姓名" disabled style={{ textAlign: 'right', paddingRight: 8 }} value={detail.bx_nick}>
                        微信昵称
                    </InputItem>
                    <InputItem placeholder="请输入单位部门" disabled value={detail.bx_department_name} style={{ textAlign: 'right', paddingRight: 8 }}>
                        单位部门
                    </InputItem>
                    <InputItem placeholder="请输入联系电话" disabled value={detail.bx_phone} style={{ textAlign: 'right', paddingRight: 8 }} type="phone">
                        联系电话
                    </InputItem>

                    <InputItem placeholder="请输入位置信息" disabled value={detail.area_name} style={{ textAlign: 'right', paddingRight: 8 }}>
                        位置信息
                    </InputItem>
                </List>
                <List renderHeader={() => '问题描述'}>
                    <TextareaItem
                        disabled
                        placeholder="请输入你要报修的问题"
                        value={detail.order_desc}
                        rows={5}
                        count={200}
                    />
                </List>
                {
                    files && files.length ? <List renderHeader={() => '报修图片'}>
                        <ImagePicker
                            files={files}
                            disableDelete
                            selectable={false}
                        />
                    </List> : ''
                }

                {
                    detail.finish_description ? <List renderHeader={() => '完工描述'}>
                        <TextareaItem
                            placeholder="请输入你的完工描述"
                            value={detail.order_desc}
                            rows={5}
                            count={200}
                        />
                    </List> : ''
                }
                
                {
                    finish_pics && finish_pics.length ? <List renderHeader={() => '完工图片'}>
                        <ImagePicker
                            files={finish_pics}
                            disableDelete
                            selectable={false}
                        />
                    </List> : ''
                }
                {
                    detail.comment_rank ? <InputItem placeholder="评价状态" disabled value={detail.comment_rank === 1 ? '好评' : detail.comment_rank === 2 ? '中评' : '差评'} style={{ textAlign: 'right', paddingRight: 8 }}>
                        评价状态
                    </InputItem> : ''
                }
                {
                    detail.comment ? <List renderHeader={() => '评价描述'}>
                        <TextareaItem
                            placeholder="请输入你的评价描述"
                            value={detail.comment}
                            rows={5}
                            count={200}
                        />
                    </List> : ''
                }
            </div>
        )
    }
}

export default index
