import React, { Component } from 'react'
import { NavBar, Icon, WhiteSpace, Toast, Popover } from 'antd-mobile'
// import {Link} from 'react-router-dom'
import { req_findListByName } from './../../service/api'
import './project.less'
import moment from 'moment';
// import IP from './../../config/config'

const Item = Popover.Item;

export default class Project extends Component {
    state = {
        data: [],
        popoverVisible: false,
    }

    componentDidMount = () => {
        this.listData();
    }

    listData = () => {
        req_findListByName().then(res => {
            if (res.code === 20000) {
                console.log(res.data)
                this.setState({
                    data: res.data
                })
            } else {
                Toast.fail(res.message, 1);
            }
        })
    }

    // 选中气泡中
    handleSelect = (opt) => {
        if (opt.props.value === 1) {
            this.props.history.push('/project/projectadd/0/0')
        }
    };


    render() {
        const { popoverVisible, data } = this.state
        // const Box = (props) => (
        //     <Link to={`/project/details/${props.data.project_id}`}>
        //         <div className="box" {...props}>
        //             <div className="box-icon">
        //                 <img src={IP.host+props.data.project_url} className="box-img" alt=""/>
        //             </div>
        //             {props.data.project_state===1?(<div className="box-int" />):('')}
        //             <div className="box-name">{props.data.project_name && props.data.project_name.length > 6 ? props.data.project_name.substr(0, 6) + '...' : props.data.project_name}</div>
        //         </div>
        //     </Link>
        // )

        return (
            <div>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.push({ pathname: `/` })}
                    rightContent={[
                        <Popover mask
                            visible={popoverVisible}
                            overlay={[
                                (<Item key="1" value={1}><img src={require('../../images/11.png')} alt='' /><span>新增项目</span></Item>),
                            ]}
                            onSelect={this.handleSelect}
                        >
                            <Icon type="ellipsis" />
                        </Popover>
                    ]}
                >
                    项目管理（共计35个）
                </NavBar>

                {/* <WhiteSpace size="lg" />
                <div className="main-num">
                    项目数量：{data && data.length}
                </div> */}
                {/* <div className="flex">
                    {
                        data.map((res,k)=>{
                            return (<Box key={k} data={res}/>)
                        })
                    }
                    
                </div> */}

                <div style ={{ padding: '0 15px' }}>
                    {/* <table width="100%" border="1" className='project_table' style={{borderCollapse: 'collapse'}}> */}
                    {/* <table width="100%" border="0" className='project_table'>
                        <thead>
                            <tr>
                                <th>项目名称</th>
                                <th>状态</th>
                                <th>负责人</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (data || []).map(item =>{
                                    return <tr key={item.project_id} onClick={()=>this.props.history.push(`/project/details/${item.project_id}`)}>
                                        <td>{item.project_name && item.project_name.length > 6 ? item.project_name.substr(0,6) + '...' : item.project_name }</td>
                                        <td>{item.project_state === 2 ? <span style={{color: 'green'}}>已完成</span> : <span style={{color: 'red'}}>未完成</span>}</td>
                                        <td>{ item.projectUserEntities.map(x=>x.pu_username).join(',') && item.projectUserEntities.map(x=>x.pu_username).join(',').length > 7 ? item.projectUserEntities.map(x=>x.pu_username).join(',').substr(0,7) + '...' : item.projectUserEntities.map(x=>x.pu_username).join(',') }</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table> */}
                    {/* {
                        (data || []).map(item => {
                            return <div className='project_info' key={item.project_id} onClick={()=>this.props.history.push(`/project/details/${item.project_id}`)}>
                                <span>{item.project_name && item.project_name.length > 12 ? item.project_name.substr(0,12) + '...' : item.project_name }</span>
                                <div>
                                    <span>{ item.projectUserEntities.map(x=>x.pu_username).join(',') && item.projectUserEntities.map(x=>x.pu_username).join(',').length > 3 ? item.projectUserEntities.map(x=>x.pu_username).join(',').substr(0,3) : item.projectUserEntities.map(x=>x.pu_username).join(',') }</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>{item.project_state === 2 ? <span style={{color: 'green'}}>已完成</span> : <span style={{color: 'red'}}>未完成</span>}</span>
                                </div>
                            </div>
                        })
                    } */}

                    <div className='project_info' style={{color:'#108ee9',fontSize:'15px'}}>
                            <div>项目名称</div>
                            <div>最新日期</div>
                    </div>
                    {
                        (data || []).map(item => {
                            return <div className='project_info' key={item.project_id} onClick={()=>this.props.history.push(`/project/details/${item.project_id}`)}>
                                <span>{item.project_name && item.project_name.length > 12 ? item.project_name.substr(0,12) + '...' : item.project_name }</span>
                                <div>
                                    {moment(item.create_time).format('YYYY-MM-DD HH:mm')}
                                </div>
                            </div>
                        })
                    }
                </div>

            </div >
        )
    }
}
