import React, { Component } from 'react'
import { NavBar, Icon, List, Picker, InputItem, TextareaItem, WhiteSpace, Toast, WingBlank, Button, Modal } from 'antd-mobile'
import cookie from 'react-cookies'
import { inject, observer } from 'mobx-react';
import { Gallery, GalleryDelete, Uploader, Cell, CellBody } from 'react-weui'; //样式在全局已经导入，直接引入react-weui中的组件即可
import { req_upload, fillInRepair } from '../../service/api'
import wx from 'weixin-js-sdk';
import JSSDK from './../../utils/wechat';
import { getLocationInfoByLonAndLat } from './../../service/api';
import { Map, Marker } from 'react-amap'

const mapKey = '70d9217c07af926e2d27f90b0c5369f8' //需要自己去高德官网上去申请

@inject('globalStore')
@observer
class index extends Component {
    state = {
        userTypeId: null, // 用户类型Id
        department: '', // 单位部门
        phone: '', // 联系电话
        problems: '', // 问题描述
        urls: [],
        gallery: false,
        demoFiles: [],
        address: '',//位置信息
        lat: '',// 维度
        lon: '',// 经度
        visible: false,
    }

    componentDidMount = () => {
        document.getElementsByClassName("weui-uploader__input")[0].removeAttribute("capture")
        const record = this.props.location.record
        if (record) {
            if (record.name === '/user/repairs') {
                this.setState({
                    userTypeId: [1]
                })
            }
            if (record.name === '/staff/repairs') {
                this.setState({
                    userTypeId: [2]
                })
            }
        }

        this.getWeChatLocation()
    }


    // 获取位置信息
    getWeChatLocation = async () => {

        await this.InitWechatConfig()
        wx.ready(async e => {
            const res = await JSSDK.getLocation()
            const { lon, lat } = res;
            const result = await getLocationInfoByLonAndLat(lon, lat)
            if (result.info === 'OK') {
                this.setState({
                    address: result.regeocode.formatted_address,
                    lat,
                    lon
                })
            }
        })
    }

    // 初始化微信配置
    InitWechatConfig = () => {
        const { globalStore: { appId } } = this.props;
        return JSSDK.InitWxConfig(appId)
    }

    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    // 提交
    handleSubmit = async () => {
        const { userTypeId, department, phone, problems, urls, lon, lat, address } = this.state;
        if (!(userTypeId && userTypeId.length && department && phone && problems && lon && lat && address)) return Toast.info('请完善报修信息', 2, null, false)
        const req = {
            bx_source: userTypeId[0],
            bx_department_name: department,
            bx_phone: phone,
            order_desc: problems,
            urls: (urls && urls.length) ? urls.join(',') : '',
            area_name: address,
            latitude: lat,
            longitude: lon
        }
        const res = await fillInRepair(req)
        if (res.code === 20000) {
            Toast.info(res.message, 2, null, false);
            this.props.history.goBack()
        }
    }

    render() {
        const { userTypeId, department, phone, address, visible, lon, lat } = this.state;
        const userType = [
            {
                label: '普通用户',
                value: 1,
            },
            {
                label: '员工',
                value: 2,
            }
        ];
        const markerEvents = {
            click: async (e) => {
                const res = await getLocationInfoByLonAndLat(e.lnglat.lng, e.lnglat.lat)
                if (res.info === 'OK') {
                    this.setState({
                        address: res.regeocode.formatted_address,
                        lat: e.lnglat.lat,
                        lon: e.lnglat.lng,
                    })
                }
            }
        }
        return (
            <div>
                {this.renderGallery()}
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    填单报修
                </NavBar>
                <List>
                    <Picker
                        extra="请选择用户类型"
                        data={userType}
                        value={userTypeId}
                        cols={1}
                        onChange={(val) => {
                            this.setState({
                                userTypeId: val,
                            })
                        }}
                    >
                        <List.Item arrow="horizontal">用户类型</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入姓名" style={{ textAlign: 'right', paddingRight: 8 }} value={cookie.load('user').user_nick}>
                        微信昵称
                    </InputItem>
                    <InputItem placeholder="请输入单位部门" value={department} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        this.setState({
                            department: e
                        })
                    }}>
                        单位部门
                    </InputItem>
                    <InputItem placeholder="请输入联系电话" value={phone} style={{ textAlign: 'right', paddingRight: 8 }} type="phone" onChange={(e) => {
                        this.setState({
                            phone: e
                        })
                    }}>
                        联系电话
                    </InputItem>
                </List>
                {/* <InputItem placeholder="请输入位置信息" value={address} style={{ textAlign: 'right', paddingRight: 8 }}
                    onChange={
                        e => this.setState({ address: e })
                    }
                >
                    位置信息
                </InputItem> */}
                <InputItem placeholder="请输入位置信息" value={address} style={{ textAlign: 'right', paddingRight: 8 }}
                    onFocus={(e) => {
                        this.setState({
                            visible: true,
                        })
                    }}
                >
                    位置信息
                </InputItem>
                <List renderHeader={() => '问题描述'}>
                    <TextareaItem
                        placeholder="请输入你要报修的问题"
                        onChange={(e) => {
                            this.setState({
                                problems: e
                            })
                        }}
                        rows={5}
                        count={200}
                    />
                </List>
                <Cell>
                    <CellBody>
                        <Uploader
                            title="图片上传"
                            maxCount={4}
                            files={this.state.demoFiles}
                            onError={msg => alert(msg)}
                            onChange={(file) => {
                                console.log(file)
                                let newFiles = [...this.state.demoFiles, { url: file.data }];
                                let files = file.nativeFile
                                console.log(newFiles)
                                this.setState({
                                    demoFiles: newFiles
                                });
                                Toast.loading('图片上传中', 0)
                                let formData = new FormData();
                                formData.append("multipartFile", files);
                                req_upload(formData).then(res => {
                                    this.setState({
                                        urls: this.state.urls.concat(res.data)
                                    }, () => {
                                        Toast.hide()
                                    })
                                })
                            }}
                            onFileClick={
                                (e, file, i) => {
                                    this.setState({
                                        gallery: {
                                            url: file.url,
                                            id: i
                                        }
                                    })
                                }
                            }
                            lang={{
                                maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                            }}
                        />
                    </CellBody>
                </Cell>
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <WingBlank size="lg">
                    <Button type="primary" onClick={this.handleSubmit}>提交</Button>
                </WingBlank>
                <WhiteSpace size="lg" />
                <Modal
                    visible={visible}
                    transparent
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                >
                    {console.log(lon, lat)}
                    <div style={{width: '100%', height: '400px'}}>
                        <Map amapkey={mapKey} zoom={15} plugins={['ToolBar']} center={ {longitude:lon, latitude: lat} } events={markerEvents}>
                            <Marker position={{longitude:lon, latitude: lat}}></Marker>
                        </Map>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default index
