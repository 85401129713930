import { observable, action } from 'mobx';
import { queryDepartmentByCompId, findRelationProject, findProjectUser, findTaskById, uploadTaskFile, updateTask, addTask } from '../service/api';
import cookie from 'react-cookies';
import { Toast } from 'antd-mobile';

class DepStore {
    // 部门数据
    @observable depData = [];

    // 复选框选择的数据
    @observable checkedData = [];

    // 所有项目
    @observable project = [];

    // 选中的项目id
    @observable porjectId = [];

    // 选中的任务紧急程度id
    @observable emergencyStatus = [];

    // 所有成员
    @observable allStaff = [];

    // 当前成员
    @observable presentMember = [];

    //剩余成员
    @observable remainMember = [];

    // 发布人id
    @observable publishId = '';

    // 附件
    @observable enclosure = []; // 附件

    // 标题
    @observable title = '';

    // 描述
    @observable desc = '';

    // 任务预计开始时间
    @observable startTime = new Date();

    // 任务预计结束时间
    @observable endTime = new Date();

    // 选择计划成员状态
    @observable personnelVisible = false;

    // 选择计划成员状态 
    @observable visible = false;

    @observable member = '';



    @action setPorjectId = porjectId => {
        this.porjectId = porjectId;
    }

    @action setEmergencyStatus = emergencyStatus => {
        this.emergencyStatus = emergencyStatus;
    }

    @action setTitle = title => {
        this.title = title;
    }

    @action setPersonnelVisible = personnelVisible => {
        this.personnelVisible = personnelVisible;
    }

    @action setDesc = desc => {
        this.desc = desc;
    }

    @action setStartTime = startTime => {
        this.startTime = startTime
    }

    @action setEndTime = endTime => {
        this.endTime = endTime
    }

    @action setVisible = visible => {
        this.visible = visible;
    }

    // 部门赋值
    @action setDepData = depData => {
        this.depData = depData
    }

    // 给CheckedData赋值
    @action setCheckedData = (checkedData) => {
        this.checkedData = checkedData
    }

    // 发布人ID set方法
    @action setPublishId = publishId => {
        this.publishId = publishId
    }

    // 查询部门
    @action queryDepData = async (compId) => {
        const result = await queryDepartmentByCompId(compId)
        if (result.code === 20000) {
            this.depData = result.data
        }
    }

    // 查询项目
    @action fetchProject = async () => {
        const res = await findRelationProject()
        if (res.code === 20000) {

            this.project = res.data.map(x => ({
                label: x.project_name,
                value: x.project_id,
            }))
            // this.project = [{ label: "全部", value: 9999 }].concat(res.data.map(x => ({
            //     label: x.project_name,
            //     value: x.project_id,
            // })))
        }
    }

    // 查询成员信息
    @action fetchMember = async (userIds) => {
        const res = await findProjectUser({ userIds })
        if (res.code === 20000) {
            if (!userIds) {
                const staff = [];
                (res.data || []).map(x => (x.sysUserEntities || []).map(t => staff.push(t)))
                this.allStaff = staff;
            }
            this.remainMember = res.data.reverse()
        }
    }

    // 编辑回显，新增重置表单
    @action initData = async (id) => {
        if (Number(id)) {
            const res = await findTaskById({ taskId: Number(id) })
            if (res.code === 20000) {
                this.publishId = res.data[0].publish_user_id;
                this.porjectId = [res.data[0].project_id]
                this.emergencyStatus = [res.data[0].emergency_tatus]
                this.enclosure = res.data[0].task_files && res.data[0].task_files.split(',').map(x => ({ fileName: x.split("*")[0], size: x.split("*")[1], url: x.split("*")[2] }))
                this.title = res.data[0].task_title;
                this.desc = res.data[0].task_description;
                this.startTime = new Date(res.data[0].start_time);
                this.endTime = new Date(res.data[0].end_time);
                this.presentMember = res.data[0].joinUsers;
                this.member = (res.data[0].joinUsers || []).map(x => x.user_name).filter(x => x).length ? (res.data[0].joinUsers || []).map(x => x.user_name).filter(x => x).join(',') : '';
                return res.data[0].group_ids;
            }
        } else {
            this.publishId = cookie.load('user').user_id;
        }
    }

    // 上传附件
    @action onChangeEnclosure = (e) => {
        const { files } = e.target;
        if (files && files.length) {
            let formData = new FormData();
            formData.append("file", files[0]);
            uploadTaskFile(formData).then(res => {
                Toast.hide()
                if (res.code === 20000) {
                    this.enclosure = this.enclosure.concat(res.data)
                } else {
                    Toast.info(res.message)
                }
            })
        }
    };

    @action initState = () => {
        this.depData = [];
        this.checkedData = [];
        this.project = [];
        this.porjectId = [];
        this.emergencyStatus = [];
        this.allStaff = [];
        this.presentMember = [];
        this.remainMember = [];
        this.publishId = '';
        this.enclosure = []; // 附件
        this.title = '';
        this.desc = '';
        this.startTime = new Date();
        this.endTime = new Date();
        this.personnelVisible = false;
        this.visible = false;
        this.member = '';
    }

    // 发布任务
    handleSubmit = async (id, chcekData, callback) => {
        const { enclosure, presentMember, title, desc, startTime, endTime, porjectId, project, emergencyStatus } = this;
        // if (!porjectId.length) return Toast.info('请选择关联项目')
        if (!title) return Toast.info('请输入任务标题')
        if (!chcekData.length) return Toast.info('请选择部门')
        if (!emergencyStatus.length) return Toast.info('请选择任务紧急程度')
        if (!desc) return Toast.info('请输入具体描述')
    
        const req = {
            task_id: Number(id) ? Number(id) : null,
            task_title: title,
            task_description: desc,
            start_time: startTime,
            end_time: endTime,
            task_files: enclosure && enclosure.length ? enclosure.map(x => (x.fileName + '*' + x.size + '*' + x.url)).join(',') : '',
            project_id: porjectId && porjectId.length ? porjectId[0] : '',
            project_name: porjectId && porjectId.length && porjectId[0] ? project.find(x => String(x.value) === String(porjectId[0])).label : '',
            emergency_tatus: emergencyStatus && emergencyStatus.length ? emergencyStatus[0] : '',
            group_ids: chcekData.join(',')
        }
        const method = Number(id) ? updateTask : addTask;
        const res = await method({
            taskEntity: JSON.stringify(req),
            joinUserIds: presentMember && presentMember.length ? presentMember.map(x => x.user_id).join(',') : '',
            groupIds: chcekData.join(',')
        });
        if (res.code === 20000) {
            this.checkedData = [];
            Toast.info(res.message)

            this.initState()

            setTimeout(() => {
                callback()
            }, 1500)
        }
    }

    handleClose = id => {
        const { remainMember } = this;
        remainMember.forEach(x => {
            if (x.checked && (x.user_group_id === id)) {
                x.checked = false
            } else {
                x.checked = x.user_group_id === id
            }
            return x
        })
    }

    // 1弹框确定
    @action handleEditMomberOk = () => {
        this.personnelVisible = false;
    }

    // 删除用户
    @action handleDelMember = id => {
        const { presentMember } = this;
        this.presentMember = presentMember.filter(x => String(x.user_id) !== String(id));
        this.member = presentMember.filter(x => String(x.user_id) !== String(id)).map(t => t.user_name).join(',');
    }


    // 选择计划成员点击确定按钮时候
    @action handleAddMemberOk = () => {
        const { allStaff, presentMember } = this;
        var obj = document.getElementsByName('staff');
        var check_val = [];
        for (var k in obj) {
            if (obj[k].checked) {
                check_val.push(obj[k].value)
            }
        }
        const check1 = check_val.concat(presentMember.map(x => x.user_id))
        const array1 = [];
        check1.forEach(x => array1.push(allStaff.find(t => Number(t.user_id) === Number(x))))
        this.presentMember = array1;
        this.visible = false;
        this.member = array1.map(x => x.user_name);

    }
}

export default new DepStore();