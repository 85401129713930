const IP = {
    host: 'https://officecloud.sastc.cn:8676',
    // host: 'https://49.4.95.232:8676',
    // host: 'https://172.22.25.57:8676',//苗
    redirectUrl: (appid, redirectURL) => `http://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(redirectURL)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`,
    TypeIcon: {
        'doc': '/targets/module-image/20220516/01.png',
        'docx': '/targets/module-image/20220516/01.png',
        'xls': '/targets/module-image/20220516/03.png',
        'xlsx': '/targets/module-image/20220516/03.png',
        'ppt': '/targets/module-image/20220516/04.png',
        'error': '/targets/module-image/20220516/05.png',
    }
}
export default IP
