import React ,{Component} from 'react'
import { NavBar,Icon,Button,InputItem,List,TextareaItem,Modal,Toast } from 'antd-mobile'
import { findProjectUser,req_addProjectPlan,req_updateProjectPlan,req_findProjectPlanByPlanId,req_deleteProjectPlan } from './../../service/api'
import './main-list.less'

class milestone extends Component{
    state={
        pp_create_username:'', // 计划成员名称
        allStaff: [], // 所有成员
        presentMember:[], // 当前成员
        remainMember:[], //剩余成员
        personnelVisible:false, // 选择计划成员状态
        visible:false, // 添加成员状态
        pp_content:'',
        pp_state:'',  
    }

    componentDidMount=()=>{
        this.fetchMember();
        if(Number(this.props.match.params.pp_id)!==0){
            this.findData()
        }
    }

    // 添加成员信息弹框
    handleMember = () => {
        const { presentMember} = this.state;
        this.fetchMember(presentMember.map(x => x.user_id).join(','))
        this.setState({
            visible: true
        })
    }
    
    // 查询成员信息
    fetchMember = async (userIds) => {
        const res = await findProjectUser({ userIds })
        if (res.code === 20000) {
            if (!userIds) {
                const staff = [];
                (res.data || []).map(x => (x.sysUserEntities || []).map(t => staff.push(t)))
                this.setState({
                    allStaff: staff
                })
            }
            this.setState({
                remainMember: res.data.reverse()
            })
        }
    }

    // 1弹框确定
    handleEditMomberOk = () => {
        this.setState({
            personnelVisible: false,
        })
    }

    // 选择用户
    handleAddMemberOk = () => {
        const { allStaff, presentMember } = this.state;
        var obj = document.getElementsByName('staff');
        var check_val = [];
        for (var k in obj) {
            if (obj[k].checked) {
                check_val.push(obj[k].value)
            }
        }
        const check1 = check_val.concat(presentMember.map(x => x.user_id))
        const array1 = [];
        check1.map(x => array1.push(allStaff.find(t=>Number(t.user_id) === Number(x))))
        this.setState({
            presentMember: array1,
            visible: false,
            pp_create_username: array1.map(x => x.user_name)
        })
    }

    // 删除用户
    handleDelMember = id => {
        const { presentMember } = this.state;
        this.setState({
            presentMember: presentMember.filter(x => String(x.user_id) !== String(id)),
            pp_create_username: presentMember.filter(x => String(x.user_id) !== String(id)).map(t => t.user_name).join(',')
        })
    }

    // 查询单条数据
    findData=()=>{
        req_findProjectPlanByPlanId({planId:this.props.match.params.pp_id}).then(res=>{
            if(res.code===20000){
                const array = res.data.projectPlanUserEntities.map(x =>{
                    return {
                        user_id: x.ppu_userid,
                        user_avatar: x.ppu_user_avatar,
                        user_name: x.ppu_username,
                    }
                });
                this.fetchMember(array.map(x => x.user_id).join(','))
                this.setState({
                    pp_state:res.data.pp_state,
                    pp_content:res.data.pp_content,
                    presentMember:array,
                    pp_create_username:array.map(x=>x.user_name),

                })
            }else{
                Toast.fail(res.message,1);
            }
        })
    }

    subAdd= async ()=>{
        const {pp_content,presentMember}=this.state
        const result={
            pp_content,
            userIds:presentMember.map(x=>x.user_id).join(','),
            pp_projectid:this.props.match.params.id,
            pp_id:Number(this.props.match.params.pp_id) ? this.props.match.params.pp_id : null,
        }
        if(pp_content===''){
            Toast.fail("请填写内容",1)
            return
        }
        const method=Number(this.props.match.params.pp_id)===0?req_addProjectPlan:req_updateProjectPlan
        const res=await method(result)
        if(res.code === 20000){
            Toast.success(res.message,2);  
            setTimeout(() => {
                this.props.history.push({pathname:`/project/workPlan/${this.props.match.params.id}/${this.props.match.params.status}`});
            }, 1000);
        }else{
            Toast.fail(res.message,1);
        }
    }

    subDel=(ppId)=>{
        req_deleteProjectPlan({ppId:ppId}).then(res=>{
            if(res.code===20000){
                Toast.success(res.message,1);
                setTimeout(() => {
                    this.props.history.push({pathname:`/project/workPlan/${this.props.match.params.id}/${this.props.match.params.status}`});
                }, 1000);
            }else{
                Toast.fail(res.message,1);
            }
        })
    }

    handleClose = id => {
        const { remainMember } = this.state;
        console.log(remainMember, id)
        remainMember.map(x => {
            if (x.checked && (x.user_group_id === id)) {
                x.checked = false
            } else {
                x.checked = x.user_group_id === id
            }
            return x
        })
        this.setState({
            remainMember
        })
    }

    render=()=>{
        const { pp_create_username,personnelVisible,presentMember,visible,remainMember,pp_content,pp_state }=this.state
        return (
            <div  style={{background:"#F5F5F5",height:"100vh"}}>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                {Number(this.props.match.params.pp_id)!==0?"编辑":"新增"}工作计划
                </NavBar>
                <div style={{height:"10px",background:"#F5F5F5"}} />
                <InputItem disabled={this.props.match.params.status === '2'} placeholder="请选择计划成员" value={pp_create_username} style={{ textAlign: 'right', paddingRight: 8 }} onFocus={(e) => {
                    this.setState({
                        personnelVisible: true,
                    })
                }}>
                    计划成员
                </InputItem>
                <div style={{width:"100%",height:"8px"}}></div>
                <List  className="main-text"  renderHeader={() => '计划描述'}>
                    <TextareaItem
                        disabled={this.props.match.params.status === '2'}
                        placeholder="请输入计划描述"
                        rows={5}
                        count={200}
                        value={pp_content}
                        onChange={(e)=>{
                            this.setState({
                                pp_content:e
                            })
                        }}
                    />
                </List>

                {
                    Number(this.props.match.params.pp_id)!==0 && pp_state===1 && Number(this.props.match.params.status) !== 2?(<Button style={{marginTop:"8px",color:"#FE2626"}} onClick={()=>{
                        this.subDel(this.props.match.params.pp_id)
                    }}>删除该计划</Button>):('')
                } 

                {
                    (Number(this.props.match.params.status) !== 2) && <div style={{width:"100%",position:"fixed",bottom:"10px",zIndex:"999"}}>
                        <Button  type="primary" className="button-footer" onClick={()=>{
                            this.subAdd()
                        }}>保存</Button>
                    </div>
                }
                <Modal
                    visible={personnelVisible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            personnelVisible: false
                        })
                    }}
                >
                    <NavBar
                        mode='mode'
                        icon={<Icon type='left' />}
                        onLeftClick={() => this.setState({ personnelVisible: false })}
                        rightContent={<div onClick={this.handleEditMomberOk}>确定</div>}
                    >
                        人员结构管理
                    </NavBar>
                    <div className='personnel_people'>
                        {
                            (presentMember || []).map(item => {
                                return <div className='personnel_info' key={item.user_id}>
                                    <div className='member_info'>
                                        <div><img src={item.user_avatar} alt='' /></div>
                                        <span >{item.user_name}</span>
                                    </div>
                                    <div className='personnel_image' onClick={() => this.handleDelMember(item.user_id)}>
                                        <img src={require('../../images/28.png')} alt='' />
                                    </div>
                                </div>
                            })
                        }
                        <div className='personnel_addImg'>
                            <div className='personnel_img' onClick={this.handleMember}>
                                <img src={require('../../images/27.png')} alt='' />
                            </div>
                            <span>添加成员</span>
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={visible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                >
                    <NavBar
                        mode='mode'
                        icon={<Icon type='left' />}
                        onLeftClick={() => this.setState({ visible: false })}
                        rightContent={<div onClick={this.handleAddMemberOk}>确定</div>}
                    >
                        选择计划成员
                    </NavBar>
                    <div>
                        {/* {
                            (remainMember || []).map(item => {
                                return <label
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px 15px'
                                    }}
                                    key={item.user_id}
                                >
                                    <input
                                        name="staff"
                                        type="checkbox"
                                        value={item.user_id}
                                    />
                                    <div className='member_info'>
                                        <div><img src={item.user_avatar} alt='' /></div>
                                        <span >{item.user_name}</span>
                                    </div>
                                </label>
                            })
                        } */}
                        {
                            (remainMember || []).map(item => {
                                return <div key={item.user_group_id} className='user_main'>
                                    <div className='user_group' onClick={()=>this.handleClose(item.user_group_id)}>
                                        {
                                            item.checked ? <img style={{ marginRight: 5 }} src={require('../../images/open.jpg')} alt='' /> :
                                            <img style={{ marginRight: 5 }} src={require('../../images/close.jpg')} alt='' />
                                        }
                                        <span>{item.group_name}</span>
                                    </div>
                                    <div className={`user_info ${item.checked ? '' : 'user_active'}`}>
                                        {
                                            (item.sysUserEntities || []).map(i => {
                                                return <label
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '5px 30px'
                                                    }}
                                                    key={i.user_id}
                                                >
                                                    <input
                                                        name="staff"
                                                        type="checkbox"
                                                        value={i.user_id}
                                                    />
                                                    <div className='member_info'>
                                                        <div><img src={i.user_avatar} alt='' /></div>
                                                        <span >{i.user_name}</span>
                                                    </div>
                                                </label>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Modal>
            </div>
        )
    }

}
export default milestone