import React, { Component } from 'react'
import IP from '../../config/config'
import cookie from 'react-cookies'
import Utils from './../../utils/utils'
import { observer, inject } from 'mobx-react'
import { qeuryUserInfoByCode } from '../../service/api'

@inject('globalStore')
@observer
class Login extends Component {

    componentDidMount = () => {
        this.handleLogin()
    }

    handleLogin = () => {
        const { globalStore: { appId } } = this.props
        const code = Utils.GetQueryString('code')
        if (code) {
            // 重定向之后
            this.getUserInfoByCode(code, appId)
            return
        }
        const redirectUrl = IP.redirectUrl(appId, window.location.href)
        window.location.href = redirectUrl
        return
    }

    getUserInfoByCode = async (code, appid) => {
        const { globalStore: { setUser } } = this.props
        const result = await qeuryUserInfoByCode(code, appid)
        if (result.code === 20000) {
            setUser(result.data)
            cookie.save('token', result.data.sessionId, { path: '/' })
            cookie.save('user', result.data, { path: '/' })
            window.location.reload()
        }
    }



    render () {
        return (
            <div>

            </div>
        )
    }
}

export default Login