import React ,{Component} from 'react'
import { NavBar,Icon,Button,List,TextareaItem, Toast } from 'antd-mobile'
import { req_addProjectMilepost,req_findProjectMilepostByMilepostId,req_updateProjectMilepost,req_deleteProjectMilepost } from './../../service/api'
import moment from 'moment'
import './main-list.less'

class milestone extends Component{
    state={
        pm_content:'', // 节点内容
        data:{}
    }

    componentDidMount=()=>{
        if(Number(this.props.match.params.pm_id)!==0){
            this.findData()
        }
    }

    findData=()=>{
        req_findProjectMilepostByMilepostId({milepostId:this.props.match.params.pm_id}).then(res=>{
            if(res.code===20000){
                this.setState({
                    data:res.data,
                    pm_content:res.data.pm_content ||''
                })
            }else{
                Toast.fail(res.message,1);
            }
        })
    }
    
    subAdd= async()=>{
        const {pm_content}=this.state
        const result={
            pm_content,
            pm_projectid:Number(this.props.match.params.id),
            pm_id:Number(this.props.match.params.pm_id)!==0?Number(this.props.match.params.pm_id):''
        }
        if(pm_content===''){
            Toast.fail("请填写内容",1)
            return
        }
        const method = Number(this.props.match.params.pm_id)===0 ? req_addProjectMilepost : req_updateProjectMilepost;
        const res= await method(result);
        if(res.code === 20000){
            Toast.success(res.message,2);  
            setTimeout(() => {
                this.props.history.push({pathname:`/project/milestone/${this.props.match.params.id}/${this.props.match.params.status}`});
            }, 1000);
        }else{
            Toast.fail(res.message,1);
        }
        

    }

    subDel=(pm_id)=>{
        req_deleteProjectMilepost({pmId:pm_id}).then(res=>{
            if(res.code===20000){
                Toast.success(res.message,1);
                setTimeout(() => {
                    this.props.history.push({pathname:`/project/milestone/${this.props.match.params.id}/${this.props.match.params.status}`});
                }, 1000);
            }else{
                Toast.fail(res.message,1);
            }
        })
    }

    render=()=>{
        const { data,pm_content }=this.state
        return (
            <div style={{background:"#F5F5F5",height:"100vh"}}>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                {Number(this.props.match.params.pm_id)===0?'新增':'编辑'}里程碑
                </NavBar>
                <div style={{height:"10px",background:"#F5F5F5"}} />
                    {
                        Number(this.props.match.params.pm_id)!==0?(
                        <div>
                            <div className="main-list">
                                <div className="main-left">节点创建时间</div>
                                <div className="main-right">{moment(data.pm_start_time).format("YYYY-MM-DD HH:mm")}</div>   
                            </div>

                            {
                                data.pm_end_time?(<div className="main-list">
                                <div className="main-left">节点完成时间</div>
                                <div className="main-right">{moment(data.pm_end_time).format("YYYY-MM-DD HH:mm")}</div>   
                            </div>):('')
                            }
                            {
                                data.pm_end_username?(<div className="main-list">
                                <div className="main-left">操作人</div>
                                <div className="main-right">{data.pm_end_username}</div>   
                            </div>):('')
                            }
                            
                        </div>
                        ):('')
                    }
                    
                    <div style={{width:"100%",height:"8px"}}></div>
                    <List className="main-text" renderHeader={() => '节点描述'}>
                        <TextareaItem
                            value={pm_content}
                            placeholder="请输入节点描述"
                            rows={5}
                            count={200}
                            disabled={this.props.match.params.status === '2'}
                            onChange={(e)=>{
                                this.setState({
                                    pm_content:e
                                })
                            }}
                        />
                    </List>
                
                {
                    Number(this.props.match.params.pm_id)!==0 && data.pm_state===1 && Number(this.props.match.params.status)!==2?(<Button style={{marginTop:"8px",color:"#FE2626"}} onClick={()=>{
                        this.subDel(data.pm_id)
                    }}>删除该里程碑</Button>):('')
                } 

                {
                    Number(this.props.match.params.status)!==2 && <div style={{width:"100%",position:"fixed",bottom:"10px",zIndex:"999"}}>
                        <Button  type="primary" className="button-footer" onClick={()=>{
                            this.subAdd()
                        }}>保存</Button>
                    </div>
                }  
            </div>
        )
    }

}
export default milestone