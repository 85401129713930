import React, { Component } from 'react'
import cookie from 'react-cookies'

export default class Track extends Component {
    componentDidMount = () => {
        window.location.href = `http://officecloud.sastc.cn/downloadApp/Android.html?token=${cookie.load('user').user_phone}`
    }
    render() {
        return (
            <div>
            </div>
        )
    }
}