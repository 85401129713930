import axios from 'axios';
import { Toast } from 'antd-mobile';
import qs from 'qs';
import IP from '../config/config';
import cookie from 'react-cookies'

let baseUrl = IP.host;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;

const instance = axios.create({
    xsrfCookieName: 'xsrf-token',
    baseURL: baseUrl,
    timeout: 30000,
    responseType: 'json',
});

instance.interceptors.request.use(config => {
    Toast.loading('加载中')
    config.headers['x-auth-token'] = cookie.load('token');
    return config;
}, err => {
    Toast.fail('请求失败')
    return Promise.reject(err);
});

instance.interceptors.response.use(function (response) {
    Toast.hide()
    const { data } = response;
    const { code } = data;
    const msg = code !== 20000 ? Toast.fail : console.log;
    // msg(data.message);
    if (code === 20009) {
        cookie.remove('token', { path: '/' })
        cookie.remove('user', { path: '/' })
        window.location.href = '/'
    }
    return Promise.resolve(data);
}, err => {
    Toast.fail('请求失败')
    return Promise.reject(err);
});

export default {
    get(url, param) {
        return new Promise(resolve => {
            instance({
                method: 'get',
                url,
                params: param,
            }).then(res => {
                resolve(res);
            });
        }).catch((err) => { console.error(err) });
    },
    post(url, param) {
        return new Promise(resolve => {
            instance({
                method: 'post',
                url,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: qs.stringify(param),
            })
                .then(res => {
                    resolve(res);
                })
                .catch((err) => { console.error(err) });
        });
    },
    jsonPost(url, param) {
        return new Promise(resolve => {
            instance({
                method: 'post',
                url,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: param,
            })
                .then(res => {
                    resolve(res);
                })
                .catch((err) => { console.error(err) });
        });
    },
    upload(url, param) {
        return new Promise((resolve) => {
            instance({
                method: 'post',
                url,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: param
            }).then(res => {
                resolve(res)
            }).catch(err => {
                console.error(err)
            })
        })
    }
};
