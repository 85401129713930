import React, { Component } from 'react'
import { NavBar, Icon, List, InputItem, WhiteSpace, WingBlank, Button, Toast } from 'antd-mobile'
import cookie from 'react-cookies'
import { authentication } from '../../service/api'

class index extends Component {
    state = {
        userName: '', // 姓名
        phone: '', // 联系电话
        // IDCard: '', // 身份证
        company: '', // 公司
    }

    componentDidMount = () => {
        const record = cookie.load('user')
        this.setState({
            userName: record.user_name,
            phone: record.user_phone,
            // IDCard: record.identity_number,
        })
    }

    handleSubmit = async () => {
        const { userName, phone } = this.state;
        if (!(userName && phone)) return Toast.info('请完善认证信息', 2, null, false)
        const req = {
            user_id: cookie.load('user').user_id,
            user_name: userName,
            user_phone: phone.replace(/\s/g, ''),
            // identity_number: IDCard,
            user_company_id:cookie.load('user').user_company_id
        }
        const res = await authentication(req)
        if (res.code === 20000) {
            res.data.role_id = cookie.load('user').role_id;
            cookie.save('user', res.data)
            // const newUser = { ...cookie.load('user'), ...req };
            // cookie.save('user', newUser)
            Toast.info(res.message, 2, null, false)
            setTimeout(() => { this.props.history.goBack() }, 1000)
        }
    }

    render() {
        const { userName, phone } = this.state;
        return (
            <div>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    实名认证
                </NavBar>
                <List>
                    <InputItem placeholder="请输入微信昵称" disabled style={{ textAlign: 'right', paddingRight: 8 }} value={cookie.load('user').user_nick}>
                        微信昵称
                    </InputItem>
                    <InputItem placeholder="请输入姓名" value={userName} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        this.setState({
                            userName: e
                        })
                    }}>
                        姓名
                    </InputItem>
                    <InputItem placeholder="请输入联系电话" value={phone} style={{ textAlign: 'right', paddingRight: 8 }} type="phone" onChange={(e) => {
                        this.setState({
                            phone: e
                        })
                    }}>
                        联系电话
                    </InputItem>
                    {/* <InputItem placeholder="请输入身份证" maxLength={18} value={IDCard} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        this.setState({
                            IDCard: e
                        })
                    }}>
                        身份证
                    </InputItem> */}
                    <InputItem placeholder="请输入公司" disabled value={cookie.load('user').user_company_name} style={{ textAlign: 'right', paddingRight: 8 }}>
                        公司
                    </InputItem>
                </List>
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <WingBlank size="lg">
                    <Button type="primary" onClick={this.handleSubmit}>认证</Button>
                </WingBlank>
                <WhiteSpace size="lg" />
            </div>
        )
    }
}

export default index
