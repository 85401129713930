import React, { Component } from 'react';
import { NavBar, Icon, Toast, Picker } from 'antd-mobile'
import { findAllTask, queryDepartmentByCompId } from '../../service/api'
import './index.less'
import cookie from 'react-cookies';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

const str2HTML = str => {
    const x = document.createElement('div')
    x.innerHTML += str;
    return typeof x.children[0] === 'object' ? x.children[0].innerText : str
}

@inject('depStore')
@observer
class index extends Component {

    state = {
        listData: [],
        tab: [
            { key: 1, name: '所有', checked: true },
            { key: 2, name: '未完成', checked: false },
        ],
        pickerVisible: false,
        depData: [], // 部门原始数据
        depTree: [],// 部门树形
        maxFloor: 0,// 部门最大深度
        pickerVal: [1, 5, -1]
    }

    componentDidMount = async () => {
        //TODO:
        await this.fetchDepData()

        // await this.fetchDataByDep(cookie.load('user').user_group_id)
        await this.fetchDataByDep()
        this.setState({
            pickerVal: this.setDefaultDep()
        });
    }

    setDefaultDep = () => {
        // 当前用户的用户组Id
        const { depData } = this.state;
        const groupId = Number(cookie.load('user').user_group_id);
        if (groupId) {
            const dep = depData.find(p => p.user_group_id === groupId)
            const allParents = [];
            const getAllParent = (obj) => {
                if (obj.group_parent_id !== 0) {
                    const parentDep = depData.find(p => p.user_group_id === obj.group_parent_id);
                    allParents.unshift(parentDep.user_group_id)
                    return getAllParent(parentDep)
                }
                else {
                    return obj;
                }
            }

            getAllParent(dep);
            return [...allParents, groupId, -1]
        }
    }


    // 查询部门
    fetchDepData = async () => {
        const result = await queryDepartmentByCompId(cookie.load('user') && cookie.load('user').user_company_id)
        if (result.code === 20000) {
            const { data } = result;
            const formatData = data.map(p => ({ label: p.group_name, value: p.user_group_id, parentId: p.group_parent_id }))
            const depTree = formatData.map(item => {
                item.children = [{ label: '全部', value: -1, children: [] }].concat(formatData.filter(p => p.parentId === item.value))
                return item;
            }).filter(p => p.parentId === 0)
            this.setState({
                depData: result.data,
                depTree,
                maxFloor: this.getMaxFloor(depTree)
            })
        }
    }


    // 获取树的深度
    getMaxFloor(treeData) {
        let max = 0
        function each(data, floor) {
            data.forEach(e => {
                e.floor = floor
                if (floor > max) {
                    max = floor
                }
                if (e.children.length > 0) {
                    each(e.children, floor + 1)
                }
            })
        }
        each(treeData, 1)
        return max
    }

    // 查询数据
    // fetch = async (taskState, groupIds = [cookie.load('user').user_group_id].join(',')) => {
        fetch = async (taskState, groupIds) => {
        const res = await findAllTask({ taskState, groupIds })
        if (res.code === 20000) {
            this.setState({
                listData: res.data
            })
        }
    }

    handleDetail = id => {
        this.props.history.push({ pathname: `/task/taskDetail/${id}` });
    }

    handleSubmit = () => {
        if (cookie.load('user').user_name) {
            this.props.depStore.initState()
            this.props.history.push({ pathname: `/task/taskAdd/0/0` });
        } else {
            Toast.info('请先实名认证', 2, null, false)
        }
    }

    handleChange = key => {
        const { tab } = this.state;
        tab.map(t => {
            t.checked = t.key === key;
            return t;
        });
        this.setState({
            tab,
        });
        if (key === 1) {
            this.fetch()
        }
        if (key === 2) {
            this.fetch(0)
        }
    }

    // 获取树的所有下级节点
    getChildrenByNode = (data) => {
        const childIds = [];
        const getIds = (data) => {
            return data.map(item => {
                if (item.children.length) {
                    childIds.push(item.value)
                    return getIds(item.children);
                }
                else {
                    childIds.push(item.value)
                    return item;
                }
            })
        }
        getIds(data);
        return [...data.map(item => item.value), ...childIds.filter(p => p !== -1)]
    }

    // 点击Picker确定时候
    handleOkBtnClick = (e) => {
        this.setState({ pickerVal: [...e] })
        const selected = e.splice(-2)[0];
        this.fetchDataByDep(selected)
        this.setState({
            pickerVisible: false
        })
    }

    // 根据depId 查询数据
    fetchDataByDep = (depId) => {
        const { depData } = this.state;
        const formatData = depData.map(p => ({ label: p.group_name, value: p.user_group_id, parentId: p.group_parent_id }))
        const depTree = formatData.map(item => {
            item.children = [{ label: '全部', value: -1, children: [] }].concat(formatData.filter(p => p.parentId === item.value))
            return item;
        }).filter(p => p.parentId === depId)
        const ids = Array.from(new Set(this.getChildrenByNode(depTree)));
        this.fetch('', [depId, ...ids].join(','));
    }

    // 项目跳转
    handleSkip = (e, item) => {
        e.stopPropagation();
        // 阻止与原生事件的冒泡
        e.nativeEvent.stopImmediatePropagation();
        this.props.history.push(`/project/details/${item.project_id}`)
    }


    render() {
        const { tab, listData, pickerVisible, depTree, maxFloor, pickerVal } = this.state;
        return (
            <div className='task_home'>
                <NavBar
                    mode="light"
                    icon={<Icon type="left" />}
                    rightContent={<span onClick={() => this.setState({ pickerVisible: true })}>切换部门</span>}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    {/* 任务定制仓 */}
                    <div className='task_chack'>
                        {
                            tab.map(x => (<div onClick={() => this.handleChange(x.key)} className={x.checked ? 'active' : ''} key={x.key}>{x.name}</div>))
                        }
                    </div>
                </NavBar>
                <div className='task_main'>
                    <div style={{ height: 2, background: '#f6f6f6' }}></div>
                    {
                        (listData || []).length ? listData.map(item => {
                            return <div className='task_info' key={item.task_id} onClick={() => this.handleDetail(item.task_id)}>
                                <div className='task_title'>
                                    <div>{item.task_title}</div>
                                    {
                                        item.task_state === 1 ? <div className='task_title_ok'>已完成</div> : item.task_state === 2 ? <div className='task_title_over'>超时完成</div> : <div className='task_title_no'>未完成</div>
                                    }
                                </div>
                                <div className='task_xm'>关联项目：{item.project_name === '全部' ? <span>{item.project_name}</span> : <span className='task_xm_active' onClick={e => this.handleSkip(e, item)}>{item.project_name}</span>}</div>
                                <div className='task_desc'>描述：<span>{str2HTML(item.task_description)}</span></div>
                                <div className='task_time'>发布时间：<span>{item.crearte_time ? moment(item.crearte_time).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}</span></div>
                                <div className='task_time'>完成时间：<span>{item.finish_time ? moment(item.finish_time).format('YYYY-MM-DD') : '暂未完成'}</span></div>
                                <div className='task_img'>
                                    <img key={item.publish_user_id} src={item.publish_user_avatar} alt='' />
                                    {
                                        item.joinUsers && item.joinUsers.length ? item.joinUsers.map(x => (<img key={x.user_id} src={x.user_avatar} alt='' />)) : ''
                                    }
                                </div>
                            </div>
                        }) : <div className='yet-not'>--暂无数据--</div>
                    }
                </div>
                <div className='task_submit' onClick={() => this.handleSubmit()}>发布</div>
                <Picker
                    visible={pickerVisible}
                    onDismiss={() => this.setState({ pickerVisible: false })}
                    data={depTree}
                    cols={maxFloor}

                    value={pickerVal}
                    onOk={e => this.handleOkBtnClick(e)}
                />
            </div>
        );
    }
}

export default index;