import React, { Component } from 'react'
import { NavBar, Icon, Button, Toast, ImagePicker, List, Picker, WhiteSpace } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { req_uploadEnclosureFile, req_addEnclosureFile } from './../../service/api'
import cookie from 'react-cookies'
import './fileUpload.less'

class fileUpload extends Component {
    state = {
        pc_content: '', // 验收信息
        files: [],//文件列表
        pe_status: '',
        pe_data: [
            {
                label: '文档',
                value: 1,
            },
            {
                label: '原型',
                value: 2,
            },
            {
                label: '效果图',
                value: 3,
            },
            {
                label: '服务器',
                value: 4,
            },
            {
                label: '手册',
                value: 5,
            },
            {
                label: 'ppt',
                value: 6,
            },
        ],
        formData: {},
        file_name: ''
    }

    componentDidMount = () => {

    }

    // 上传文件
    uploadImage = (e) => {
        const { files } = e.target;
        Toast.loading('上传中...', 50000)
        if (files && files.length) {
            let formData = new FormData();
            formData.append("file", files[0]);
            req_uploadEnclosureFile(formData).then(res => {
                if (res.code === 20000) {
                    Toast.success("上传成功", 1)
                    const formData = {
                        pe_projectid: this.props.match.params.id,
                        pe_filename: res.data.fileName,
                        pe_size: res.data.size,
                        pe_filepath: res.data.url
                    }
                    this.setState({
                        file_name: res.data.fileName,
                        formData
                    })

                } else {
                    Toast.fail(res.message, 1)
                }
            })
        }
    }

    submit = () => {
        const { formData, pe_status } = this.state
        if (pe_status == '') {
            return Toast.fail("请选择文件类型", 1)
        }
        formData.pe_status = pe_status.join(',')
        req_addEnclosureFile(formData).then(result => {
            if (result.code === 20000) {
                Toast.success(result.message, 1)
                setTimeout(() => {
                    this.props.history.goBack()
                }, 2000);
            } else {
                Toast.fail(result.message, 1)
            }
        })
    }


    render = () => {
        const { files, pe_status, pe_data, file_name } = this.state;
        return (
            <div style={{ height: "100vh", background: "#F5F5F5" }}>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    上传附件
                </NavBar>
                <WhiteSpace size="lg" />
                <Picker
                    data={pe_data}
                    value={pe_status}
                    cols={1}
                    onChange={(val) => {
                        this.setState({
                            pe_status: val
                        })
                    }}
                >
                    <List.Item arrow="horizontal">文件类型</List.Item>
                </Picker>
                <div className="upload_file">
                    {/* <input type='file' onChange={e => this.uploadImage(e)} /> */}
                    <div className="upload-wrap anticon" nv-file-drop="" uploader="uploader">
                        <input className="file-ele" type="file" file-model="image" name="image" nv-file-select="true" uploader="uploader" multiple onChange={e => this.uploadImage(e)} />
                        <div className="file-open"><em className="icon icon-upload"></em>
                            <i aria-label="图标: upload" className="anticon anticon-upload"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="upload" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg></i>
                            &nbsp;
                            上传</div>
                    </div>
                    
                    {
                        file_name?(<div style={{marginLeft:'10px'}}>
                            <i aria-label="图标: paper-clip" className="anticon anticon-paper-clip"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 0 0 174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg></i>
                            {file_name}
                        </div>):('')
                    }
                </div>

                <div style={{ width: "100%", position: "fixed", bottom: "10px", zIndex: "999" }}>
                    <Button type="primary" className="button-footer" onClick={() => {
                        this.submit()
                    }}>保存</Button>
                </div>
            </div>
        )
    }

}
export default fileUpload