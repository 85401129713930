import React, { Component } from 'react'
import { Tabs, SegmentedControl } from 'antd-mobile';
import { getList } from '../../service/api';
import './index.less'

const tabs = [
    { title: '黄淮学院', sub: '1' },
    { title: '驻职', sub: '2' },
    { title: '洛职', sub: '3' },
];

export default class Home extends Component {
    state = {
        state: 'all',
        selectedIndex: 0,
        data: [],
    }

    componentDidMount() {
        this.search()
    }

    search = () => {
        const { state } = this.state
        getList({ pageSize: 10000, state, treeNodeId: '00' }).then(res => {
            if (res.code === 20000) {
                if (state === "all") {
                    this.setState({
                        allLength: JSON.parse(res.data).resList.length,
                        onlineLength: JSON.parse(res.data).resList.filter(x => x.state === "1").length,
                        outlineLength: JSON.parse(res.data).resList.filter(x => x.state === "-1").length,
                        unkonwnLength: JSON.parse(res.data).resList.filter(x => x.state === "-").length,
                    })
                }
                this.setState({
                    data: JSON.parse(res.data).resList,
                })
            }
        })
    }
    render() {
        const { selectedIndex, allLength, onlineLength, outlineLength, unkonwnLength, data } = this.state
        return (
            <div>
                <Tabs
                    tabs={tabs}
                    initialPage={0}
                >
                    <div className="huanghuai">
                        <div className="huanghuai-tongji">
                            <div>总数:{allLength}台</div>
                            <div>上线:<span style={{ color: '#1890FF' }}>{onlineLength}</span>台</div>
                            <div>下线:<span style={{ color: '#F5222D' }}>{outlineLength}</span>台</div>
                            <div>未知:<span style={{ color: '#FBA63F' }}>{unkonwnLength}</span>台</div>
                        </div>
                        <SegmentedControl
                            style={{ marginBottom: 10 }}
                            values={['全部', '上线', '下线', '未知']}
                            selectedIndex={selectedIndex}
                            onChange={(e) => {
                                if (e.nativeEvent.selectedSegmentIndex === 0) {
                                    this.setState({
                                        state: 'all',
                                        selectedIndex: e.nativeEvent.selectedSegmentIndex
                                    }, () => {
                                        this.search()
                                    })
                                } else if (e.nativeEvent.selectedSegmentIndex === 2) {
                                    this.setState({
                                        state: -1,
                                        selectedIndex: e.nativeEvent.selectedSegmentIndex
                                    }, () => {
                                        this.search()
                                    })
                                } else if (e.nativeEvent.selectedSegmentIndex === 3) {
                                    this.setState({
                                        state: -2,
                                        selectedIndex: e.nativeEvent.selectedSegmentIndex
                                    }, () => {
                                        this.search()
                                    })
                                } else {
                                    this.setState({
                                        state: e.nativeEvent.selectedSegmentIndex,
                                        selectedIndex: e.nativeEvent.selectedSegmentIndex
                                    }, () => {
                                        this.search()
                                    })
                                }
                            }}
                        />
                        <table>
                            <colgroup>
                                <col style={{ width: '50%' }}></col>
                                <col style={{ width: '30%' }}></col>
                                <col style={{ width: '20%' }}></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>地址</th>
                                    <th>类型</th>
                                    <th>状态</th>
                                </tr>
                                {data.map((p, index) => <tr key={index}>
                                    <td>{p.name}</td>
                                    <td>{p.type}</td>
                                    <td>{(p.state === '1' && <div style={{ color: '#1890FF' }}>在线</div>) || (p.state === '-1' && <div style={{ color: '#F5222D' }}>下线</div>) || (p.state === '-' && <div style={{ color: '#FBA63F' }}>未知</div>)}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        第二部分
                    </div>
                    <div>
                        第三部分
                    </div>
                </Tabs>
            </div>
        )
    }
}
