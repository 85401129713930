import React, { Component } from 'react'
import './index.less'
import { isSignAuthorized, saveSignRecord } from './service';
import JSSDK from '../../utils/wechat';
import { inject, observer } from 'mobx-react';
import wx from 'weixin-js-sdk';
import Utils from './../../utils/utils';
import { getLocationInfoByLonAndLat, findAppAttendanceRecordList } from '../../service/api';
import moment from 'moment';
import { Toast, NavBar, Icon, Popover, List, DatePicker, Modal, Steps, WingBlank, WhiteSpace } from 'antd-mobile';

const Item = Popover.Item;
const Step = Steps.Step;

@inject('globalStore')
@observer
class ClockIn extends Component {
    state = {
        isRange: '', // 是否再范围内
        location: '',// 当前位置名称
        info: '', // 打卡位置信息
        lon: '',
        lat: '',
        recordList: [],
        isWork: true, // 是否上班打卡
        visible: false,
        date: '',
        popoverVisible: false,
        clockInRecord: [], // 打卡记录
        delay: true, // 打卡延时
        isData:{}
    }

    componentDidMount = async () => {
        // 查看是否有打卡权限
        this.isAuthority()
        // 获取当前位置信息
        // this.getLocation()
        //每隔一秒执行一次
        setInterval(this.getTime, 1000);
    }

    // 查看是否拥有签到权限
    isAuthority = async () => {
        const result = await isSignAuthorized();
        if (result.code === 20000) {
            // console.log(info.attendanceEntity, 'result')
            Toast.hide()
            this.setState({
                info: result.data,
                recordList: result.data
            })
            if (result.data.attendanceEntity) {
                this.getLocation();
            }
        }
    }

    compare=(property)=>{
        return function(a,b){
            var value1 = a[property];
            var value2 = b[property];
            return value1 - value2;
        }
    }

    getLocation = async () => {
        const { globalStore: { appId } } = this.props;
        await JSSDK.InitWxConfig(appId)
        window.wx.ready(() => {
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            window.wx.getLocation({
                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: async (res) => {
                    var lat = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                    var lon = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                    const result = await getLocationInfoByLonAndLat(lon, lat)
                    const { attendanceEntity: { latitude, longitude, attendance_scope }, attendanceRecordListEntities } = this.state.info;
                    const { attendanceEntity } = this.state.info
                    // const isData= attendanceEntity.find(val=>{
                    //     const mi = Utils.GetDistance(lat, lon, val.latitude, val.longitude);
                    //     return mi <= val.attendance_scope
                    // })


                    const isData= attendanceEntity.map(val=>{
                        const item = {...val}
                        item.mi = Utils.GetDistance(lat, lon, val.latitude, val.longitude);
                        item.state = item.mi <= val.attendance_scope?true:false
                        return item
                    })

                    // 判断是否有匹配的打卡配置
                    const isRange = isData.find(x=>x.state===true)
                    if(isRange){
                        var state = true
                        console.log(isRange)
                        this.setState({
                            isData:isRange,
                        })
                    }else{
                        const compareData=isData.sort(this.compare('mi'))
                        this.setState({
                            isData:compareData.length>1?compareData[0]:[],
                        })
                    }
                    

                    this.setState({
                        isRange: state,
                        location: result.regeocode.formatted_address,
                        recordList: attendanceRecordListEntities
                    }, () => {
                        const sign = attendanceRecordListEntities[attendanceRecordListEntities.length - 1];
                        this.setState({
                            isWork: (attendanceRecordListEntities.length === 0 || !!sign.off_duty_time)
                        })
                    })
                },
                fail: function (res) {
                    console.log(res)
                    Toast.fail("请打开你的GPS进行定位,然后刷新页面以获取地理位置", 3, null, false)
                }
            });
        });


        // wx.ready(async () => {
        //     const result = await JSSDK.getLocation()
        //     const { lat, lon } = result;
        //     const res = await getLocationInfoByLonAndLat(lon, lat)
        //     console.log(result, 'result')
        //     console.log(res, 'res')
        //     console.log(this.state.info, 'info')
        //     const { attendanceEntity: { latitude, longitude, attendance_scope }, attendanceRecordListEntities } = this.state.info;
        //     const mi = Utils.GetDistance(lat, lon, latitude, longitude);
        //     this.setState({
        //         isRange: mi <= attendance_scope,
        //         location: res.regeocode.formatted_address,
        //         recordList: attendanceRecordListEntities
        //     }, () => {
        //         const sign = attendanceRecordListEntities[attendanceRecordListEntities.length - 1];
        //         this.setState({
        //             isWork: (attendanceRecordListEntities.length === 0 || !!sign.off_duty_time)
        //         })
        //     })
        // })
    }

    /**
     * 点击签到按钮时候
     * @params type 类型 1 正常打卡 2 外勤打卡
     */
    handleSignInBtnClick = async (type) => {
        const { recordList, location, isWork, isData } = this.state;
        const sign = recordList[recordList.length - 1];
        const params = {}
        if (recordList.length === 0 || sign.off_duty_time) {
            // 有值的话 打上班卡
            params.be_onduty_time = moment().format('HH:mm');
            params.be_onduty_address = location;
            params.be_onduty_state = type
        }
        else {
            params.off_duty_time = moment().format('HH:mm')
            params.off_duty_address = location;
            params.off_duty_state = type;
            
        }
        params.attendance_id = isData && isData.attendance_id ? isData.attendance_id : ''
        this.setState({
            delay: false,
        })
        setTimeout(() => {
            this.setState({
                delay: true
            })
        }, 10000)
        const result = await saveSignRecord(params)
        if (result.code === 20000) {
            // Toast.show('打卡成功')
            // window.location.reload()
            Toast.success('打卡成功', 5)
            setTimeout(() => {
                this.isAuthority()
                this.getLocation()
            }, 1000)
            this.setState({
                isWork: !isWork
            })

            // 延时十秒

        }
    }

    // 点击打卡记录
    handleSelect = opt => {
        console.log(opt)
        this.fetchRecord()
        this.setState({
            visible: true,
            date: new Date(),
            popoverVisible: false,
        })
    }

    // 根据日期查询打卡记录
    fetchRecord = async date => {
        const res = await findAppAttendanceRecordList({ attendanceDate: date })
        if (res.code === 20000) {
            this.setState({
                clockInRecord: res.data
            })
            // Toast.info(res.message, 2, null, false)
        }
    }

    getTime = () => {
        var o = document.getElementById("inner");
        //新的日期对象
        var date = new Date();
        // var year = date.getFullYear();	//获取年份
        // var month = date.getMonth() + 1;	//获取月份
        // var day = date.getDate();	//获取日期
        var hour = date.getHours();		//获取小时
        var minutes = date.getMinutes();	//获取分钟
        var seconds = date.getSeconds();	//获取秒数
        // month = this.addZero(month);		//为月份调用补零函数并赋值
        // day = this.addZero(day);		//为日期调用补零函数并赋值
        hour = this.addZero(hour);		//为小时调用补零函数并赋值
        minutes = this.addZero(minutes);		//为分钟调用补零函数并赋值
        seconds = this.addZero(seconds);		//为秒调用补零函数并赋值
        //把日期时间放到页面中
        // var time = year + "年" + month + "月" + day + "日" + "&nbsp;" + hour + "：" + minutes + "：" + seconds;
        var time = hour + ":" + minutes + ":" + seconds;
        if (o) {
            o.innerHTML = time;
        }
    }

    //不足十时补 0 
    addZero = (az) => {
        if (az < 10) {
            az = "0" + az;
        }
        return az;
    }

    render() {
        const { isRange, location, info, isWork, visible, date, popoverVisible, clockInRecord, delay } = this.state;
        return (
            <div>
                {
                    info ? <div className={'main'}>
                        <NavBar
                            mode="mode"
                            icon={<Icon type="left" />}
                            onLeftClick={() => this.props.history.goBack()}
                            rightContent={[
                                info.attendanceEntity && <Popover mask
                                    visible={popoverVisible}
                                    overlay={[
                                        (<Item key="1" value={1}><img src={require('../../images/11.png')} alt='' /><span>打卡记录</span></Item>),
                                    ]}
                                    onSelect={this.handleSelect}
                                >
                                    <Icon type="ellipsis" />
                                </Popover>
                            ]}
                        >
                            打卡
                    </NavBar>
                        {
                            info.attendanceEntity ? (
                                <>
                                    <div className={'location'}>
                                        {
                                            isRange !== '' ? (isRange ? <p>你已在签到范围内</p> : <p style={{ color: 'red' }}>不在签到范围</p>) : (<p>正在获取位置信息...</p>)
                                        }
                                        <p style={{ color: `${!isRange ? 'red' : 'green'}` }}>
                                            {location}
                                        </p>
                                    </div>
                                    {
                                        isRange ?
                                            (
                                                <div className={'signIn'} onClick={() => delay ? this.handleSignInBtnClick(1) : Toast.show('请十秒后再次打卡！')}>
                                                    <div className='punch_btn'>
                                                        <div id='inner'></div>
                                                        <span>{isWork ? '上班打卡' : '下班打卡'}</span>
                                                    </div>
                                                </div>
                                            ) :
                                            (
                                                <div className={'signIn'} onClick={() => delay ? this.handleSignInBtnClick(2) : Toast.show('请十秒后再次打卡！')}>
                                                    <div className='punch_btn'>
                                                        <div id='inner'></div>
                                                        <span>外勤打卡</span>
                                                    </div>
                                                </div>
                                            )

                                    }
                                </>
                            ) : (
                                    <div className={'location'}>
                                        <p style={{ color: `${!isRange ? 'red' : 'green'}` }}>
                                            无权访问
                                    </p>
                                    </div>
                                )
                        }
                        <Modal
                            visible={visible}
                            transparent
                            className='search_modal clockin_modal'
                            onClose={() => {
                                this.setState({
                                    visible: false
                                })
                            }}
                        >
                            <NavBar
                                mode="mode"
                                icon={<Icon type="left" />}
                                onLeftClick={() => this.setState({ visible: false })}
                            >
                                打卡记录
                    </NavBar>
                            <div style={{ position: 'fixed', width: '100%', marginTop: 40, zIndex: 999, }}>
                                <List>
                                    <DatePicker
                                        mode="date"
                                        title="日期"
                                        extra="打卡日期"
                                        value={date}
                                        onChange={date => {
                                            this.fetchRecord(moment(date).format('YYYY-MM-DD'))
                                            this.setState({ date })
                                        }}
                                    >
                                        <List.Item arrow="horizontal">打卡日期</List.Item>
                                    </DatePicker>
                                </List>
                            </div>
                            <div style={{ marginTop: 84, textAlign: 'left', overflow: 'scroll' }}>
                                <div className='clockin_role'>
                                    <div>上下班打卡</div>
                                    <div>打卡规则：{(clockInRecord || []).length ? clockInRecord[0].attendance_name + '打卡' : '暂无'}</div>
                                </div>
                                <WhiteSpace />
                                <WhiteSpace />
                                <WingBlank>
                                    <div className='clockin_role_content'>
                                        {
                                            (clockInRecord || []).map((item, key) => {
                                                return <Steps status='wait' key={key}>
                                                    <Step title={<div>上班</div>} description={<div className='clockin_role_state'>{item.be_onduty_state === 0 ? <span style={{ color: 'red' }}>缺卡</span> : item.be_onduty_state === 1 ? '正常打卡' : '外勤打卡'}</div>} icon={<div className='clockin_role_time'>{item.be_onduty_time}</div>} />
                                                    {
                                                        item.off_duty_time && <Step title={<div>下班</div>} description={<div className='clockin_role_state'>{item.off_duty_state === 0 ? <span style={{ color: 'red' }}>缺卡</span> : item.off_duty_state === 1 ? '正常打卡' : '外勤打卡'}</div>} icon={<div className='clockin_role_time'>{item.off_duty_time}</div>} />
                                                    }
                                                </Steps>
                                            })
                                        }
                                    </div>
                                </WingBlank>
                            </div>
                        </Modal>
                    </div> : Toast.info('加载中...')
                }
            </div>
        )
    }
}

export default ClockIn;