import React, { Component } from 'react';
import { NavBar, Icon, List, Picker, InputItem, TextareaItem, WhiteSpace, WingBlank, Button, Modal, DatePicker } from 'antd-mobile'
import cookie from 'react-cookies';
import { inject, observer } from 'mobx-react';
@inject('depStore')
@observer
class taskAdd extends Component {

    state = {
        checkedData: []
    }

    componentDidMount = async () => {
        const { queryDepData, fetchProject, fetchMember, initData } = this.props.depStore;
        queryDepData(cookie.load('user').user_company_id);
        await fetchProject();
        await fetchMember();
        const checkedData = await initData(Number(this.props.match.params.id))
        this.setState({
            checkedData: (checkedData && checkedData.split(',').map(item => Number(item))) || []
        })

    }

    // 添加成员信息弹框
    handleMember = () => {
        const { presentMember, publishId, fetchMember, setVisible } = this.props.depStore;
        fetchMember(publishId + (publishId && presentMember && presentMember.length ? ',' : '') + presentMember.map(x => x.user_id).join(','))
        setVisible(true)
    }


    render() {
        const { checkedData, depData, title, desc, project, porjectId, member, personnelVisible, presentMember, visible, remainMember, enclosure, publishId, startTime, endTime, emergencyStatus } = this.props.depStore;
        const { handleSubmit, setPorjectId, setTitle, setPersonnelVisible, setDesc, setStartTime, setEndTime, setEmergencyStatus, setVisible, handleAddMemberOk, handleClose, onChangeEnclosure, handleEditMomberOk, handleDelMember } = this.props.depStore;
        const groupNames = (checkedData.length ? checkedData : this.state.checkedData).map(id => depData.find(item => item.user_group_id === id) && depData.find(item => item.user_group_id === id).group_name)

        const emergency = [
            {label: '轻度', value: 1},
            {label: '中度', value: 2},
            {label: '重度', value: 3},
            {label: '加急', value: 4},
        ]

        return (
            <div>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    {Number(this.props.match.params.id) ? '编辑任务' : '新增任务'}
                </NavBar>
                <List>
                    <Picker
                        extra="请选择关联项目"
                        data={project}
                        value={porjectId}
                        cols={1}
                        disabled={Number(this.props.match.params.status)}
                        onChange={(val) => {
                            setPorjectId(val.filter(p => p));
                        }}
                    >
                        <List.Item arrow="horizontal">关联项目</List.Item>
                    </Picker>
                    <InputItem placeholder="请输入标题" disabled={Number(this.props.match.params.status)} value={title} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                        setTitle(e)
                    }}>
                        标题
                    </InputItem>
                    <InputItem placeholder="请选择成员" disabled={Number(this.props.match.params.status)} value={member} style={{ textAlign: 'right', paddingRight: 8 }} onFocus={(e) => {
                        setPersonnelVisible(true)
                    }}>
                        项目成员
                    </InputItem>
                    <InputItem placeholder="请选择部门" onFocus={() => !(Number(this.props.match.params.status)) && this.props.history.push('/task/tree/0')} value={groupNames && groupNames.join(',')}  style={{ textAlign: 'right', paddingRight: 8 }} >
                        选择部门
                    </InputItem>
                    <DatePicker
                        mode="date"
                        title="开始时间"
                        extra="开始时间"
                        value={startTime}
                        disabled={Number(this.props.match.params.status)}
                        onChange={date => setStartTime(date)}
                    >
                        <List.Item arrow="horizontal">计划开始时间</List.Item>
                    </DatePicker>
                    <DatePicker
                        mode="date"
                        title="结束时间"
                        extra="结束时间"
                        minDate={startTime}
                        value={endTime}
                        disabled={Number(this.props.match.params.status)}
                        onChange={date => setEndTime(date)}
                    >
                        <List.Item arrow="horizontal">计划结束时间</List.Item>
                    </DatePicker>
                    <Picker
                        extra="请选择任务紧急程度"
                        data={emergency}
                        value={emergencyStatus}
                        cols={1}
                        disabled={Number(this.props.match.params.status)}
                        onChange={(val) => {
                            setEmergencyStatus(val.filter(p => p));
                        }}
                    >
                        <List.Item arrow="horizontal">任务紧急程度</List.Item>
                    </Picker>
                </List>
                <List renderHeader={() => <div style={{ color: '#000' }}>具体描述</div>}>
                    <TextareaItem
                        placeholder="请输入你的描述内容"
                        value={desc}
                        disabled={Number(this.props.match.params.status)}
                        onChange={(e) => {
                            setDesc(e)
                        }}
                        rows={5}
                        count={200}
                    />
                </List>
                <div className='taskAdd_enclosure'>
                    <div className='taskAdd_enclosure_file'>

                        {
                            Number(this.props.match.params.status) ? '' :
                                <div className='taskAdd_file'>
                                    <div onClick={() => document.getElementById('upload').click()} style={{ background: '#4aa14a', color: '#fff', padding: '3px 5px', borderRadius: '5px' }}>上传附件</div>
                                    <input type='file' id='upload' onChange={e => onChangeEnclosure(e)} hidden />
                                </div>
                        }
                    </div>
                    {
                        (enclosure || []).map(x => {
                            return <div key={x.url}>{x.fileName.length > 24 ? x.fileName.substr(0, 24) + "..." : x.fileName}</div>
                        })
                    }
                </div>
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                {
                    Number(this.props.match.params.status) ? '' :
                        <WingBlank size="lg">
                            <Button type="primary" onClick={() => handleSubmit(this.props.match.params.id, (checkedData.length ? checkedData : this.state.checkedData), () => this.props.history.push('/task/index'))}>提交</Button>
                        </WingBlank>
                }
                <WhiteSpace size="lg" />
                <Modal
                    visible={personnelVisible}
                    transparent
                    className='search_modal'
                    onClose={() => {

                        setPersonnelVisible(false)

                    }}
                >
                    <NavBar
                        mode='mode'
                        icon={<Icon type='left' />}
                        onLeftClick={() => setPersonnelVisible(false)}
                        rightContent={<div onClick={handleEditMomberOk}>确定</div>}
                    >
                        人员结构管理
                    </NavBar>
                    <div className='personnel_people'>
                        {
                            (presentMember || []).map(item => {
                                return <div className='personnel_info' key={item.user_id}>
                                    <div className='member_info'>
                                        <div><img src={item.user_avatar} alt='' /></div>
                                        <span >{item.user_name}</span>
                                    </div>
                                    {
                                        cookie.load('user').user_id !== publishId && Number(this.props.match.params.id) ? '' :
                                            <div className='personnel_image' onClick={() => handleDelMember(item.user_id)}>
                                                <img src={require('../../images/28.png')} alt='' />
                                            </div>
                                    }
                                </div>
                            })
                        }
                        <div className='personnel_addImg'>
                            <div className='personnel_img' onClick={this.handleMember}>
                                <img src={require('../../images/27.png')} alt='' />
                            </div>
                            <span>添加成员</span>
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={visible}
                    transparent
                    className='search_modal'
                    onClose={() => setVisible(false)}
                >
                    <NavBar
                        mode='mode'
                        icon={<Icon type='left' />}
                        onLeftClick={() => setVisible(false)}
                        rightContent={<div onClick={handleAddMemberOk}>确定</div>}
                    >
                        选择计划成员
                    </NavBar>
                    <div>
                        {
                            (remainMember || []).map(item => {
                                return <div key={item.user_group_id} className='user_main'>
                                    <div className='user_group' onClick={() => handleClose(item.user_group_id)}>
                                        {
                                            item.checked ? <img style={{ marginRight: 5 }} src={require('../../images/open.jpg')} alt='' /> :
                                                <img style={{ marginRight: 5 }} src={require('../../images/close.jpg')} alt='' />
                                        }
                                        <span>{item.group_name}</span>
                                    </div>
                                    <div className={`user_info ${item.checked ? '' : 'user_active'}`}>
                                        {
                                            (item.sysUserEntities || []).map(i => {
                                                return <label
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '5px 30px'
                                                    }}
                                                    key={i.user_id}
                                                >
                                                    <input
                                                        name="staff"
                                                        type="checkbox"
                                                        value={i.user_id}
                                                    />
                                                    <div className='member_info'>
                                                        <div><img src={i.user_avatar} alt='' /></div>
                                                        <span >{i.user_name}</span>
                                                    </div>
                                                </label>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

export default taskAdd;