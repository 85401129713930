/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { findRepairAllOrder, findUserOrder, queryModuleData, orderReceiving, findRepairUser, redeployOrder, req_upload, operationUserOrder, findShareUser, findAppointOrder, phoneDescRepair, findOrderType, getLocationInfoByLonAndLat, StatisticalQuantity } from '../../service/api'
import { NavBar, Icon, Tabs, Badge, Modal, Grid, Button, Popover, Toast, SearchBar, List, TextareaItem, Radio, Picker, InputItem } from 'antd-mobile'
import moment from 'moment'
import { observer, inject } from 'mobx-react'
import { Gallery, GalleryDelete, Uploader, Cell, CellBody } from 'react-weui';
import IP from '../../config/config'
import './index.less'
import JSSDK from '../../utils/wechat'
import wx from 'weixin-js-sdk';
import cookie from 'react-cookies'

const Item = Popover.Item;
const RadioItem = Radio.RadioItem;

@inject('globalStore')
@observer
class index extends Component {
    state = {
        funcData: [], // 功能
        workOrderData: [], // 工单
        workData: [], // 未接单
        index: 0,
        repairType: null,
        pitchOn: false,
        flag: 1,
        visible: false,
        iconData: [],
        popoverVisible: false,
        maintainVisible: false,
        userData: [], // 维修工
        orderId: null, // 工单id
        finishVisible: false,
        urls: [],
        gallery: false,
        demoFiles: [],
        problems: '', // 完成描述
        evaluateVisible: false,
        evaluates: '', // 评价描述
        satisfaction: 1, // 满意度
        shareVisible: false,
        userList: [], // 分享用户
        appointVisible: false,
        describeVisible: false,
        describes: '', // 报修描述
        orderData:[], // 工单类型
        orderTypeId:'', // 工单类别ID
        address:'',
        lat:'',
        lon:'',
        countData:'',
    }

    componentDidMount = async () => {
        // const funcData = this.props.globalStore.funcData;
        this.findCount();
        const funcData = (cookie.load('funcData') || []);
        this.setState({
            funcData
        })
        
        if (funcData && funcData.length) {
            if (funcData.find(x => Number(x.function_id) === 2)) {
                this.fetchMaintainData(0);
            } else {
                this.fetchOneselfData(0);
            }
        }
    }

    // 工单数量
    findCount=()=>{
        StatisticalQuantity().then(res=>{
            if(res.code === 20000){
                this.setState({
                    countData:{
                        alreadyOrder:String(res.data.alreadyOrder),
                        completeOrder: String(res.data.completeOrder),
                        evaluate: String(res.data.evaluate),
                        waitingOrder: String(res.data.waitingOrder)
                    }
                })
            }else{
                Toast.fail(res.message)
            }
        })
    }
    
    // 工单类型
    orderType=async ()=>{
        const res = await findOrderType()
        if(res.code===20000){
            const list=res.data.map(v=>{
                const a={
                    value:v.order_type_id,
                    label:v.type_name
                }
                return a
            })
            this.setState({
                orderData:list
            })
        }else{
            Toast.fail(res.message)
        }
    }

    // 初始化微信配置
    InitWechatConfig = () => {
        const { globalStore: { appId } } = this.props;
        return JSSDK.InitWxConfig(appId)
    }


    // 获取位置
    getWeChatLocation = async () => {

        await this.InitWechatConfig()
        wx.ready(async e => {
            const res = await JSSDK.getLocation()
            const { lon, lat } = res;
            const result = await getLocationInfoByLonAndLat(lon, lat)
            if (result.info === 'OK') {
                console.log(result,'result')
                this.setState({
                    address: result.regeocode.formatted_address,
                    lat,
                    lon
                })
            }
        })
    }

    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    // 手机端查询待指派工单
    fetchAppoint = async () => {
        const res = await findAppointOrder()
        if (res.code === 20000) {
            this.setState({
                workOrderData: res.data
            })
        }
    }

    // 当Tab页发生改变的时候
    handleTabChange = (tab, index) => {
        console.log(index)
        this.findCount();
        const { funcData } = this.state;
        this.setState({
            index
        })
        if(index !== 4) {
            if (funcData.find(x => Number(x.function_id) === 2)) {
                this.fetchMaintainData(index)
            } else {
                this.fetchOneselfData(index)
            }
        } else {
            this.fetchAppoint()
        }
    }

    // 手机端维修人员查询工单
    fetchMaintainData = async (orderStatus) => {
        const { repairType, flag } = this.state;
        const res = await findRepairAllOrder({ orderStatus, repairType, flag })
        if (res.code === 20000) {
            this.setState({
                workOrderData: res.data
            })
            if (!orderStatus) {
                this.setState({
                    workData: res.data
                })
            }
        }
    }

    // 手机端查询用户自己的报修订单和共享的
    fetchOneselfData = async (orderStatus) => {
        const { repairType } = this.state;
        const res = await findUserOrder({ orderStatus, repairType });
        if (res.code === 20000) {
            this.setState({
                workOrderData: res.data
            })
            if (!orderStatus) {
                this.setState({
                    workData: res.data
                })
            }
        }
    }

    // 选择工单状态
    handlePitchOn = (index) => {
        if (index === 1) {
            this.setState({
                pitchOn: false,
                flag: 1,
                index: 0,
                repairType: null,
            }, () => { this.fetchMaintainData(0) })
        }
        if (index === 2) {
            this.setState({
                pitchOn: true,
                flag: 2,
                index: 0,
                repairType: null,
            }, () => { this.fetchMaintainData(0) })
        }
    }

    // 弹窗
    handleModal = async () => {
        const { funcData } = this.state;
        const res = await queryModuleData()
        if (res.code === 20000) {
            const modules = res.data.filter(x => (x.module_url === '/user/repairs' || x.module_url === '/staff/repairs'))
            if (modules.length) {
                if ((funcData || []).find(x => Number(x.function_id) === 1)) {
                    const array = [
                        { icon: '01.png', name: '工单报修', url: '/action/index' },
                        { icon: '02.png', name: '电话报修', url: '/phone/index' },
                        { icon: '03.png', name: '语音报修', url: '/voice/index' },
                    ]
                    this.setState({
                        iconData: array.map((x, index) => { //图标按钮
                            return {
                                icon: require(`../../images/${x.icon}`),
                                text: x.name,
                                id: index + 1,
                                url: x.url,
                            }
                        }),
                        visible: true,
                    })
                } else {
                    Toast.info('用户暂无报修功能', 2, null, false)
                }
            }
        }
    }

    // 报修
    handleRepairs = e => {
        this.props.history.push({ pathname: e.url })
    }

    // 选中气泡中的某一个报修状态
    handleSelect = (opt) => {
        const { funcData, index } = this.state;
        this.setState({
            repairType: opt.props.value,
            popoverVisible: false,
        }, () => {
            if (funcData.find(x => Number(x.function_id) === 2)) {
                this.fetchMaintainData(index);
            } else {
                this.fetchOneselfData(index);
            }
        });
    };

    // 接单
    handleOrderReceiving = async orderId => {
        const res = await orderReceiving({ orderId, flag: 1 })
        if (res.code === 20000) {
            this.fetchMaintainData(0);
            Toast.info(res.message, 2, null, false)
        }
    }

    // 防抖
    debounce(fn) {
        var timer;
        var _self = fn
        return function () {
            clearTimeout(timer)
            var args = arguments // fn所需要的参数
            var _me = this // 当前的this
            timer = setTimeout(function () {
                _self.call(_me, args[0])
            }, 800)
        }
    }

    // 查询维修工
    fetchMaintainer = async val => {
        const res = await findRepairUser({ companyId: cookie.load('user').user_company_id, userName: val })
        if (res.code === 20000) {
            this.setState({
                userData: res.data,
            })
        }
    }

    // 派单弹框
    handleSendOrders = orderId => {
        this.fetchMaintainer()
        this.setState({
            maintainVisible: true,
            orderId
        })
    }

    // 派单
    handleSubmit = async item => {
        const { orderId } = this.state;
        const res = await redeployOrder({ orderId, flag: 1, workerId: item.user_id, workerName: item.user_name })
        if (res.code === 20000) {
            this.fetchMaintainData(1);
            this.setState({
                index: 1,
                maintainVisible: false,
            })
            Toast.info(res.message, 2, null, false)
        }
    }

    // 完成弹框
    handleFinish = orderId => {
        this.orderType();
        this.getWeChatLocation();
        this.setState({
            orderId,
            finishVisible: true,
        })
    }

    // 完成
    handleOk = async () => {
        const { problems, urls, orderId,orderTypeId,address,lat,lon } = this.state;
        if(urls.join(',') && orderTypeId && address){
            
        }else{
            Toast.fail("请完善信息")
            return false
        }
        const res = await orderReceiving({ orderId, flag: 2, desc: problems, urls: urls.join(','), orderTypeId:orderTypeId.join(','),address,longitude:lon,latitude:lat})
        if (res.code === 20000) {
            this.fetchMaintainData(1);
            this.setState({
                index: 1,
                finishVisible: false,
            })
            Toast.info(res.message, 2, null, false)
        }
    }

    // 评价弹框
    handleEvaluate = orderId => {
        this.setState({
            orderId,
            evaluateVisible: true,
        })
    }

    // 满意度
    onChangeSatisfaction = val => {
        this.setState({
            satisfaction: val
        })
    }

    // 评价
    handleSatisfactionOk = async () => {
        const { satisfaction, evaluates, orderId } = this.state;
        const res = await operationUserOrder({ orderId, flag: 3, commentRank: satisfaction, comment: evaluates })
        if (res.code === 20000) {
            this.fetchMaintainData(2);
            this.setState({
                index: 2,
                evaluateVisible: false,
            })
            Toast.info(res.message, 2, null, false)
        }
    }

    // 查询用户
    fetchUser = async val => {
        const res = await findShareUser({ userName: val })
        if (res.code === 20000) {
            console.log(res.data)
            this.setState({
                userList: res.data,
            })
        }
    }

    // 分享弹框
    handleShare = orderId => {
        this.fetchUser();
        this.setState({
            orderId,
            shareVisible: true
        })
    }

    // 分享
    handleShareSubmit = async item => {
        const { orderId } = this.state;
        const res = await operationUserOrder({ orderId, flag: 2, userIds: item.user_id })
        if (res.code === 20000) {
            this.setState({
                shareVisible: false,
            })
            Toast.info(res.message, 2, null, false)
        }
    }

    // 指派弹窗
    handleAppoint = async orderId => {
        this.fetchMaintainer()
        this.setState({
            appointVisible: true,
            orderId
        })
    }

    // 指派
    handleAppointSubmit = async item => {
        const { orderId } = this.state;
        const res = await redeployOrder({ orderId, flag: 2, workerId: item.user_id, workerName: item.user_name })
        if (res.code === 20000) {
            this.fetchAppoint();
            this.setState({
                index: 4,
                appointVisible: false,
            })
            Toast.info(res.message, 2, null, false)
        }
    }

    // 描述弹框
    handleDescribe = orderId => {
        this.setState({
            describeVisible: true,
            orderId
        })
    }

    // 描述确定
    handleDescribeOk = async () => {
        const { describes, orderId } = this.state;
        const res = await phoneDescRepair({ orderId, desc: describes })
        if (res.code === 20000) {
            this.fetchMaintainData(1);
            this.setState({
                index: 1,
                describeVisible: false,
            })
            Toast.info(res.message, 2, null, false)
        }
    }

    // 详情
    handleInfo = id => {
        this.props.history.push({pathname: `/detail/index/${id}`})
    }

    render() {
        const { workOrderData, workData, funcData, index, pitchOn, visible, iconData, popoverVisible, userData, maintainVisible, finishVisible, flag, evaluateVisible, satisfaction, shareVisible, userList, appointVisible, describeVisible, orderData, address, countData } = this.state;
        const tabs = [
            { title: <Badge text={countData && countData.waitingOrder}>待接单</Badge> },
            { title: <Badge text={countData && countData.alreadyOrder}>已接单</Badge> },
            { title: <Badge text={countData && countData.completeOrder}>已完工</Badge> },
            { title: <Badge text={countData && countData.evaluate}>已评价</Badge> },
            funcData.find(x => Number(x.function_id) === 5) && { title: <Badge>代指派</Badge> }
        ];
        const satisfactions = [
            { value: 1, label: '好评' },
            { value: 2, label: '中评' },
            { value: 3, label: '差评' },
        ];

        return (
            <div className='work_order'>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={
                        <Popover mask
                            visible={popoverVisible}
                            overlay={[
                                (<Item key="1" value={1}><img src={require('../../images/11.png')} alt='' /><span>填单报修</span></Item>),
                                (<Item key="2" value={2}><img src={require('../../images/12.png')} alt='' /><span>语音报修</span></Item>),
                                (<Item key="3" value={3}><img src={require('../../images/13.png')} alt='' /><span>电话报修</span></Item>),
                                (<Item key="4" value={4}><img src={require('../../images/14.png')} alt='' /><span>用户报修</span></Item>),
                                (<Item key="5" value={5}><img src={require('../../images/15.png')} alt='' /><span>员工报修</span></Item>),
                            ]}
                            onSelect={this.handleSelect}
                        >
                            <Icon type="ellipsis" />
                        </Popover>
                    }
                >
                    全部工单
                </NavBar>
                <Tabs tabs={tabs.filter(x => x)}
                    initialPage={0}
                    page={index}
                    onChange={(tab, index) => this.handleTabChange(tab, index)}
                >
                    <div>
                        {
                            index === 0 && <div className='hint'>工单2小时内未接单自动推送协调指派</div>
                        }
                        {
                            (workOrderData || []).map(item => {
                                return <div key={item.order_id} className='list_info'>
                                    <div onClick={() => this.handleInfo(item.order_id)}>
                                        <div className='list_info_num'>
                                            <div className='info_num'>
                                                <img src={require('../../images/05.png')} alt='' />
                                                <span>维修单号：{item.order_no}</span>
                                            </div>
                                            <span className='info_time'>{moment(item.create_time).format('YYYY-MM-DD HH:mm').substr(11, 5)}</span>
                                        </div>
                                        <div className='info_names'>用户姓名：<span>{item.bx_nick}</span></div>
                                        {
                                            item.order_desc && <div className='info_desc'>描述：<span>{item.order_desc && item.order_desc.length > 40 ? item.order_desc.substr(0, 38) + '...' : item.order_desc}</span></div>
                                        }
                                        {
                                            item.repair_type === 2 && <div className='info_audio'><span>语音：</span><audio controls="controls" src={`${IP.host + item.media_url}`}>
                                                你的浏览器版本暂不支持音频播放
                                        </audio></div>
                                        }
                                    </div>
                                    <div className='info_status'>
                                        <div className='status_content'>
                                            <span>{item.bx_source === 1 ? '普通用户' : '内部员工'}</span>
                                            <span>{item.repair_type === 1 ? '填单报修' : item.repair_type === 2 ? '语音报修' : '电话报修'}</span>
                                            <span>{item.bx_phone}</span>
                                        </div>
                                        {
                                            funcData.find(x => Number(x.function_id) === 2) && index === 0 && flag === 1 && <div className='info_btn'>
                                                <img onClick={() => this.handleOrderReceiving(item.order_id)} src={require('../../images/16.png')} alt='' />
                                            </div>
                                        }
                                        {
                                            funcData.find(x => Number(x.function_id) === 2) && index === 1 && flag === 1 && 
                                            (
                                                item.repair_type === 3 && (!item.order_desc) ? <div className='info_btn'>
                                                    {
                                                        funcData.find(x => Number(x.function_id) === 3) && 
                                                        <img onClick={() => this.handleDescribe(item.order_id)} src={require('../../images/21.png')} alt='' />
                                                    }
                                                </div> : <div className='info_btn'>
                                                    {
                                                        funcData.find(x => Number(x.function_id) === 6) && 
                                                        <img style={{ marginRight: 10 }} onClick={() => this.handleSendOrders(item.order_id)} src={require('../../images/17.png')} alt='' />
                                                    }
                                                    {
                                                        funcData.find(x => Number(x.function_id) === 3) && 
                                                        <img onClick={() => this.handleFinish(item.order_id)} src={require('../../images/18.png')} alt='' />
                                                    }
                                                </div>
                                            )
                                            
                                        }
                                        {
                                            ((funcData.find(x => Number(x.function_id) === 2) && index === 2 && flag === 2) || ((!funcData.find(x => Number(x.function_id) === 2)) && index === 2))
                                            && <div className='info_btn'>
                                                {
                                                    funcData.find(x => Number(x.function_id) === 4) && 
                                                    <img style={{ marginRight: 10 }} onClick={() => this.handleEvaluate(item.order_id)} src={require('../../images/19.png')} alt='' />
                                                }
                                            </div>
                                        }
                                        {
                                            ((funcData.find(x => Number(x.function_id) === 2) && [0, 1].includes(index) && flag === 2) || ((!(funcData.find(x => Number(x.function_id) === 2))) && [0, 1].includes(index)))
                                            && <div className='info_btn'>
                                                {
                                                    funcData.find(x => Number(x.function_id) === 7) && 
                                                    <img onClick={() => this.handleShare(item.order_id)} src={require('../../images/20.png')} alt='' />
                                                }
                                            </div>
                                        }
                                        {
                                            funcData.find(x => Number(x.function_id) === 5) && index === 4 && <div className='info_btn'>
                                            <img onClick={() => this.handleAppoint(item.order_id)} src={require('../../images/17.png')} alt='' />
                                        </div>
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Tabs>
                <Modal
                    popup
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    animationType="slide-up"
                    className='modal_repairs'
                >
                    <div className='modal_repairs_info'>
                        <div className='modal_repairs_type'>
                            <span>报修方式</span>
                            <span>数量：3</span>
                        </div>
                        <Grid data={iconData} hasLine={false} columnNum={4} onClick={e => this.handleRepairs(e)} />
                        <Button onClick={() => this.setState({ visible: false })}>取消</Button>
                    </div>
                </Modal>
                <Modal
                    visible={maintainVisible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            maintainVisible: false
                        })
                    }}
                >
                    <div style={{ position: 'fixed', width: '100%' }}>
                        <SearchBar
                            placeholder="请输入维修工姓名"
                            ref={ref => this.manualFocusInst = ref}
                            onCancel={() => this.setState({ maintainVisible: false })}
                            onChange={this.debounce((val) => {
                                this.fetchMaintainer(val)
                            })}
                            onSubmit={(e) => this.fetchMaintainer(e)}
                        />
                    </div>
                    <div style={{ marginTop: 54, padding: '0 18px' }}>
                        {
                            userData.map(item => {
                                return <div key={item.user_id} className='modal_content' onClick={() => this.handleSubmit(item)}>
                                    <a style={{ display: 'flex' }} href="#">
                                        <div className='modal_img'>
                                            {
                                                item.user_avatar ? <img src={item.user_avatar} alt='' /> :
                                                    <img src={require("../../images/04.png")} alt='' />
                                            }
                                        </div>
                                        <div className='modal_info'>
                                            <div className='modal_info_content'>
                                                <span className='info_name'>{item.user_name}</span>&nbsp;&nbsp;
                                                <span className='info_nick'>昵称：{item.user_nick.length > 10 ? item.user_nick.substr(0, 10) + '...' : item.user_nick}</span>
                                            </div>
                                            <div className='modal_info_phone'>{item.user_phone}</div>
                                        </div>
                                    </a>
                                </div>
                            })
                        }
                    </div>
                </Modal>
                <Modal
                    visible={finishVisible}
                    onClose={() => this.setState({ finishVisible: false })}
                    transparent
                    className='search_modal'
                >
                    {this.renderGallery()}
                    <div>
                        <Picker data={orderData} cols={1} className="forss" value={this.state.orderTypeId} onChange={(val)=>{
                            this.setState({
                                orderTypeId:val
                            })
                        }}>
                            <List.Item arrow="horizontal">工单类别：</List.Item>
                        </Picker>
                        <List>
                            <InputItem placeholder="请输入位置信息" disabled value={address} style={{ textAlign: 'right', paddingRight: 8 }}>
                            位置信息
                        </InputItem>
                        </List>
                        <List renderHeader={() => <div style={{ textAlign: 'left' }}>报修完成描述：</div>}>
                            <TextareaItem
                                placeholder="请输入你的报修完成描述"
                                onChange={(e) => {
                                    this.setState({
                                        problems: e
                                    })
                                }}
                                rows={8}
                                count={200}
                            />
                        </List>
                        <Cell>
                            <CellBody>
                                <Uploader
                                    title="图片上传"
                                    maxCount={4}
                                    files={this.state.demoFiles}
                                    onError={msg => alert(msg)}
                                    onChange={(file) => {
                                        let newFiles = [...this.state.demoFiles, { url: file.data }];
                                        let files = file.nativeFile
                                        this.setState({
                                            demoFiles: newFiles
                                        });
                                        Toast.loading('图片上传中', 0)
                                        let formData = new FormData();
                                        formData.append("multipartFile", files);
                                        req_upload(formData).then(res => {
                                            this.setState({
                                                urls: this.state.urls.concat(res.data)
                                            }, () => {
                                                Toast.hide()
                                            })
                                        })
                                    }}
                                    onFileClick={
                                        (e, file, i) => {
                                            this.setState({
                                                gallery: {
                                                    url: file.url,
                                                    id: i
                                                }
                                            })
                                        }
                                    }
                                    lang={{
                                        maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                                    }}
                                />
                            </CellBody>
                        </Cell>
                        <div style={{ marginTop: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button style={{ marginRight: 20, width: 100, height: 37, lineHeight: '37px' }} type="primary" onClick={this.handleOk}>完成</Button>
                            <Button style={{ width: 100, height: 37, lineHeight: '37px' }} type="warning" onClick={() => this.setState({ finishVisible: false })}>取消</Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={evaluateVisible}
                    onClose={() => this.setState({ evaluateVisible: false })}
                    transparent
                    className='search_modal'
                >
                    <div>
                        <List renderHeader={() => <div style={{ textAlign: 'left' }}>满意程度</div>}>
                            {satisfactions.map(i => (
                                <RadioItem key={i.value} checked={satisfaction === i.value} onChange={() => this.onChangeSatisfaction(i.value)}>
                                    {i.label}
                                </RadioItem>
                            ))}
                        </List>
                        <List renderHeader={() => <div style={{ textAlign: 'left' }}>评价描述：</div>}>
                            <TextareaItem
                                placeholder="请输入你的评价描述"
                                onChange={(e) => {
                                    this.setState({
                                        evaluates: e
                                    })
                                }}
                                rows={8}
                                count={200}
                            />
                        </List>
                        <div style={{ marginTop: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button style={{ marginRight: 20, width: 100, height: 37, lineHeight: '37px' }} type="primary" onClick={this.handleSatisfactionOk}>评价</Button>
                            <Button style={{ width: 100, height: 37, lineHeight: '37px' }} type="warning" onClick={() => this.setState({ evaluateVisible: false })}>取消</Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={shareVisible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            shareVisible: false
                        })
                    }}
                >
                    <div style={{ position: 'fixed', width: '100%' }}>
                        <SearchBar
                            placeholder="请输入用户姓名或昵称"
                            ref={ref => this.manualFocusInst = ref}
                            onCancel={() => this.setState({ shareVisible: false })}
                            onChange={this.debounce((val) => {
                                this.fetchUser(val)
                            })}
                            onSubmit={(e) => this.fetchUser(e)}
                        />
                    </div>
                    <div style={{ marginTop: 54, padding: '0 18px' }}>
                        {
                            userList.map(item => {
                                return <div style={{ padding: 5 }} key={item.user_id} onClick={() => this.handleShareSubmit(item)}>
                                    {item.user_nick}：{item.user_name}
                                </div>
                            })
                        }
                    </div>
                </Modal>
                <Modal
                    visible={appointVisible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            appointVisible: false
                        })
                    }}
                >
                    <div style={{ position: 'fixed', width: '100%' }}>
                        <SearchBar
                            placeholder="请输入维修工姓名"
                            ref={ref => this.manualFocusInst = ref}
                            onCancel={() => this.setState({ appointVisible: false })}
                            onChange={this.debounce((val) => {
                                this.fetchMaintainer(val)
                            })}
                            onSubmit={(e) => this.fetchMaintainer(e)}
                        />
                    </div>
                    <div style={{ marginTop: 54, padding: '0 18px' }}>
                        {
                            userData.map(item => {
                                return <div key={item.user_id} className='modal_content' onClick={() => this.handleAppointSubmit(item)}>
                                    <a style={{ display: 'flex' }} href="#">
                                        <div className='modal_img'>
                                            {
                                                item.user_avatar ? <img src={item.user_avatar} alt='' /> :
                                                    <img src={require("../../images/04.png")} alt='' />
                                            }
                                        </div>
                                        <div className='modal_info'>
                                            <div className='modal_info_content'>
                                                <span className='info_name'>{item.user_name}</span>&nbsp;&nbsp;
                                                <span className='info_nick'>昵称：{item.user_nick.length > 10 ? item.user_nick.substr(0, 10) + '...' : item.user_nick}</span>
                                            </div>
                                            <div className='modal_info_phone'>{item.user_phone}</div>
                                        </div>
                                    </a>
                                </div>
                            })
                        }
                    </div>
                </Modal>
                <Modal
                    visible={describeVisible}
                    onClose={() => this.setState({ describeVisible: false })}
                    transparent
                >
                    <div>
                        <List renderHeader={() => <div style={{ textAlign: 'left' }}>报修描述：</div>}>
                            <TextareaItem
                                placeholder="请输入你的报修描述"
                                onChange={(e) => {
                                    this.setState({
                                        describes: e
                                    })
                                }}
                                rows={8}
                                count={200}
                            />
                        </List>
                        <div style={{ marginTop: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button style={{ marginRight: 20, width: 100, height: 37, lineHeight: '37px' }} type="primary" onClick={this.handleDescribeOk}>确定</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default index
