import { observable, action } from 'mobx';


class HomeStore {
    @observable visible = false;

    @action setVisible = bool => {
        this.visible = bool;
    }
}
export default new HomeStore();