import React, { Component } from 'react';
import { NavBar, Icon, Tabs, Badge, Button, Popover, Modal, List, TextareaItem, Toast } from 'antd-mobile'
import { findTaskById, addTaskReply, finishTask } from '../../service/api'
import moment from 'moment';
import IP from '../../config/config'


const Item = Popover.Item;


class taskDetail extends Component {
    state = {
        detailData: {},
        enclosureData: [], // 附件
        popoverVisible: false,
        visible: false,
        content: '', // 交流内容
    }

    componentDidMount = () => {
        this.fetchDetail(this.props.match.params.id)
    }

    fetchDetail = async taskId => {
        const res = await findTaskById({ taskId })
        if (res.code === 20000) {
            this.setState({
                detailData: res.data[0],
                enclosureData: res.data[0].task_files && res.data[0].task_files.split(',').map(x => {
                    return { fileName: x.split("*")[0], size: x.split("*")[1], url: x.split("*")[2] }
                }),
            })
        }
    }

    // 选中气泡中的某一个报修状态
    handleSelect = (opt) => {
        const { detailData } = this.state;
        this.setState({
            popoverVisible: false,
        });
        if (opt.props.value === 1) {
            this.props.history.push({ pathname: `/task/enclosure/${this.props.match.params.id}/${detailData.task_state}` })
        }
        if (opt.props.value === 2) {
            this.props.history.push({ pathname: `/task/taskAdd/${this.props.match.params.id}/${detailData.task_state}` });
        }
    };

    // 工作沟通
    handleCommunicate = () => {
        this.setState({
            visible: true,
        })
    }

    // 工作交流ok
    heandleSubmitOk = async () => {
        const { content, detailData } = this.state;
        if (!content) return Toast.info('请输入你的内容', 2, null, false)
        const res = await addTaskReply({ content, taskId: detailData.task_id })
        if (res.code === 20000) {
            this.setState({
                visible: false,
            })
            Toast.info('任务回复成功', 2, null, false)
            this.fetchDetail(this.props.match.params.id)
        }
    }

    // 完成
    heandleSubmit = async () => {
        const { detailData } = this.state;
        const res = await finishTask({ taskId: detailData.task_id })
        if (res.code === 20000) {
            Toast.info(res.message)
        }
        this.fetchDetail(this.props.match.params.id)
    }

    render() {
        const { detailData, enclosureData, popoverVisible, visible } = this.state;
        const tabs = [
            { title: <Badge>沟通概要</Badge> },
            { title: <Badge>附件</Badge> },
            { title: <Badge>人员结构</Badge> },
        ];
        return (
            <div className='taskDetial_home'>
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={
                        <Popover mask
                            visible={popoverVisible}
                            overlay={[
                                (<Item key="2" value={2}><img src={require('../../images/40.png')} alt='' /><span>编辑任务</span></Item>),
                                (<Item key="1" value={1}><img src={require('../../images/43.png')} alt='' /><span>编辑附件</span></Item>),
                            ]}
                            onSelect={this.handleSelect}
                        >
                            <Icon type="ellipsis" />
                        </Popover>
                    }
                >
                    任务详情
                </NavBar>
                <div className='taskDetail_main'>
                    <div className='taskDetail_title'>{detailData.task_title}</div>
                    <div className='taskDetail_name'>{detailData.publish_user_name}发表于{moment(detailData.crearte_time).format("YYYY-MM-DD HH:mm")}</div>
                    <div className='taskDetail_desc' dangerouslySetInnerHTML={{ __html: detailData.task_description }}></div>
                    {
                        detailData.project_name ? <div className='taskDetail_project'><span>关联项目名称：{detailData.project_name}</span></div> : ''
                    }
                </div>
                <div className='taskDetail_separator'></div>
                <div className='taskDetail_info'>
                    <Tabs tabs={tabs}
                        initialPage={0}
                    >
                        <div className='taskDetail_tabs'>

                            {
                                (detailData.replyEntities || []).map(item => {
                                    return <div className='tackDetail_report' key={item.task_reply_id}>
                                        <div className='report_title'>
                                            <div className='report_title_img'>
                                                <img src={item.reply_user_avatar} alt='' />
                                            </div>
                                            <div className='report_title_name'>
                                                <div>{item.reply_user_name}</div>
                                                <div>创建于{moment(item.reply_time).format('YYYY-MM-DD HH:mm')}</div>
                                            </div>
                                        </div>
                                        <div className='report_desc'>
                                            {item.reply_content}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className='taskDetail_tabs'>
                            {
                                (enclosureData || []).map((v, k) => {
                                    return (
                                        <div className="tabs-access" key={k}>
                                            <div className="tabs-img">
                                                <img src={IP.TypeIcon[v.url.substring(v.url.lastIndexOf(".") + 1)] ? IP.host + IP.TypeIcon[v.url.substring(v.url.lastIndexOf(".") + 1)] : IP.host + IP.TypeIcon['error']} width="40px" alt="" />
                                            </div>
                                            <div className="tabs-main">
                                                <div className="tabs-title">{v.fileName.length > 24 ? v.fileName.substr(0, 24) + "..." : v.fileName}</div>
                                                <div className="tabs-size">
                                                    <span>{Number(v.size) ? Number(v.size).toFixed(2) : 0}MB</span>
                                                </div>
                                            </div>
                                            <div className="tabs-icon" onClick={() => {
                                                window.location.href = IP.host + v.url
                                            }}>
                                                <span>•</span>
                                                <span>•</span>
                                                <span>•</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='taskDetail_tabs'>
                            <div key={detailData.publish_user_id} className='taskDetail_user'>
                                <div className='taskDetail_user_img'><img src={detailData.publish_user_avatar} alt='' /></div>
                                <div className='taskDetail_user_name'>{detailData.publish_user_name}</div>
                            </div>
                            {
                                detailData.joinUsers && detailData.joinUsers.length ? detailData.joinUsers.map(x => {
                                    return <div key={x.user_id} className='taskDetail_user'>
                                        <div className='taskDetail_user_img'><img src={x.user_avatar} alt='' /></div>
                                        <div className='taskDetail_user_name'>{x.user_name}</div>
                                    </div>
                                }) : ''
                            }
                        </div>
                    </Tabs>
                </div>
                <div className='taskDetail_footer'>
                    {
                        detailData.task_state ? '' :
                            <div className='footer_btn'>
                                <Button type='primary' onClick={() => this.handleCommunicate()}>工作沟通</Button>
                                <Button onClick={() => this.heandleSubmit()} type='primary' style={{ marginLeft: 20 }}>完成</Button>
                            </div>
                    }
                </div>
                <Modal
                    popup
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    animationType="slide-up"
                    className='modal_repairs'
                >
                    <div className='modal_repairs_info'>
                        <List renderHeader={() => <div style={{ textAlign: 'left', fontSize: 16, fontWeight: 'bold' }}>工作交流</div>}>
                            <TextareaItem
                                placeholder="请输入你的内容"
                                onChange={(e) => {
                                    this.setState({
                                        content: e
                                    })
                                }}
                                rows={5}
                                count={200}
                            />
                        </List>
                        <div className='taskDetail_modal_btn'>
                            <Button onClick={() => this.heandleSubmitOk()}>确定</Button>
                            <Button onClick={() => this.setState({ visible: false })}>取消</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default taskDetail;