import React, { Component } from 'react';
import { NavBar, Icon, List, Toast } from 'antd-mobile';
import { queryChildDepAndUserById } from '../../service/api';

const { Item } = List;
const Brief = Item.Brief;
class MailList extends Component {
    state = {
        depData: [],
        userData: []
    }

    componentDidMount = () => {
        this.fetchChildrenById()
    }

    componentWillReceiveProps = nextProps => {
        console.log(nextProps, 'nextProps');
        const { id } = nextProps.match.params;
        this.fetchChildrenById(id > 0 ? id : undefined)
    }

    // 根据部门Id查询子级部门与用户
    fetchChildrenById = async (id) => {
        // queryChildDepAndUserById
        const result = await queryChildDepAndUserById(id);
        if (result.code === 20000) {
            this.setState({
                depData: result.data.sysUserGroupEntitie,
                userData: result.data.sysUserEntities
            })
        }
        console.log(result);
    }

    // 点击用户时候
    handleUserItemClick = (record) => {
        // console.log('')
        if (record.user_phone)
            window.location.href = `tel:${record.user_phone.replace(/\s/g, '')}`;
        else
            Toast.info('该用户未设置手机号')
    }

    render() {
        const { userData, depData } = this.state;
        const { history: { push, goBack } } = this.props;

        // 渲染用户头像
        const renderUserAvatar = url => {
            return (
                <img src={url} alt="" />
            )
        }

        return (
            <div>
                <NavBar
                    icon={<Icon type="left" />}
                    onLeftClick={() => goBack()}
                >
                    通讯录
                </NavBar>
                {depData.length > 0 && <List renderHeader={() => '部门'}>
                    {depData.map(item => (
                        <Item
                            arrow={'horizontal'}
                            onClick={() => push(`/MailList/${item.user_group_id}`)}
                            thumb={
                                <img
                                    alt=""
                                    src={require('../../images/dep.png')}
                                    style={{ borderRadius: 'initial' }}
                                />
                            }
                        >
                            {item.group_name}
                            <Brief>{item.group_description}</Brief>
                        </Item>
                    ))}
                </List>}

                <List renderHeader={() => '用户'} >

                    {
                        userData.map(item => (
                            <Item onClick={() => this.handleUserItemClick(item)} thumb={renderUserAvatar(item.user_avatar)}>{item.user_name || '未实名'}<Brief>{item.user_phone || '未设置手机号'}</Brief></Item>
                        ))
                    }
                </List>
            </div>
        );
    }
}

export default MailList;