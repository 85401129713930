/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { WingBlank, Carousel, WhiteSpace, Grid, Modal, Toast, Button } from 'antd-mobile'
import './index.less'
import { queryCarouselData, queryModuleData, findModuleFunction } from '../../service/api'
import IP from '../../config/config'
import { NavBar } from 'antd-mobile';
import cookie from 'react-cookies'
import { Icon } from 'antd-mobile';
import { Popover } from 'antd-mobile';

@inject('globalStore')
@observer
class Home extends Component {

    state = {
        data: ['1', '2', '3'],
        imgHeight: 20,
        modules: [],
        visible: false,
        iconData: [],
        funcData: [],
        moduleData: [],
    }

    componentDidMount = () => {
        // setTimeout(() => {
        //     this.setState({
        //         data: ['AiyWuByWklrrUDlFignR', 'TekJlZRVCjLFexlOCuWn', 'IJOtIlfsYdTyaDTRVrLI'],
        //     });
        // }, 100);

        this.fetchCarouselData();

        // this.fetchModuleData();

        this.fetchModule();
    }

    // 查询轮播图
    fetchCarouselData = async () => {
        const result = await queryCarouselData()
        if (result.code === 20000) {
            this.setState({
                data: result.data.map(item => IP.host + item.slideshow_url)
            })
        }
    }

    fetchModuleData = async moduleData => {
        const result = await queryModuleData();
        if (result.code === 20000) {
            const newModuleData = (result.data || []).filter(x => moduleData.map(x => x.function_name).includes(x.module_name))
            this.setState({
                modules: (newModuleData || []).map(x => { // 图标按钮
                    return {
                        icon: IP.host + x.module_image_url,
                        text: x.module_name,
                        url: x.module_url,
                        id: x.mobile_module_id,
                    }
                })
            })
        }
    }

    // 查询用户功能详情
    fetchModule = async () => {
        const res = await findModuleFunction({ roleId: cookie.load('user').role_id })
        const { globalStore: { setFuncData } } = this.props;
        const funcData = res.data.filter(x => x.function_id < 100)
        const moduleData = res.data.filter(x => x.function_id > 100)
        if (res.code === 20000) {
            cookie.save('funcData', funcData)
            setFuncData(funcData)
            this.setState({
                funcData
            })
        }
        this.fetchModuleData(moduleData)
    }

    // 跳转地址
    handleSkip = async e => {
        const { funcData } = this.state;
        const array = [
            { icon: '01.png', name: '工单报修', url: '/action/index' },
            { icon: '02.png', name: '电话报修', url: '/phone/index' },
            { icon: '03.png', name: '语音报修', url: '/voice/index' },
        ]
        if ((e.url === '/user/repairs') || (e.url === '/staff/repairs')) {
            if ((funcData || []).find(x => Number(x.function_id) === 1)) {
                this.setState({
                    iconData: array.map((x, index) => { //图标按钮
                        return {
                            icon: require(`../../images/${x.icon}`),
                            text: x.name,
                            id: index + 1,
                            url: x.url,
                            name: e.url,
                        }
                    }),
                    visible: true,
                })
            } else {
                Toast.info('用户暂无报修功能', 2, null, false)
            }
        } else if (e.url.includes('http')){
            window.location.href = e.url
        } else {
            if(e.url==='/clear'){
                cookie.remove('user');
                cookie.remove('token');
                Toast.show('清除成功！');
                window.location.href = "/"
                return false;
            }else{
                this.props.history.push({ pathname: e.url })
            }
        }
    }

    // 报修跳转
    handleRepairs = e => {
        this.props.history.push({ pathname: e.url, record: e })
    }

    render() {
        const { modules, visible, iconData, popoverVisible } = this.state;
        return (
            <>
                <NavBar
                    mode="mode"
                    rightContent={[
                        <Popover 
                            key = "2"
                            mask
                            visible={popoverVisible}
                            overlay={[
                                (<Popover.Item key="1" value={1}><img src={require('../../images/11.png')} alt='' /><span onClick={() => { cookie.remove('user'); cookie.remove('token'); Toast.show('清除成功！'); window.location.href = "/" }}>清除缓存</span></Popover.Item>),
                            ]}
                            onSelect={() => this.setState({ popoverVisible: false })}
                        >
                            <Icon key="3" type="ellipsis" />
                        </Popover>
                    ]}

                >
                    云平台
                </NavBar>
                <div>
                    <WhiteSpace size="lg" />
                    <WingBlank>
                        <Carousel
                            autoplay={false}
                            infinite
                            beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                            afterChange={index => console.log('slide to', index)}
                        >
                            {this.state.data.map(val => (
                                <a
                                    key={val}
                                    // href="http://www.alipay.com"
                                    href="#"
                                    style={{ display: 'inline-block', width: '100%', }}
                                >
                                    <img
                                        src={val}
                                        alt=""
                                        style={{ width: '100%', verticalAlign: 'top', borderRadius: 10 }}
                                        onLoad={() => {
                                            // fire window resize event to change height
                                            window.dispatchEvent(new Event('resize'));
                                            this.setState({ imgHeight: 'auto' });
                                        }}
                                    />
                                </a>
                            ))}
                        </Carousel>
                    </WingBlank>
                    <WhiteSpace size="lg" />
                    <Grid data={modules} hasLine={false} onClick={e =>{
                        this.handleSkip(e)
                    }} />
                </div>
                <Modal
                    popup
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    animationType="slide-up"
                    className='modal_repairs'
                >
                    <div className='modal_repairs_info'>
                        <div className='modal_repairs_type'>
                            <span>报修方式</span>
                            <span>数量：3</span>
                        </div>
                        <Grid data={iconData} hasLine={false} columnNum={4} onClick={e => this.handleRepairs(e)} />
                        <Button onClick={() => this.setState({ visible: false })}>取消</Button>
                    </div>
                </Modal>
            </>
        )
    }
}
export default Home;
